import * as firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";

/**
 * Main Firebase authentication
 * @const
 */

const config = {
  apiKey: "AIzaSyBoLJ9VTzBFCXcOb3Wy8_DlBFns8FcUUII",
  authDomain: "undiscoveredtest.firebaseapp.com",
  projectId: "undiscoveredtest",
  storageBucket: "undiscoveredtest.appspot.com",
  //databaseURL: "https://undiscoveredtest.firebaseio.com",
  messagingSenderId: "75698367286",
  appId: "1:75698367286:web:48d1df351dcf024fc9e197"
};

firebase.initializeApp(config);


const db = firebase.firestore();
const auth = firebase.auth;

// eslint-disable-next-line no-restricted-globals
if (location.hostname === "localhost") {
  firebase.auth().useEmulator("http://localhost:9099/");
  firebase.firestore().settings({ host: 'localhost:8080', ssl: false });
}



export default firebase;

/** PROD Firebase authentication

 apiKey: "AIzaSyCnVPWulXChoBxSu7RgI8f3Zs1HgXvtIjY",
authDomain: "undiscovered-885a7.firebaseapp.com",
databaseURL: "https://undiscovered-885a7.firebaseio.com",
projectId: "undiscovered-885a7",
storageBucket: "undiscovered-885a7.appspot.com",
messagingSenderId: "410965190125",
appId: "1:410965190125:web:4bbf886bf2fdcc8245f3e4",
measurementId: "G-033RGTV7TL"

 */
