import React from "react";
import { Container } from "reactstrap";
import { Link } from "react-router-dom";

/**
 * PoliticaPrivacidad Class.
 * Main PoliticaPrivacidad component view.
 * @constructor
 */
class PoliticaPrivacidad extends React.Component {
  /**
   * Renders the PoliticaPrivacidad component.
   * @see PoliticaPrivacidad Component
   */
  render() {
    return (
      <>
        {/* Page content */}
        <Container fluid>
          <h2 className="ls-2  text-center">Política de Privacidad</h2>
          <p className=" text-justify">
            Undiscovered es una plataforma online dedicada al fútbol y a la
            promoción de talento futbolístico. Undiscovered es titular, o tiene
            los derechos de explotación, de una página web y/o aplicación móvil
            a la que se accede mediante la url{" "}
            <a href="https://undiscovered.app">www.undiscovered.app</a>, así
            como el uso de una o varias redes sociales, como por ejemplo
            Instagram mediante el siguiente enlace{" "}
            <a href="https://www.instagram.com/undiscovered.app/">
              www.instagram.com/undiscovered.app/
            </a>
            , (en adelante, denominados colectivamente como la “Plataforma”).
            Undiscovered ofrece en y a través de la Plataforma contenido
            futbolístico generado por Undiscovered, compartido por los usuarios
            o desarrollado de manera conjunta, para que dicho contenido e
            información sea vista por el mayor número de personas (los
            “Servicios”). Los Servicios de Undiscovered se ofrecen bajo sus{" "}
            <Link to="/info/terminos-condiciones">Términos y Condiciones</Link>.
            <br />
            <br />
            El acceso a determinados Servicios de la Plataforma requiere el
            registro de datos. Por ello, queremos ser transparentes acerca de lo
            que pretendemos hacer con la información que nos confieres o que
            sabremos sobre ti, es decir, sobre tus datos personales, mismos que
            se desarrollan más abajo (los “Datos”). Te rogamos leer y revisar
            con detalle la presente Política de Privacidad, estando disponibles
            para resolver cualquier duda.
            <br />
            <br />
            Para efectos de la presente Política de Privacidad, se considera
            como usuario a toda aquella persona que envíe, comparta o solicite
            que se publiqué contenido generado por él, o del que dicha persona
            es titular, para ser compartido a través de la Plataforma en
            relación con los Servicios (el “Usuario”).
            <br />
            <br />
            De igual manera, se considerará como suscriptor, a toda aquella
            persona que solicite el acceso, uso, aprovechamiento y/o
            interacción, así como acceso al contenido y algunos Datos de los
            Usuarios en la Plataforma respecto de los Servicios con otros
            Usuarios y/o suscriptores (el “Suscriptor”). En el momento en que un
            Suscriptor solicita que algún contenido generado por él, o del que
            él es titular sea compartido a través de la Plataforma en relación
            con los servicios, será considerado como Usuario.
            <br />
            <br />
            Nuestros Usuarios y/o Suscriptores comparten su identidad,
            interactúan con su red de contactos, intercambian información y
            conocimientos, publican y ven contenido relevante, se informan y
            encuentran oportunidades profesionales y de desarrollo futbolístico.
            <br />
            <br />
            Cuando utilizas los Servicios de la Plataforma, o únicamente la
            Plataforma y envías o compartes Datos, consientes el tratamiento de
            los Datos, de acuerdo con lo establecido en la Política de
            Privacidad. Considera los siguientes aspectos respecto de la
            Política de Privacidad:
            <ul>
              <li>
                Datos que recibimos de ti y tratamos cuando interactúas,
                descargas, utilizas o accedes a la Plataforma o los Servicios.
              </li>
              <li>La manera en que los Datos serán tratados.</li>
              <li>
                Motivos por el que los Datos puedan ser compartidos con
                terceros.
              </li>
              <li>
                La protección legal y jurídica con la que cuentas respecto de
                los Datos.
              </li>
            </ul>
            Cualquier cambio realizado a la presente Política de Privacidad será
            notificado con antelación suficiente, así como volver a solicitar tu
            consentimiento con cualquier cambio que requiera el otorgamiento del
            mismo nuevamente. Sin menoscabo de lo anterior, podrás solicitar la
            eliminación de todo o parte de tus Datos en la Plataforma si no
            estás de acuerdo con los cambios realizados o a realizarse.
            <br />
            <br />
            De conformidad con lo dispuesto en Reglamento UE 2016/679, del
            Parlamento Europeo y del Consejo de 27 de abril de 2016. (RGPD) y en
            la Ley Orgánica 3/2018, de 5 de diciembre, de Protección de Datos
            Personales y Garantía de los Derechos Digitales (LOPDGDD);
            Undiscovered te informa que mediante la aceptación de esta Política
            de Privacidad, otorgas tu consentimiento informado, expreso, libre e
            inequívoco para que los Datos que proporcionas a Undiscovered sean
            incluidos en los ficheros de los que Undiscovered es responsable y
            sobre los que se aplican las medidas de seguridad, técnicas y
            organizativas previstas en la normativa vigente.
            <br />
            <br />
            Por lo anterior, aquí encontraras la información en relación con el
            tratamiento de los Datos que hará Undiscovered.
            <br />
            <br />
            <h3 className="color-secondary">
              ¿Quién es el responsable del tratamiento de tus Datos?
            </h3>
            <ul>
              <li>Undiscovered Talent Discovery, S.L.</li>
              <li>La manera en que los Datos serán tratados.</li>
              <li>Domicilio: María de Molina 31, Madrid, Madrid 28006</li>
              <li>N.I.F. B88439930</li>
              <li>
                Información Mercantil: inscrita en el Registro Mercantil de
                Madrid, con fecha 26 de julio de 2019, número de inscripción 1,
                tomo 39485, folio 80, hoja 701033.
              </li>
              <li>
                Correo electrónico:{" "}
                <a href="mailto:contacto@undiscovered.app">
                  contacto@undiscovered.app
                </a>
                .
              </li>
            </ul>
            <h3 className="color-secondary">
              ¿Con qué finalidad tratamos tus Datos?
            </h3>
            Cuando nos facilitas tus Datos como Usuario o Suscriptor de los
            Servicios de la Plataforma, Undiscovered trata dichos datos con el
            fin de prestarte los servicios públicos de la Plataforma y
            contactarte, si fuese necesario.
            <br />
            <br />
            En relación con lo anterior, te informamos que de buena fe
            generamos, de manera propia o a través de terceros, estadísticas de
            uso de los Servicios a fin de realizar mejoras, acciones de
            marketing y desarrollos de herramientas. Es por lo anterior, que
            podremos elaborar un perfil comercial de Usuarios y/o Suscriptores.
            Lo anterior no significa que vayamos a tomar decisiones
            automatizadas respecto al perfil a elaborar.
            <br />
            <br />
            De igual manera, Undiscovered podrá tratar los Datos y la Plataforma
            para llevar a cabo actividades promocionales de productos o
            servicios propios de Undiscovered o de terceros con los que
            Undiscovered tenga acuerdos comerciales. Es por ello que
            expresamente aceptas recibir información y/o publicidad de
            Undiscovered. Lo anterior puede ser revocado solicitándolo por
            escrito al correo electrónico{" "}
            <a href="mailto:undiscoveredapp@gmail.com">
              undiscoveredapp@gmail.com
            </a>
            .
            <br />
            <br />
            En caso de que manifiestes tu deseo para tus Datos no sean tratados
            en comunicaciones comerciales, puedes inscribirte en los sistemas de
            exclusión publicitaria. Puedes encontrar mayor información en el
            siguiente enlance{" "}
            <a href="https://www.aepd.es/areas/publicidad/index.html">
              https://www.aepd.es/areas/publicidad/index.html
            </a>
            , mismo que depende de la Agencia Española de Protección de Datos.
            <br />
            <br />
            Te recordamos que en la Plataforma o la Web (según se define en la
            Política de Cookies), utilizamos cookies para analizar usos y
            medición de datos para mejorar nuestros Servicios.
            <br />
            <br />
            Undiscovered podrá insertar publicidad dentro de la Plataforma y de
            los productos que se generen a partir de la misma. Usted otorga su
            consentimiento a estos efectos.
            <br />
            <br />
            El tratamiento de los Datos para finalidades distintas a las que se
            detallan en este apartado requerirá tu consentimiento.
            <br />
            <br />
            <h3 className="color-secondary">
              ¿Cuál es la legitimación para el tratamiento de tus datos?
            </h3>
            Cuando nos facilitas tus datos como Usuario o Suscriptor de los
            Servicios de la Plataforma, el fundamento para el tratamiento de los
            Datos es tu consentimiento. Los Datos serán tratados con el máximo
            rigor y respeto a la privacidad, derechos y libertades; sin que se
            utilicen con finalidades que afecten tus derechos.
            <br />
            <br />
            El consentimiento otorgado podrá ser revocado en cualquier momento.
            <br />
            <br />
            <h3 className="color-secondary">
              ¿A qué destinatarios se comunicarán sus Datos?
            </h3>
            Undiscovered podrá comunicar tus Datos a personas físicas y/o
            morales del sector deportivo para el envío de comunicaciones
            comerciales respecto de los Usuarios y/o Suscriptores. En caso de
            ser Usuario, Undiscovered podrá comunicar tus Datos a personas
            físicas y/o morales que representen una oportunidad para que el
            Usuario sea visto y pueda ser contactado por visitantes a la
            plataforma, distintos de los Usuarios y/o de los Suscriptores.
            <br />
            <br />
            Salvo por los mencionados anteriormente, tus Datos no se cederán a
            terceros, excepto cuando exista obligación legal de hacerlo y en los
            siguientes casos:
            <ul>
              <li>
                A Jueces y Tribunales, en cumplimiento de requerimientos,
                obligaciones legales o en el marco de un procedimiento judicial.
              </li>
              <li>
                A la Agencia Tributaria, para el cumplimiento de las
                obligaciones fiscales.
              </li>
              <li>
                A Auditores Financieros para el cumplimiento de las obligaciones
                financieras.
              </li>
              <li>
                Y a otros terceros a quienes, en virtud de la normativa vigente
                aplicable en cada caso, sea obligatorio llevar a cabo la cesión,
                tal como órganos de la Administración competentes por motivos de
                control, registro e inspección.
              </li>
            </ul>
            No obstante, si en algún momento de la relación comercial fuera
            necesario comunicar sus datos a cualquier otro tercero no
            contemplado en los supuestos anteriores, dicha comunicación se
            realizará cuando contemos con su consentimiento previo para ello, y
            en la forma legalmente prevista.
            <br />
            <br />
            Además, como consecuencia de los servicios que nos prestan,
            determinados proveedores podrían tener acceso a los Datos (como
            encargados de tratamiento), el cual se realiza siempre con las
            garantías adecuadas y de acuerdo con la normativa establecida en la
            legislación europea y nacional, en virtud de los contratos de
            encargado de tratamiento firmados con estos.
            <br />
            <br />
            Undiscovered te informa que realizará encargos de tratamientos a
            terceros tales como los servicios de almacenamiento en servidores
            físicos o en la nube, servicios de gestoría fiscal o laboral o
            servicios relacionados con la elaboración de estadísticas de juego.
            <br />
            <br />
            Los datos serán transferidos a GOOGLE LLC, que presta servicio de
            alojamiento de datos. Dicha transferencia internacional, consistente
            en el alojamiento de información en el Servicio Google Cloud y
            G-SUITE, se efectúa a Estados Unidos, y está amparada en la
            formalización del contrato de uso de los servicios de GOOGLE, que
            cumple con los requerimientos de las cláusulas contractuales tipo
            aprobadas por la UE, en relación con los encargados del tratamiento.
            Pueden consultarse en:
            <ul>
              <li>
                <a href="https://cloud.google.com/terms/">
                https://cloud.google.com/terms/{" "}
                </a>
              </li>
              <li>
                <a href="https://firebase.google.com/products/storage">
                https://firebase.google.com/products/storage
                </a>
              </li>
              <li>
                <a href="https://firebase.google.com/products/realtime-database">
                https://firebase.google.com/products/realtime-database
                </a>
              </li>
              <li>
                <a href="https://firebase.google.com/products/firestore">
                https://firebase.google.com/products/firestore
                </a>
              </li>
            </ul>
            <h3 className="color-secondary">
              ¿Cuáles son sus derechos cuando nos facilita los datos?
            </h3>
            Cualquier persona tiene derecho a obtener confirmación sobre si
            Undiscovered está tratando datos personales que le conciernan o no.
            <br />
            <br />
            También puedes ejercer los derechos que te confiere la normativa
            vigente: derecho de acceso, rectificación, supresión y oposición,
            limitación del tratamiento, portabilidad de datos y a no ser objeto
            de decisiones individualizadas automatizadas.
            <br />
            <br />
            <ul>
              <li>
                <span className="fw-600">Acceso:</span> permite al titular de
                los datos obtener información sobre si Undiscovered trata datos
                personales que le conciernen o no y, en tal caso, derecho a
                obtener información sobre sus datos de carácter personal
                sometidos a tratamiento.
              </li>
              <li>
                <span className="fw-600">Rectificación:</span> permite corregir
                errores y modificar los datos que resulten ser inexactos o
                incompletos.
              </li>
              <li>
                <span className="fw-600">Supresión:</span> permite que los datos
                sean suprimidos y dejen de tratarse por Undiscovered salvo que
                exista obligación legal de conservarlos y/o no prevalezcan otros
                motivos legítimos para su tratamiento por Undiscovered conforme
                a la normativa vigente.
              </li>
              <li>
                <span className="fw-600">Limitación:</span> bajo las condiciones
                establecidas legalmente, permite que el tratamiento de datos se
                paralice, de tal manera que se evite por parte de Undiscovered
                su tratamiento en un futuro, que únicamente los conservará para
                el ejercicio o la defensa de reclamaciones.
              </li>
              <li>
                <span className="fw-600">Oposición:</span> permite al titular
                que en determinadas circunstancias y por motivos relacionados
                con su situación particular, puedan oponerse al tratamiento de
                sus datos. Undiscovered dejará de tratar los datos, salvo por
                motivos legales, o el ejercicio o la defensa de posibles
                reclamaciones.
              </li>
              <li>
                <span className="fw-600">Portabilidad:</span> permite al titular
                recibir sus datos personales y poder transmitirlos directamente
                a otro responsable en un formato estructurado, de uso común y
                lectura mecánica. Para el ejercicio de este derecho, será
                necesario que el titular proporcione un correo electrónico
                válido.
              </li>
              <li>
                <span className="fw-600">Portabilidad:</span> permite al titular
                recibir sus datos personales y poder transmitirlos directamente
                a otro responsable en un formato estructurado, de uso común y
                lectura mecánica. Para el ejercicio de este derecho, será
                necesario que el titular proporcione un correo electrónico
                válido.
              </li>
            </ul>
            El Usuario y/o Suscriptor, tiene derecho a retirar el consentimiento
            en cualquier momento. El ejercicio de tus derechos podrá realizar
            mediante cualquier medio que muestre el envío y recepción de la
            solicitud respectiva. Es por ello que Undiscovered garantiza la
            adopción de medidas pertinentes para asegurar el ejercicio de los
            derechos.
            <br />
            <br />
            Cualquier solicitud, deberá ser dirigida a Undiscovered, al
            domicilio o a la dirección de correo electrónico. En la comunicación
            se deberá de indicar la referencia de “Protección de Datos”,
            mencionando nombre, apellidos, fotocopia del Documento Nacional de
            Identidad o identificación oficial del país de nacionalidad,
            petición en la que se concreta la solicitud y domicilio y correo
            electrónico a efectos de notificaciones.
            <br />
            <br />
            Lo anterior sin menoscabo del derecho a reclamar ante la Autoridad
            correspondiente la respuesta a la solicitud por parte de
            Undiscovered.
            <br />
            <br />
            <h3 className="color-secondary">¿Cómo hemos obtenido tus datos?</h3>
            Los Datos objeto de tratamiento, como Usuario y/o Suscriptor de los
            Servicios de la Plataforma, han sido proporcionados por ti mismo
            mediante la cumplimentación de los correspondientes formularios de
            registro incorporados en la Plataforma y previa aceptación de las
            Condiciones Uso y de esta Política de Privacidad.
            <br />
            <br />
            De igual manera, algunos Datos pudieron haber sido compartidos
            mediante la cesión de los mismos por parte del club o equipo
            deportivo del que formas parte.
            <br />
            <br />
            Cualquier Usuario y/o Suscriptor debe garantizar que es mayor de
            edad, o mayor de catorce años, y que los Datos aportados son
            verdaderos, exactos, completos y actualizados, siendo responsable de
            cualquier daño o perjuicio, directo o indirecto, que pudiera
            ocasionarse como consecuencia del incumplimiento de tal obligación.
            <br />
            <br />
            También podremos recabar información que publiques en las redes
            sociales en las que está presente Undiscovered, adicionales a la
            Plataforma, que será utilizada sólo para las finalidades incluidas
            en esta Política de Privacidad. Lo anterior no significa que tu
            presencia en dichas redes sociales no sea regulada por las
            respectivas políticas de privacidad.
            <br />
            <br />
            Por lo anterior, Undiscovered te informa del tratamiento de los
            siguientes datos:
            <ol>
              <li>Nombre y apellidos</li>
              <li>Foto de perfil (opcional)</li>
              <li>Correo electrónico y contraseña</li>
              <li>Fecha de nacimiento</li>
              <li>Ciudad.</li>
              <li>Equipo preferido.</li>
              <li>Elementos propios de la identidad física.</li>
              <li>Datos biométricos.</li>
              <li>Videos e imágenes del Usuario.</li>
              <li>No se tratan datos especialmente protegidos</li>
            </ol>
            <h3 className="color-secondary">
              ¿Durante cuánto tiempo almacenaremos tus datos?
            </h3>
            Trataremos y almacenaremos tus datos siempre y cuando sea necesario
            para proveer los servicios de la Plataforma y la prestación de los
            Servicios. No obstante, los datos de Usuario registrado serán
            bloqueados y/o eliminados conforme a su solicitud de revocación del
            consentimiento u oposición al tratamiento. También retenemos tus
            Datos para cumplir con los requisitos de la ley aplicable, prevenir
            acciones ilícitas, resolver disputas, solucionar problemas, ejecutar
            nuestro contrato y otras acciones permitidas por la ley. Una vez que
            no sea necesario el tratamiento de tus Datos, mantendremos tus Datos
            durante los plazos de prescripción legal de acuerdo con la normativa
            fiscal, contable y en aplicación del Código Civil.
            <br />
            <br />
            Trascurridos los plazos de prescripción legal, los eliminaremos y/o
            bloquearemos conforme a nuestra política de retención y supresión de
            Datos.
            <br />
            <br />
            De acuerdo a los estándares del sector, mantenemos medidas técnicas
            y organizativas contra la destrucción accidental o ilegal, pérdida o
            alteración accidental, revelación o acceso no autorizado y otras
            formas o procedimientos ilegales.
            <br />
            <br />
            Sin perjuicio de lo anterior, la seguridad de la información
            transmitida a través de internet no puede ser garantizada. En este
            sentido, Undiscovered no puede ser considerada responsable de
            ninguna intercepción o interrupción de comunicaciones a través de
            Internet o de cambios de información o pérdida de la misma. Los
            usuarios de la Plataforma son responsables de mantener la seguridad
            de cualquier contraseña, nombre de usuario o cualquier otra forma de
            identificación para obtener acceso a áreas protegidas por contraseña
            de cualquiera de nuestros Servicios. A fin de protegerte tanto a ti
            como tu información, podremos suspender el uso de cualquiera de los
            servicios de Undiscovered, sin aviso previo, pendiente de
            investigación, si se sospecha que exista violación de seguridad.
            <br /> <br />
            <h3 className="color-secondary">
              Enlaces a servicios de terceros.
            </h3>
            Los Servicios de la Plataforma pueden contener enlaces a páginas web
            de terceros y sus servicios (“Servicios de Terceros”) con los cuales
            no tenemos afiliación. Un enlace a cualquier Servicio de Tercero no
            significa que lo respaldemos, tampoco la calidad o precisión de la
            información contenida en dicho link. Si decides visitar un Servicio
            de Tercero, estás sujeto a sus condiciones y/o política de
            privacidad, revisa detenidamente los avisos legales y de privacidad
            de dichos servicios.
            <br /> <br />
            <h3 className="color-secondary">
              Lugar de procesamiento de los Datos
            </h3>
            Los Datos son procesados en las oficinas y ubicación de
            Undiscovered, sus equipos de trabajo y en cualesquiera otros lugares
            donde los Usuarios y/o Suscriptores estén ubicados al momento del
            procesamiento de Datos.
            <br /> <br />
            Dependiendo de la ubicación del Usuario y/o Suscriptor, la
            transferencia de Datos puede realizarse en un país distinto al suyo.
            <br /> <br />
            En caso de requerir información adicional respecto del procesamiento
            internacional de sus Datos, el Usuario y/o Suscriptor pueden
            contactar a Undiscovered con la cuestión respectiva.
            <br /> <br />
            <h3 className="color-secondary">
              Modificaciones a esta Política de Privacidad
            </h3>
            Undiscovered se reserva el derecho a realizar modificaciones de la
            Política de Privacidad en cualquier momento, mediante un aviso en la
            Plataforma y/o, siempre que sea técnica y legalmente factible,
            mediante comunicación a la información de contacto disponible para
            Undiscovered.
            <br /> <br />
            Es altamente recomendable revisar la presente página y Política de
            Privacidad, refiriéndose a la fecha de última modificación al final.
            <br /> <br />
            En caso de que las modificaciones requieran un consentimiento nuevo
            de parte del Usuario y/o Suscriptor, Undiscovered deberá obtenerlo
            nuevamente mediante cualquier medio, físico o digital.
            <br />
            <h5 className="color-black">
              Fecha de ultima actualización: Junio 19, 2020
            </h5>
            <br />
            <br />
            <br />
            <br />
          </p>
        </Container>
      </>
    );
  }
}

export default PoliticaPrivacidad;
