import firebase from "./../config/firebase.js";

const userHelpers = {
  /**
   * Gets all data from a user
   * @method
   * @param id user id from which to get data
   */
  getUserData: async (id) => {
    if (id) {
      const doc = await firebase.firestore().collection("users").doc(id).get();
      const user = { id: doc.id, ...doc.data() };
      return user;
    } else {
      return [];
    }
  },

  /**
   * Gets the username from a user id
   * @method
   * @param id user id from which to get data
   */
  getUsername: async (id) => {
    if (id) {
      const doc = await firebase.firestore().collection("users").doc(id).get();
      const username = doc.data().username;
      return username;
    } else {
      return [];
    }
  },

  /**
   * Gets the user data from username (url type)
   * @method
   * @param username username to look up
   */
  getUserByUrl: async (username) => {
    var user;
    await firebase
      .firestore()
      .collection("users")
      .get()
      .then((querySnapshot) => {
        querySnapshot.docs.map((doc) => {
          if (doc.data().username === username) {
            user = { id: doc.id, ...doc.data() };
          }
          return doc.data();
        });
      });
    return user;
  },

  /**
   * Checks current user type
   * @method
   */
  getUserType: async () => {
    const uid = localStorage.getItem("userUndiscovered");
    if (uid) {
      const doc = await firebase.firestore().collection("users").doc(uid).get();
      const user_type = doc.data()?.user_type;
      return user_type;
    } else {
      return [];
    }
  },

  /**
   * Updates the displayName from username
   * @method
   * @param username username to set
   */
  updateUsername: async (username) => {
    await firebase.auth().currentUser.updateProfile({
      displayName: username,
    });
  },

  /**
   * Gets all posts from one user
   * @method
   * @param id user id to be looked up
   */
  getUserPosts: async (id) => {
    return firebase
      .firestore()
      .collection("posts")
      .where("author", "==", id)
      .where("video_type", "in", ["game", "skill"])
      .orderBy("dateCreated", "desc")
      .get()
      .then((querySnapshot) =>
        querySnapshot.docs.map((d) => ({ id: d.id, ...d.data() }))
      );
  },

  /**
   * Gets all posts from all users
   * @method
   */
  getAllPosts: async (start, number = 3) => {
    const reference = firebase
      .firestore()
      .collection("posts")
      .where("video_type", "in", ["game", "skill"])
      .limit(number)
      .orderBy("dateCreated", "desc");

    const data = await (start ? reference.startAfter(start) : reference)
      .get()
      .then((query) =>
        query.docs
          .filter((d) => !!d.data().link)
          .map((doc) => ({ id: doc.id, ...doc.data() }))
      );
    const userPromises = data.map((d) =>
      firebase
        .firestore()
        .collection("users")
        .doc(d.author)
        .get()
        .then((d) => ({ id: d.id, ...d.data() }))
    );
    const users = await Promise.all(userPromises);
    const posts = data.map((d, index) => ({ ...d, user: users[index] }));
    return posts;
  },

  /**
   * Gets all posts from a certain type
   * @method
   * @param type type of post to filter
   */
  getPostsByType: async (type) => {
    var users = [];
    await firebase
      .firestore()
      .collection("users")
      .get()
      .then((querySnapshot) => {
        querySnapshot.docs.map((doc) => {
          users.push({ id: doc.id, ...doc.data() });
          return doc.data();
        });
      });

    var posts = [];
    const uid = localStorage.getItem("userUndiscovered");
    for (let i = 0; i < users.length; i++) {
      await firebase
        .firestore()
        .collection("users")
        .doc(users[i].id)
        .collection("posts")
        .get()
        .then((querySnapshot) => {
          querySnapshot.docs.map((doc) => {
            if (users[i].id !== uid && doc.data().video_type === type) {
              let p = doc.data();
              p.user = users[i];
              p.id = doc.id;
              posts.push(p);
            }
            return doc.data();
          });
        });
    }
    return posts;
  },

  /**
   * Gets all post data from a certain post
   * @method
   * @param post_id id of post to look up
   */
  getPostData: async (post_id) => {
    const doc = await firebase
      .firestore()
      .collection("posts")
      .doc(post_id)
      .get();
    const post = { id: doc.id, ...doc.data() };
    return post;
  },

  /**
   * Change a user to be discovered
   * Saves discovering in current user
   * Saves discoveries in user being discovered
   * @method
   * @param discovered_id id of discovered user
   */
  discoverUser: async (discovered_id) => {
    const uid = localStorage.getItem("userUndiscovered");
    await firebase
      .firestore()
      .collection("users")
      .doc(uid)
      .collection("discovering")
      .doc(discovered_id)
      .set({
        date_discovered: new Date(),
      });
    await firebase
      .firestore()
      .collection("users")
      .doc(discovered_id)
      .collection("discoveries")
      .doc(uid)
      .set({
        date_discovered: new Date(),
      });
  },

  /**
   * Change a user to be undiscovered
   * Deletes discovering in current user
   * Deletes discoveries in user being discovered
   * @method
   * @param discovered_id id of discovered user
   */
  undiscoverUser: async (discovered_id) => {
    const uid = localStorage.getItem("userUndiscovered");
    await firebase
      .firestore()
      .collection("users")
      .doc(uid)
      .collection("discovering")
      .doc(discovered_id)
      .delete();
    await firebase
      .firestore()
      .collection("users")
      .doc(discovered_id)
      .collection("discoveries")
      .doc(uid)
      .delete();
  },

  /**
   * Get all info on discoveries and discovering
   * @method
   * @param user_id id of user to be looked up
   */
  getDiscoveriesDiscovering: async (user_id) => {
    var discoveries = [];
    await firebase
      .firestore()
      .collection("users")
      .doc(user_id)
      .collection("discoveries")
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          discoveries.push(doc.id);
        });
      });

    var discovering = [];
    await firebase
      .firestore()
      .collection("users")
      .doc(user_id)
      .collection("discovering")
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          discovering.push(doc.id);
        });
      });
    return [discoveries, discovering];
  },

  /**
   * Get all users
   * @method
   */
  getAllUsers: async () => {
    var users = [];
    await firebase
      .firestore()
      .collection("users")
      .get()
      .then((querySnapshot) => {
        querySnapshot.docs.map((doc) => {
          users.push({ id: doc.id, ...doc.data() });
          return doc.data();
        });
      });
    return users;
  },

  /**
   * Get all users of type player
   * @method
   */
  getPlayerUsers: async () => {
    var users = [];
    await firebase
      .firestore()
      .collection("users")
      .get()
      .then((querySnapshot) => {
        querySnapshot.docs.map((doc) => {
          if (doc.data().user_type === "player") {
            users.push({ id: doc.id, ...doc.data() });
          }
          return doc.data();
        });
      });
    return users;
  },

  /**
   * Get all discoveries from a certain user
   * @method
   * @param user_id id of user to be looked up
   */
  getDiscoveries: async (user_id) => {
    var users = [];
    await firebase
      .firestore()
      .collection("users")
      .get()
      .then((querySnapshot) => {
        querySnapshot.docs.map((doc) => {
          users.push({ id: doc.id, ...doc.data() });
          return doc.data();
        });
      });

    var discoveries = [];
    await firebase
      .firestore()
      .collection("users")
      .doc(user_id)
      .collection("discoveries")
      .get()
      .then((querySnapshot) => {
        querySnapshot.docs.map((doc) => {
          for (let i = 0; i < users.length; i++) {
            if (users[i].id === doc.id) {
              discoveries.push(users[i]);
            }
          }
          return doc.data();
        });
      });
    return discoveries;
  },

  /**
   * Get all discovering from a certain user
   * @method
   * @param user_id id of user to be looked up
   */
  getDiscovering: async (user_id) => {
    var users = [];
    await firebase
      .firestore()
      .collection("users")
      .get()
      .then((querySnapshot) => {
        querySnapshot.docs.map((doc) => {
          users.push({ id: doc.id, ...doc.data() });
          return doc.data();
        });
      });

    var discovering = [];
    await firebase
      .firestore()
      .collection("users")
      .doc(user_id)
      .collection("discovering")
      .get()
      .then((querySnapshot) => {
        querySnapshot.docs.map((doc) => {
          for (let i = 0; i < users.length; i++) {
            if (users[i].id === doc.id) {
              discovering.push(users[i]);
            }
          }
          return doc.data();
        });
      });
    return discovering;
  },

  /**
   * Get all discovered from current user
   * @method
   */
  getDiscoveredUsers: async () => {
    const uid = localStorage.getItem("userUndiscovered");
    const discovering = [];
    await firebase
      .firestore()
      .collection("users")
      .doc(uid)
      .collection("discovering")
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          discovering.push(doc.id);
          return doc.id;
        });
      })
      .catch((e) => {
        console.log("There was an error: ", e);
      });
    return discovering;
  },

  /**
   * Delete current user info
   * @method
   */
  deleteUser: async () => {
    firebase
      .firestore()
      .collection("users")
      .doc(firebase.auth().currentUser.uid)
      .delete()
      .then(() => {
        firebase
          .auth()
          .currentUser.delete()
          .then(() => {
            console.log("success");
            return true;
          })
          .catch((err) => {
            console.log("error");
            console.log(err);
            return false;
          });
      })
      .catch((err) => {
        console.log(err);
        return false;
      });
  },

  /**
   * Delete current user info
   * @method
   */
  reportUser: async (user_id, reason) => {
    const uid = localStorage.getItem("userUndiscovered");
    try {
      firebase.firestore().collection("user_reports").add({
        reported_by: uid,
        user_id: user_id,
        date: new Date(),
        reason: reason,
      });
      console.log("success");
      return true;
    } catch (err) {
      console.log(err);
      return false;
    }
  },

  /**
   * Delete current user info
   * @method
   */
  reportVideo: async (user_id, reason, post_id) => {
    const uid = localStorage.getItem("userUndiscovered");
    try {
      firebase.firestore().collection("user_reports").add({
        reported_by: uid,
        user_id: user_id,
        date: new Date(),
        post_id: post_id,
        reason: reason,
      });
      return true;
    } catch (err) {
      console.log(err);
      return false;
    }
  },
  minTommss(minutes) {
    minutes = minutes * 100;
    console.log(minutes);
    var sign = minutes < 0 ? "-" : "";
    var min = Math.floor(Math.abs(minutes));
    var sec = Math.floor((Math.abs(minutes) * 60) % 60);
    return (
      sign + (min < 10 ? "0" : "") + min + ":" + (sec < 10 ? "0" : "") + sec
    );
  },
};

export default userHelpers;
