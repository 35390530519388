import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { 
  Container, 
  Row
} from "reactstrap";
import Footer from "components/Footers/Footer.js";
import routes from "routes.js";

/**
 * AuthLayout Class.
 * @constructor
 */
class Auth extends React.Component {

  /**
   * Upon mounting, add background.
   * @method
   */
  componentDidMount() {
    document.body.classList.add("bg-default");
  }

  /**
   * Upon unmount, remove background.
   * @method
   */
  componentWillUnmount() {
    document.body.classList.remove("bg-default");
  }

  /**
   * Get routes for this layout
   * @method
   */
  getRoutes = routes => {
    return routes.map((prop, key) => {
      if (prop.layout === "/auth") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

   /**
   * Renders the AuthLayout component.
   * @see AuthLayout Component
   */
  render() {
    return (
      <>
        <div className="main-content p-0 bg-white">
          <Container className="mt-0 pb-2 pt-4">
            <Row className="justify-content-center">
              <Switch>
                {this.getRoutes(routes)}
                <Redirect from="*" to="/auth/login" />
              </Switch>
            </Row>
          </Container>
        </div>
        <Footer />
      </>
    );
  }
}

export default Auth;
