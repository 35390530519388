import React from "react";
import {
  Container,
  Row,
  Col,
  Modal,
  Dropdown,
  DropdownToggle,
  ModalBody,
  Card,
  Button,
} from "reactstrap";

import * as _ from "lodash";
import Video from "../components/Videos/Video";
import PostFilter from "../components/Filters/PostFilter";
import userHelpers from "../helpers/user";
import utilitiesHelpers from "../helpers/utilities";
import {skillContained} from '../helpers/skills';

/**
 * Home Class.
 * Main post feed view.
 * @constructor
 */
class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      random_active: true,
      video_type: "all",
      technical_skills: {},
      physical_skills: {},
      type_skills: {},
      posts: [],
      original_posts: [],
      loading: true,
      filtered_skills: false,
      selected_user: null,
      user_type_filter: ["player", "fan", "agent"],
      noMorePost: false,
      gettingPosts: false,
    };
    this.toggleModal = this.toggleModal.bind(this);
    this.toggleVideoType = this.toggleVideoType.bind(this);
    this.setDiscovered = this.setDiscovered.bind(this);
    this.setUndiscovered = this.setUndiscovered.bind(this);
    this.getUtilities = this.getUtilities.bind(this);
    this.setSkills = this.setSkills.bind(this);
    this.setUserTypeFilter = this.setUserTypeFilter.bind(this);
    this.shouldGetMorePosts = this.shouldGetMorePosts.bind(this);
    this.skillContained = skillContained.bind(this);
    // this.userDiscoveryUpdated = this.userDiscoveryUpdated.bind(this);
  }

  /**
   * Upon mounting.
   * Get utilities from database.
   * Get the available posts.
   * Check for discovered/undiscovered users.
   * @method
   */
  async componentDidMount() {
    await this.getUtilities();
    await this.getPosts();
    await this.getDiscoveredUsers();
    window.addEventListener("scroll", this.shouldGetMorePosts);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.shouldGetMorePosts);
  }

  async shouldGetMorePosts(event) {
    const target = event.target.scrollingElement;
    const isBottom =
      target.scrollHeight - target.scrollTop - 300 <=
      event.target.scrollingElement.clientHeight;
    if (isBottom && !this.state.gettingPosts && !this.state.noMorePost) {
      this.setState({ gettingPosts: true });
      await this.getPosts();
      this.getDiscoveredUsers();
    }
  }

  /**
   * Gets the posts from db
   * @method
   */
  async getPosts() {
    const original = this.state.original_posts;
    const last = original.length ? original[original.length - 1] : undefined;
    const posts = await userHelpers.getAllPosts(last && last.dateCreated);
    const noMorePost =
      !posts.length || last?.id === posts[posts.length - 1]?.id;
    this.setState((state) => ({
      original_posts: [...state.original_posts, ...posts],
      gettingPosts: false,
      noMorePost,
    }));
  }

  /**
   * Gets the physical/technical skills utilities from db
   * @method
   */
  async getUtilities() {
    const [
      physical_skills,
      technical_skills,
      type_skills,
    ] = await utilitiesHelpers.getUtilitiesSkills();
    this.setState({
      physical_skills: physical_skills,
      technical_skills: technical_skills,
      type_skills: type_skills,
      filtered_skills: false,
    });
  }

  /**
   * Gets the discovered users and checks if the users from the posts are discovered or not
   * @method
   */
  async getDiscoveredUsers() {
    const uid = localStorage.getItem("userUndiscovered");
    if (!uid) return;
    const discovered = await userHelpers.getDiscoveredUsers();
    var posts = this.state.original_posts;
    if (posts) {
      for (let i = 0; i < posts.length; i++) {
        posts[i].user.discovered = false;
        for (let j = 0; j < discovered.length; j++) {
          if (posts[i].user.id === discovered[j]) {
            posts[i].user.discovered = true;
          }
        }
      }
      this.setState({ posts: posts, loading: false });
    } else {
      this.setState({ posts: [], loading: false });
    }
  }

  /***
   * Checks if discovered/undiscovered action took place and updates directly in view without having to change filter.
   * @method
   * @param updated_uid user ID that was updated
   * @param discovered flag for discovered/undiscovered
   */
  // userDiscoveryUpdated(updated_uid, discovered) {
  //   let temp_posts = this.state.posts;
  //   for (let i = 0; i < temp_posts.length; i++) {
  //     if (temp_posts[i].user.id === updated_uid) {
  //       temp_posts[i].user.discovered = discovered;
  //     }
  //   }
  //   this.setState({ posts: temp_posts });
  // }

  /**
   * Basic toggle for modal
   * @method
   */
  toggleModal() {
    this.setState({ modal: !this.state.modal });
  }

  /**
   * Set if filtered to discovered users.
   * @method
   */
  async setDiscovered() {
    await this.getDiscoveredUsers();
    this.setState({ random_active: false });
  }

  /**
   * Set if filtered to undiscovered users.
   * @method
   */
  async setUndiscovered() {
    await this.getDiscoveredUsers();
    this.setState({ random_active: true });
  }

  /**
   * Toggle video type.
   * @method
   * @param type Skill/Game video type
   */
  toggleVideoType(type) {
    this.setState({ video_type: type });
  }

  /**
   * Set the filtered skills from Filter Posts component
   * @method
   * @param video_type Skill/Game video type
   * @param physical_skills Filtered active pysical skills
   * @param technical_skills Filtered active technical skills
   * @param type_skills Filtered active type skills
   * @param filtered Boolean flag to see if filter must be applied
   */
  async setSkills(
    video_type,
    physical_skills,
    technical_skills,
    type_skills,
    filtered
  ) {
    this.setState({
      video_type: video_type,
      physical_skills: physical_skills,
      technical_skills: technical_skills,
      type_skills: type_skills,
      filtered_skills: filtered,
    });
    await this.getDiscoveredUsers();
  }

  setUserTypeFilter(user_type) {
    Array.prototype.remove = function () {
      var what,
        a = arguments,
        L = a.length,
        ax;
      while (L && this.length) {
        what = a[--L];
        while ((ax = this.indexOf(what)) !== -1) {
          this.splice(ax, 1);
        }
      }
      return this;
    };

    console.log(user_type);
    var user_type_filter = this.state.user_type_filter;
    if (user_type_filter.includes(user_type)) {
      user_type_filter.remove(user_type);
    } else {
      user_type_filter.push(user_type);
    }
    console.log(user_type_filter);
    this.setState({
      user_type_filter: user_type_filter,
    });
  }

  /**
   * Renders the Home component.
   * @see Home Component
   */
  render() {
    if (this.state.loading) {
      return (
        <div className="preloader-wrapper">
          <embed
            className="preloader-icon"
            src={require("assets/img/brand/preloader.svg")}
          />
        </div>
      );
    } else {
      return (
        <>
          {/* Page content */}
          <Container className="mt-0 mt-lg-3" fluid>
            <Row>
              <Col
                className="order-xl-1 mb-0 mb-xl-0 mt-lg-1 no-mobile p-sticky"
                lg="4"
              >
                <div className="p-sticky-1">
                  <div className="d-flex justify-content-center mb-3">
                    <Button
                      className="discovered button small"
                      color={
                        this.state.user_type_filter.includes("player")
                          ? "primary"
                          : "white"
                      }
                      size="sm"
                      onClick={() => {
                        this.setUserTypeFilter("player");
                      }}
                    >
                      JUGADORES
                    </Button>
                    <Button
                      className="discovered button small"
                      color={
                        this.state.user_type_filter.includes("fan")
                          ? "primary"
                          : "white"
                      }
                      size="sm"
                      onClick={() => {
                        this.setUserTypeFilter("fan");
                      }}
                    >
                      FANS
                    </Button>
                    <Button
                      className="discovered button small"
                      color={
                        this.state.user_type_filter.includes("agent")
                          ? "primary"
                          : "white"
                      }
                      size="sm"
                      onClick={() => {
                        this.setUserTypeFilter("agent");
                      }}
                    >
                      AGENTES/CLUBES
                    </Button>
                  </div>
                  {this.state.loading ? null : (
                    <PostFilter
                      toggleVideoType={this.toggleVideoType}
                      getUtilities={() => this.getUtilities()}
                      setSkills={this.setSkills}
                      physical_skills={this.state.physical_skills}
                      technical_skills={this.state.technical_skills}
                      type_skills={this.state.type_skills}
                    />
                  )}
                </div>
              </Col>
              <Col className="order-xl-1 mb-0 mt--2 mb-lg-0 mt-lg-1" lg="8">
                <Row className="justify-content-center bg-primary pt-3 pb-2 border-radius-10-top pb-md-3 mb-md--3 ml-md-0 mr-md-0 shadow">
                  <div
                    onClick={() => this.setUndiscovered()}
                    className={
                      "post-type-select h4 txt-black " +
                      (this.state.random_active ? "active" : "")
                    }
                  >
                    UNDISCOVERED
                  </div>
                  <div className="separator-skills">|</div>
                  <div
                    onClick={() => this.setDiscovered()}
                    className={
                      "post-type-select h4 txt-black " +
                      (this.state.random_active ? "" : "active")
                    }
                  >
                    DISCOVERED
                  </div>
                </Row>
                <Row className="justify-content-center bg-primary pt-0 mt--1 pb-2 no-desktop">
                  <Dropdown
                    isOpen={false}
                    className="full-width hz-padded"
                    toggle={() => {
                      this.toggleModal();
                    }}
                  >
                    <DropdownToggle
                      caret
                      className="full-width btn-white mobile-bigger-dropdown"
                    >
                      Filtrar Posts
                    </DropdownToggle>
                  </Dropdown>
                </Row>
                <Modal isOpen={this.state.modal} className="posts-filter-modal">
                  <ModalBody className="p-0">
                    {this.state.loading ? null : (
                      <PostFilter
                        toggleVideoType={this.toggleVideoType}
                        getUtilities={() => this.getUtilities()}
                        setSkills={this.setSkills}
                        physical_skills={this.state.physical_skills}
                        technical_skills={this.state.technical_skills}
                        type_skills={this.state.type_skills}
                        toggleModal={() => this.toggleModal()}
                        is_modal={true}
                      />
                    )}
                  </ModalBody>
                </Modal>
                <div className="user-type-filter d-flex justify-content-center my-3 no-desktop">
                  <Button
                    className="discovered button small"
                    color={
                      this.state.user_type_filter.includes("player")
                        ? "primary"
                        : "white"
                    }
                    size="sm"
                    onClick={() => {
                      this.setUserTypeFilter("player");
                    }}
                  >
                    JUGADORES
                  </Button>
                  <Button
                    className="discovered button small"
                    color={
                      this.state.user_type_filter.includes("fan")
                        ? "primary"
                        : "white"
                    }
                    size="sm"
                    onClick={() => {
                      this.setUserTypeFilter("fan");
                    }}
                  >
                    FANS
                  </Button>
                  <Button
                    className="discovered button small"
                    color={
                      this.state.user_type_filter.includes("agent")
                        ? "primary"
                        : "white"
                    }
                    size="sm"
                    onClick={() => {
                      this.setUserTypeFilter("agent");
                    }}
                  >
                    AGENTES/CLUBES
                  </Button>
                </div>
                <Card
                  className="shadow p-3 mt-2 pt-2 pb-2"
                  style={{ overflow: "auto" }}
                >
                  <Row className="justify-content-around">
                    {this.state.posts.map((post) => {
                      if (post.user.blocked === true) {
                        return null;
                      } else {
                        if (
                          this.state.video_type === "all" ||
                          post.video_type === this.state.video_type
                        ) {
                          if (this.state.random_active) {
                            if (post.user.discovered !== true) {
                              if (post.user.username === "undiscovered") {
                                return null;
                              } else {
                                if (this.state.video_type === "skill") {
                                  if (post.video_type === "skill") {
                                    if (this.state.filtered_skills) {
                                      if (
                                        this.skillContained(
                                          post.physical_skills,
                                          post.technical_skills,
                                          post.type_skills
                                        )
                                      ) {
                                        if (
                                          this.state.user_type_filter.includes(
                                            post.user.user_type
                                          )
                                        ) {
                                          return (
                                            <Video
                                              {...this.props}
                                              // userDiscoveryUpdated={
                                              //   this.userDiscoveryUpdated
                                              // }
                                              key={
                                                post.id +
                                                ":" +
                                                post.user.discovered
                                              }
                                              showButton={
                                                localStorage.getItem(
                                                  "userUndiscovered"
                                                ) === post.user.id
                                                  ? false
                                                  : true
                                              }
                                              showMenu={
                                                localStorage.getItem(
                                                  "userUndiscovered"
                                                ) === post.user.id
                                                  ? true
                                                  : false
                                              }
                                              user={post.user}
                                              post={post}
                                            />
                                          );
                                        } else {
                                          return null;
                                        }
                                      } else {
                                        return null;
                                      }
                                    } else {
                                      if (
                                        this.state.user_type_filter.includes(
                                          post.user.user_type
                                        )
                                      ) {
                                        return (
                                          <Video
                                            {...this.props}
                                            // userDiscoveryUpdated={
                                            //   this.userDiscoveryUpdated
                                            // }
                                            key={
                                              post.id +
                                              ":" +
                                              post.user.discovered
                                            }
                                            showButton={
                                              localStorage.getItem(
                                                "userUndiscovered"
                                              ) === post.user.id
                                                ? false
                                                : true
                                            }
                                            showMenu={
                                              localStorage.getItem(
                                                "userUndiscovered"
                                              ) === post.user.id
                                                ? true
                                                : false
                                            }
                                            user={post.user}
                                            post={post}
                                          />
                                        );
                                      } else {
                                        return null;
                                      }
                                    }
                                  } else {
                                    return null;
                                  }
                                } else if (this.state.video_type === "game") {
                                  if (post.video_type === "game") {
                                    if (
                                      this.state.user_type_filter.includes(
                                        post.user.user_type
                                      )
                                    ) {
                                      return (
                                        <Video
                                          {...this.props}
                                          // userDiscoveryUpdated={
                                          //   this.userDiscoveryUpdated
                                          // }
                                          key={
                                            post.id + ":" + post.user.discovered
                                          }
                                          showButton={
                                            localStorage.getItem(
                                              "userUndiscovered"
                                            ) === post.user.id
                                              ? false
                                              : true
                                          }
                                          showMenu={
                                            localStorage.getItem(
                                              "userUndiscovered"
                                            ) === post.user.id
                                              ? true
                                              : false
                                          }
                                          user={post.user}
                                          post={post}
                                        />
                                      );
                                    } else {
                                      return null;
                                    }
                                  } else {
                                    return null;
                                  }
                                } else if (this.state.video_type === "all") {
                                  if (
                                    this.state.user_type_filter.includes(
                                      post.user.user_type
                                    )
                                  ) {
                                    return (
                                      <Video
                                        {...this.props}
                                        // userDiscoveryUpdated={
                                        //   this.userDiscoveryUpdated
                                        // }
                                        key={
                                          post.id + ":" + post.user.discovered
                                        }
                                        showButton={
                                          localStorage.getItem(
                                            "userUndiscovered"
                                          ) === post.user.id
                                            ? false
                                            : true
                                        }
                                        showMenu={
                                          localStorage.getItem(
                                            "userUndiscovered"
                                          ) === post.user.id
                                            ? true
                                            : false
                                        }
                                        user={post.user}
                                        post={post}
                                      />
                                    );
                                  } else {
                                    return null;
                                  }
                                } else {
                                  return null;
                                }
                              }
                            } else {
                              return null;
                            }
                          } else {
                            if (post.user.username === "undiscovered") {
                              return (
                                <Video
                                  {...this.props}
                                  // userDiscoveryUpdated={
                                  //   this.userDiscoveryUpdated
                                  // }
                                  key={post.id + ":" + post.user.discovered}
                                  showButton={
                                    localStorage.getItem(
                                      "userUndiscovered"
                                    ) === post.user.id
                                      ? false
                                      : true
                                  }
                                  showMenu={
                                    localStorage.getItem(
                                      "userUndiscovered"
                                    ) === post.user.id
                                      ? true
                                      : false
                                  }
                                  user={post.user}
                                  post={post}
                                />
                              );
                            } else {
                              if (post.user.discovered) {
                                if (this.state.video_type === "skill") {
                                  if (post.video_type === "skill") {
                                    if (this.state.filtered_skills) {
                                      if (
                                        this.skillContained(
                                          post.physical_skills,
                                          post.technical_skills,
                                          post.type_skills
                                        )
                                      ) {
                                        if (
                                          this.state.user_type_filter.includes(
                                            post.user.user_type
                                          )
                                        ) {
                                          return (
                                            <Video
                                              {...this.props}
                                              // userDiscoveryUpdated={
                                              //   this.userDiscoveryUpdated
                                              // }
                                              key={
                                                post.id +
                                                ":" +
                                                post.user.discovered
                                              }
                                              showButton={
                                                localStorage.getItem(
                                                  "userUndiscovered"
                                                ) === post.user.id
                                                  ? false
                                                  : true
                                              }
                                              showMenu={
                                                localStorage.getItem(
                                                  "userUndiscovered"
                                                ) === post.user.id
                                                  ? true
                                                  : false
                                              }
                                              user={post.user}
                                              post={post}
                                            />
                                          );
                                        } else {
                                          return null;
                                        }
                                      } else {
                                        return null;
                                      }
                                    } else {
                                      if (
                                        this.state.user_type_filter.includes(
                                          post.user.user_type
                                        )
                                      ) {
                                        return (
                                          <Video
                                            {...this.props}
                                            // userDiscoveryUpdated={
                                            //   this.userDiscoveryUpdated
                                            // }
                                            key={
                                              post.id +
                                              ":" +
                                              post.user.discovered
                                            }
                                            showButton={
                                              localStorage.getItem(
                                                "userUndiscovered"
                                              ) === post.user.id
                                                ? false
                                                : true
                                            }
                                            showMenu={
                                              localStorage.getItem(
                                                "userUndiscovered"
                                              ) === post.user.id
                                                ? true
                                                : false
                                            }
                                            user={post.user}
                                            post={post}
                                          />
                                        );
                                      } else {
                                        return null;
                                      }
                                    }
                                  } else {
                                    return null;
                                  }
                                } else if (this.state.video_type === "game") {
                                  if (post.video_type === "game") {
                                    if (
                                      this.state.user_type_filter.includes(
                                        post.user.user_type
                                      )
                                    ) {
                                      return (
                                        <Video
                                          {...this.props}
                                          // userDiscoveryUpdated={
                                          //   this.userDiscoveryUpdated
                                          // }
                                          key={
                                            post.id + ":" + post.user.discovered
                                          }
                                          showButton={
                                            localStorage.getItem(
                                              "userUndiscovered"
                                            ) === post.user.id
                                              ? false
                                              : true
                                          }
                                          showMenu={
                                            localStorage.getItem(
                                              "userUndiscovered"
                                            ) === post.user.id
                                              ? true
                                              : false
                                          }
                                          user={post.user}
                                          post={post}
                                        />
                                      );
                                    } else {
                                      return null;
                                    }
                                  } else {
                                    return null;
                                  }
                                } else if (this.state.video_type === "all") {
                                  if (
                                    this.state.user_type_filter.includes(
                                      post.user.user_type
                                    )
                                  ) {
                                    return (
                                      <Video
                                        {...this.props}
                                        // userDiscoveryUpdated={
                                        //   this.userDiscoveryUpdated
                                        // }
                                        key={
                                          post.id + ":" + post.user.discovered
                                        }
                                        showButton={
                                          localStorage.getItem(
                                            "userUndiscovered"
                                          ) === post.user.id
                                            ? false
                                            : true
                                        }
                                        showMenu={
                                          localStorage.getItem(
                                            "userUndiscovered"
                                          ) === post.user.id
                                            ? true
                                            : false
                                        }
                                        user={post.user}
                                        post={post}
                                      />
                                    );
                                  } else {
                                    return null;
                                  }
                                } else {
                                  return null;
                                }
                              } else {
                                return null;
                              }
                            }
                          }
                        } else {
                          return null;
                        }
                      }
                    })}
                    {this.state.gettingPosts && (
                      <div className="h4 ls-2">Cargando...</div>
                    )}
                    {this.state.noMorePost && (
                      <div className="h4 ls-2">
                        No hay más posts para mostrar.
                      </div>
                    )}
                    {!this.state.posts[0] && (
                      <div className="h4 ls-2">No hay posts publicados.</div>
                    )}
                  </Row>
                </Card>
              </Col>
            </Row>
          </Container>
        </>
      );
    }
  }
}

export default Home;
