import React from "react";
import {
  Button,
  Media,
  Row,
  Col,
  DropdownItem,
  Dropdown,
  DropdownMenu,
  DropdownToggle,
  Modal,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Input,
  Alert,
} from "reactstrap";
import { findDOMNode } from "react-dom";

import Rating from "react-rating";
import validationHelpers from "./../../helpers/validation";
import userHelpers from "./../../helpers/user";
import ReactPlayer from "react-player";
import emailjs from "emailjs-com";
import { Waypoint } from "react-waypoint";
import PlayerControls from "./PlayerControls";
import moment from "moment";
import localization from "moment/locale/es";

const fullscreenIOSStyle = {
  height: "100vh",
  width: "100vw",
  position: "fixed",
  top: 0,
  left: 0,
  zIndex: 15,
  backgroundColor: "black",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

/**
 * Video Class.
 * Main Video Post component
 * @constructor
 */
class Video extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      played: 0,
      rating: 0,
      already_rated: false,
      avg_rating: "N/A",
      num_of_ratings: 0,
      discovered: false,
      dropdown_open: false,
      confirm_delete: false,
      playing: false,
      muted: false,
      modal: false,
      volume: 0.8,
      reportModal: false,
      reason: "",
      error_message: "",
      user_report_response: "",
      is_fullscreen: false,
      loading: true,
    };
    this.handleRatingChange = this.handleRatingChange.bind(this);
    this.discoverUser = this.discoverUser.bind(this);
    this.toggleDropdown = this.toggleDropdown.bind(this);
    this.deleteVideo = this.deleteVideo.bind(this);
    this.openModal = this.openModal.bind(this);
    this.reportUser = this.reportUser.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleClickFullscreen = this.handleClickFullscreen.bind(this);
    this.handleClickFullscreenOut = this.handleClickFullscreenOut.bind(this);
    this.handleFullScreen = this.handleFullScreen.bind(this);
    this.handleSeekChange = this.handleSeekChange.bind(this);
    this.onReady = this.onReady.bind(this);
  }

  /**
   * Upon mounting.
   * Gets current video ratings.
   * Gets users discovered/undiscovered.
   * Add event listener for fullscreen change.
   * @method
   */
  async componentDidMount() {
    await this.updateRatings();
    await this.getRatings();
    await this.getDiscovered();
    document.addEventListener("fullscreenchange", this.handleFullScreen);
    this.isIOS = this.iOS();
  }

  onReady() {
    this.setState({ loading: false, playing: false });
  }

  /**
   * Determine if is an iOS device
   * @method
   */
  iOS() {
    return [
      "iPad Simulator",
      "iPhone Simulator",
      "iPod Simulator",
      "iPad",
      "iPhone",
      "iPod",
    ].includes(navigator.platform);
    // iPad on iOS 13 detection
    // || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
  }

  /**
   * Before unmounting.
   * Remove the event listener for full screen
   * @method
   */
  componentWillUnmount() {
    document.removeEventListener("fullscreenchange", this.handleFullScreen);
  }

  /**
   * Handler for the fullscreen change listener
   * If the user exists the fullscreen, the state is updated
   * @method
   */
  handleFullScreen() {
    if (!document.fullscreenElement) {
      this.setState({
        is_fullscreen: false,
      });
    }
  }

  ref = (player) => {
    this.playerWrapper = player;
  };

  /**
   * Basic change handle
   * @method
   * @param e Element that fires function
   */
  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  changeDateFormat() {
    var date = moment.unix(this.props.post.dateCreated.seconds);
    date.locale("es", localization);
    return date.format("LLLL");
  }

  /**
   * Sets user to discovered/undiscovered
   * @method
   */
  async getDiscovered() {
    const discovered = await userHelpers.getDiscoveredUsers();
    let temp_user = this.props.user;
    for (let i = 0; i < discovered.length; i++) {
      if (temp_user.id === discovered[i]) {
        this.setState({ discovered: true });
        break;
      }
    }
  }
  //video controls
  handleClickPlay = () => {
    if (this.state.loading) return;
    this.setState({ playing: !this.state.playing });
  };
  handleVolumeChange = (e, ne) => {
    this.setState({ volume: parseFloat(ne / 100) });
  };
  handleToggleMuted = () => {
    console.log("muted");
    this.setState({ muted: !this.state.muted });
  };

  handleProgress = (state) => {
    // We only want to update time slider if we are not currently seeking
    if (!this.state.seeking) {
      this.setState(state);
    }
  };
  handleSeekMouseDown = (e) => {
    this.setState({ seeking: true });
  };

  handleSeekChange = (e) => {
    this.setState({ played: parseFloat(e.target.value) });
    this.player.seekTo(parseFloat(e.target.value));
  };

  handleSeekMouseUp = (e) => {
    this.setState({ seeking: false });
    this.player.seekTo(parseFloat(e.target.value));
  };

  handleClickFullscreen() {
    if (this.playerWrapper.requestFullscreen) {
      this.playerWrapper.requestFullscreen({ navigationUI: "hide" });
    } else if (this.playerWrapper.mozRequestFullScreen) {
      this.playerWrapper.mozRequestFullScreen();
    } else if (this.isIOS) {
      document.getElementsByTagName("body")[0].style.overflow = "hidden";
    } else if (this.playerWrapper.webkitRequestFullscreen) {
      this.playerWrapper.webkitRequestFullscreen();
    }
    this.setState({ is_fullscreen: true, playing: true });
  }

  handleClickFullscreenOut() {
    if (!document.fullscreenElement && !this.isIOS) return;
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen();
    } else if (this.isIOS) {
      document.getElementsByTagName("body")[0].style.overflow = "scroll";
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen();
    } else if (document.msExitFullscreen) {
      document.msExitFullscreen();
    }
    this.setState({ is_fullscreen: false });
    this.wrapper.scrollIntoView({ block: "center" });
  }

  handleDuration = (duration) => {
    this.setState({ duration });
  };
  /**
   * Sets user to discovered/undiscovered
   * @method
   */
  async discoverUser() {
    if (this.state.discovered === false) {
      await userHelpers.discoverUser(this.props.user.id);
    } else {
      await userHelpers.undiscoverUser(this.props.user.id);
    }
    //this.props.userDiscoveryUpdated(this.props.user.id, !this.state.discovered);
    this.setState({
      discovered: !this.state.discovered,
    });
  }

  /**
   * Update ratings after rating change to edit average
   * @method
   */
  async updateRatings() {
    const uid = localStorage.getItem("userUndiscovered");
    var post_id =
      this.props.post.id === undefined
        ? this.props.post.id
        : this.props.post.id;
    var user_id = this.props.user.id === undefined ? uid : this.props.user.id;
    var ratings = await validationHelpers.getRatings(post_id, user_id);
    let total = 0;

    for (let i = 0; i < ratings.length; i++) {
      total += ratings[i].rating;
      if (ratings[i].rated_by === uid) {
        this.setState({
          already_rated: true,
          // rating: ratings[i].rating,
          my_rating_id: ratings[i].id,
        });
      }
    }
    if (ratings.length > 1) {
      var avg_rating = total / ratings.length;
      this.setState({
        avg_rating: avg_rating.toFixed(1),
      });
    }
    this.setState({ num_of_ratings: ratings.length });
  }

  async getRatings() {
    const uid = localStorage.getItem("userUndiscovered");
    var post_id =
      this.props.post.id === undefined
        ? this.props.post.id
        : this.props.post.id;
    var user_id = this.props.user.id === undefined ? uid : this.props.user.id;
    var ratings = await validationHelpers.getRatings(post_id, user_id);
    let total = 0;

    for (let i = 0; i < ratings.length; i++) {
      total += ratings[i].rating;
      if (ratings[i].rated_by === uid) {
        this.setState({
          already_rated: true,
          rating: ratings[i].rating,
          my_rating_id: ratings[i].id,
        });
      }
    }
    if (ratings.length > 1) {
      var avg_rating = total / ratings.length;
      this.setState({
        avg_rating: avg_rating.toFixed(1),
      });
    }
    this.setState({ num_of_ratings: ratings.length });
  }

  /**
   * Basic rating change handle
   * @method
   * @param value value of the current rating
   */
  async handleRatingChange(value) {
    this.setState({ rating: value });
    if (this.state.already_rated) {
      await validationHelpers.changeRatingVideo(
        this.props.post.id,
        this.props.user.id,
        this.state.my_rating_id,
        value
      );
    } else {
      await validationHelpers.rateVideo(
        this.props.post.id,
        this.props.user.id,
        value
      );
    }
    this.updateRatings();
  }

  /**
   * Deletes video from db
   * @method
   */
  async deleteVideo() {
    try {
      await validationHelpers.deletePost(this.props.post.id);
      window.location.reload();
    } catch (error) {
      console.error(error);
    }
  }

  /**
   * Basic toggle for dropdowns
   * @method
   */
  toggleDropdown() {
    this.setState({ dropdown_open: !this.state.dropdown_open });
  }

  async reportUser() {
    console.log(this.props.post.id);
    if (this.state.reason === "") {
      this.setState({
        error_message: "Es necesario poner una razón para el reporte.",
      });
    } else {
      var success = await userHelpers.reportVideo(
        this.props.user.id,
        this.state.reason,
        this.props.post.id
      );
      if (success) {
        try {
          emailjs
            .sendForm(
              "service_town7n5",
              "template_ib814nv",
              "#reportForm",
              "user_5Ye8HPD0sH2KC7wy4mfeE"
            )
            .then(
              (result) => {
                this.setState({
                  user_report_response: "El usuario fue reportado.",
                });
                console.log(result);
              },
              (error) => {
                console.log(error);
                this.setState({
                  user_report_response:
                    "No pudimos reportar al usuario en este momento, por favor intenta más tarde.",
                });
              }
            );
        } catch (e) {
          console.log(e);
        }
      } else {
        this.setState({
          user_report_response:
            "No pudimos reportar al usuario en este momento, por favor intenta más tarde.",
        });
      }
    }
  }

  /**
   * Basig modal toggle.
   * Resets modal values.
   * @method
   */
  openModal() {
    this.setState({
      reportModal: !this.state.reportModal,
      reason: "",
      error_message: "",
      user_report_response: "",
    });
  }

  //handles viewport to pause video when scrolling
  handleExitViewport = () => {
    this.setState({ playing: false });
  };

  /**
   * Renders the SideBar component.
   * @see SideBar Component
   */
  render() {
    let report_modal = (
      <Modal
        isOpen={this.state.reportModal}
        toggle={() => this.openModal()}
        className="mt-8"
      >
        <ModalBody className="text-center mt-2 pb-0">
          {this.state.user_report_response === "" ? (
            <>
              <p className="mb-2">
                ¿Estas seguro de que quieres reportar a este video?
              </p>
              <Form role="form" id="reportForm">
                <FormGroup className="mb-2">
                  <Input
                    className="form-control-alternative"
                    rows="3"
                    type="textarea"
                    name="reason"
                    value={this.state.reason}
                    placeholder="Razón del reporte..."
                    onChange={this.handleChange}
                  />
                  <Input
                    hidden
                    type="text"
                    name="report_user_id"
                    value={this.props.user.id}
                    onChange={() => {}}
                  />
                  <Input
                    hidden
                    type="text"
                    name="report_video_id"
                    value={this.props.post.id}
                    onChange={() => {}}
                  />
                </FormGroup>
                <Alert
                  color="warning"
                  hidden={this.state.error_message === "" ? true : false}
                >
                  <strong>Error:</strong> {this.state.error_message}
                </Alert>
              </Form>
            </>
          ) : (
            <p className="mb-2">{this.state.user_report_response}</p>
          )}
        </ModalBody>
        <ModalFooter className="d-flex justify-content-center">
          <Button color="primary" onClick={() => this.openModal()}>
            {this.state.user_report_response === "" ? "CANCELAR" : "TERMINAR"}
          </Button>
          {this.state.user_report_response === "" ? (
            <Button color="white" onClick={() => this.reportUser()}>
              REPORTAR
            </Button>
          ) : null}
        </ModalFooter>
      </Modal>
    );

    let confirm_delete;

    if (this.state.confirm_delete) {
      confirm_delete = (
        <div className="full-width text-center">
          <div className="alert">
            <h3>¿Estas seguro de que quieres eliminar este post?</h3>
            <Button
              color="primary"
              onClick={() => {
                this.setState({ confirm_delete: false });
              }}
              size="sm"
            >
              CANCELAR
            </Button>
            <Button
              color="white"
              onClick={() => {
                this.deleteVideo();
              }}
              size="sm"
            >
              ELIMINAR
            </Button>
          </div>
        </div>
      );
    }

    let actionButton;
    if (this.props.showButton) {
      if (this.props.user.username !== "undiscovered") {
        actionButton = (
          <>
            <Button
              className="discovered button small"
              color={this.state.discovered ? "primary" : "white"}
              onClick={this.discoverUser}
              size="sm"
            >
              {this.state.discovered ? "DISCOVERED" : "UNDISCOVERED"}
            </Button>
            <Dropdown
              isOpen={this.state.dropdown_open}
              direction="left"
              toggle={() => this.toggleDropdown()}
              className="mx-2"
            >
              <DropdownToggle className="video-menu-toggle">
                <Media className="align-items-center menu-dots">
                  <img
                    alt="Undiscovered"
                    src={require("assets/img/icons/dots.svg")}
                  />
                </Media>
              </DropdownToggle>
              <DropdownMenu className="video-menu">
                <DropdownItem onClick={() => this.openModal()}>
                  Reportar
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </>
        );
      }
    } else if (this.props.showMenu) {
      actionButton = (
        <Dropdown
          isOpen={this.state.dropdown_open}
          direction="left"
          toggle={() => this.toggleDropdown()}
        >
          <DropdownToggle className="video-menu-toggle">
            <Media className="align-items-center menu-dots">
              <img
                alt="Undiscovered"
                src={require("assets/img/icons/dots.svg")}
              />
            </Media>
          </DropdownToggle>
          <DropdownMenu className="video-menu">
            <DropdownItem
              onClick={() => {
                this.props.history.push("/agregar/?post=" + this.props.post.id);
              }}
            >
              Editar
            </DropdownItem>
            <DropdownItem divider />
            <DropdownItem
              onClick={() => {
                this.setState({ confirm_delete: true });
              }}
            >
              Eliminar
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
      );
    } else {
      actionButton = null;
    }

    let skills;

    if (this.props.post.video_type === "skill") {
      skills = (
        <div className="skill-cloud p-2">
          {this.props.post.physical_skills &&
            Object.values(this.props.post.physical_skills).map((skill, i) => {
              if (skill.active) {
                return (
                  <Button
                    className="button small mb-2"
                    color="white"
                    size="sm"
                    key={i}
                    name={skill.value}
                  >
                    {skill.value}
                  </Button>
                );
              } else {
                return null;
              }
            })}
          {this.props.post.technical_skills &&
            Object.values(this.props.post.technical_skills).map((skill, i) => {
              if (skill.active) {
                return (
                  <Button
                    className="button small mb-2"
                    color="white"
                    size="sm"
                    key={i}
                    name={skill.value}
                  >
                    {skill.value}
                  </Button>
                );
              } else {
                return null;
              }
            })}
          {this.props.post.type_skills &&
            Object.values(this.props.post.type_skills).map((skill, i) => {
              if (skill.active) {
                return (
                  <Button
                    className="button small mb-2"
                    color="white"
                    size="sm"
                    key={i}
                    name={skill.value}
                  >
                    {skill.value}
                  </Button>
                );
              } else {
                return null;
              }
            })}
        </div>
      );
    }

    let user_type;

    if (this.props.user.user_type === "agent") {
      user_type = "AGENTE";
    } else if (this.props.user.user_type === "fan") {
      user_type = "FAN";
    } else if (this.props.user.user_type === "player") {
      user_type = "JUGADOR";
    }

    return (
      <div className="post-wrapper" ref={(r) => (this.wrapper = r)}>
        <Waypoint
          onLeave={this.handleExitViewport}
          scrollableAncestor="window"
        ></Waypoint>
        {confirm_delete}
        {report_modal}
        <Row>
          <Col className="justify-content-between pl-1 pr-2 pl-lg-2 pr-lg-3">
            <div className="d-flex justify-content-between pb-2 vertical-center">
              <div className="d-flex justify-content-around vertical-center">
                <div className="card-profile-image">
                  <Button
                    className="no-styles"
                    onClick={() =>
                      this.props.history.push(
                        "/perfil/" + this.props.user.username
                      )
                    }
                  >
                    <img
                      alt="Undiscovered"
                      className="rounded-circle small"
                      src={
                        this.props.user.profile_picture_url !== undefined
                          ? this.props.user.profile_picture_url
                          : require("assets/img/brand/ProfilePlaceholder.png")
                      }
                      // src={require("assets/img/brand/ProfilePlaceholder.png")}
                    />
                  </Button>
                </div>
                <div
                  className="pl-2"
                  onClick={() =>
                    this.props.history.push(
                      "/perfil/" + this.props.user.username
                    )
                  }
                >
                  <div className="h4 user-type-label mb-1">
                    {this.props.user.username}
                  </div>
                  {this.props.user.username !== "undiscovered" &&
                  this.props.user.city ? (
                    <div className="h5 font-weight-300 mb-0 pb-1">
                      {this.props.user.city}, {this.props.user.country}
                    </div>
                  ) : (
                    <div className="h5 font-weight-300 mb-0 pb-1 color-white">
                      .
                    </div>
                  )}
                  {this.props.user.username !== "undiscovered" ? (
                    <div className="h4 mb-0 color-primary user-type-label">
                      {user_type}
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="">{actionButton}</div>
            </div>
            <div></div>
          </Col>
        </Row>
        <div
          className="youtube-video-wrapper"
          style={
            this.state.is_fullscreen && this.isIOS ? fullscreenIOSStyle : {}
          }
        >
          <div
            className={
              "player-wrapper" +
              (this.state.is_fullscreen ? " youtube-video-fs" : "")
            }
            ref={this.ref}
          >
            <div
              style={{
                width: "100%",
                height: "100%",
                backgroundColor: "black",
                textAlign: "center",
              }}
              onClick={this.handleClickPlay}
            >
              <ReactPlayer
                ref={(ref) => (this.player = ref)}
                url={this.props.post.link}
                className="youtube-video"
                loop={true}
                controls={false}
                volume={this.state.volume}
                light={false}
                played={this.state.played}
                playsinline
                onContextMenu={(e) => e.preventDefault()}
                muted={this.state.muted}
                onPlay={() => this.setState({ playing: true })}
                onPause={() => this.setState({ playing: false })}
                onBuffer={() => this.setState({ loading: true })}
                onBufferEnd={() => this.setState({ loading: false })}
                playing={this.state.playing}
                onProgress={this.handleProgress}
                onDuration={this.handleDuration}
                onReady={this.onReady}
                onError={(e)=>console.log(e)}
                config={{
                  youtube: {
                    playerVars: {
                      showinfo: 1,
                      modestbranding: 1,
                      disablePictureInPicture: true,
                    },
                  },
                  file: {
                    attributes: {
                      disablePictureInPicture: true,
                      controlsList: "nodownload noremoteplayback ",
                    },
                  },
                }}
              />
            </div>
            <PlayerControls
              ios={this.isIOS}
              duration={this.state.duration}
              handleClickFullscreen={this.handleClickFullscreen}
              handleClickFullscreenOut={this.handleClickFullscreenOut}
              handleSeekMouseDown={this.handleSeekMouseDown}
              handleSeekMouseUp={this.handleSeekMouseUp}
              handleSeekChange={this.handleSeekChange}
              played={this.state.played}
              handleToggleMuted={this.handleToggleMuted}
              muted={this.state.muted}
              playing={this.state.playing}
              handleClickPlay={this.handleClickPlay}
              handleVolumeChange={this.handleVolumeChange}
              volume={this.state.volume}
              is_fullscreen={this.state.is_fullscreen}
            />
          </div>
          {!this.state.playing && !this.state.loading && (
            <img
              alt="Undiscovered"
              src={require("assets/img/brand/PlayButton.png")}
              className="play-button icon"
              onClick={this.handleClickPlay}
            />
          )}
          {this.state.loading && (
            <embed
              alt="Undiscovered"
              className="preloader-icon play-button"
              src={require("assets/img/brand/preloader.svg")}
            />
          )}
        </div>
        <div className="d-flex justify-content-between pt-1 mt-2">
          <div className="h4 mb-0 pt-1 pl-2 color-secondary ml-1">
            <p className="h6 font-weight-300 info-text-sm muted num-of-ratings">
              Calificaciones: {this.state.num_of_ratings}
            </p>
          </div>
          <div className="d-flex rating-wrapper justify-content-end">
            <div className="d-flex">
              <Rating
                onChange={(value) => this.handleRatingChange(value)}
                name="rating"
                initialRating={this.state.rating}
                className="rating"
                emptySymbol={
                  <img
                    alt="Undiscovered"
                    src={require("assets/img/icons/empty-ball.svg")}
                    className="icon"
                  />
                }
                fullSymbol={
                  <img
                    alt="Undiscovered"
                    src={require("assets/img/icons/filled-ball.svg")}
                    className="icon"
                  />
                }
              />

              <div className="rating-avg">
                <h4
                  className={
                    "h4 color-secondary mb-0 " +
                    (this.state.avg_rating === "N/A" ? "smaller" : "")
                  }
                >
                  {this.state.avg_rating}
                </h4>
              </div>
            </div>
          </div>
        </div>
        <div className="h4 mb-1 pt-0 mt--1 pl-2 color-secondary ml-1">
          <span className="txt-black">
            {this.props.post.video_type === "skill"
              ? "HABILIDAD: "
              : "PARTIDO: "}
          </span>
          {this.props.post.title}
        </div>
        <p className="h6 font-weight-300 info-text-sm muted num-of-ratings p-2 ml-1 capitalize">
          {this.changeDateFormat()}
        </p>
        <p className="profile-description pl-2 pr-2 ml-1">
          {this.props.post.description}
        </p>
        {skills}
        <hr className="my-2 mb-4" />
      </div>
    );
  }

  _onReady(event) {
    //event.target.pauseVideo();
  }

  _onEnd(event) {
    event.target.seekTo(0);
    event.target.playVideo();
  }
}

export default Video;
