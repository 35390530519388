import React from "react";
import { Link } from "react-router-dom";
import firebase from "./../../config/firebase.js";
import {
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Navbar,
  Nav,
  Container,
  Media
} from "reactstrap";

/**
 * AdminNavbar Class.
 * Main Navigation
 * @constructor
 */
class AdminNavbar extends React.Component { 
  constructor(props) {
    super(props);
    this.logout = this.logout.bind(this);
  }

    /**
   * Logout function.
   * Removes id from local storage and signs out of firebase.
   * Redirects to login.
   * @method
   */
  async logout() { 
    await firebase
      .auth()
      .signOut()
      .then(()=> {
        localStorage.removeItem("userUndiscovered");
        this.props.history.push("/auth/login");
      })
      .catch(function(error) {
      });
  }

  /**
   * Renders the AdminNavBar component.
   * @see AdminNavBar Component
   */
  render() {
    return (
      <>
        <Navbar className={"navbar-top navbar-dark " + (this.props.loggedIn ? null : "no-topbar-margin")} expand="md" id="navbar-main" style={{zIndex:2}}>
          <Container fluid>
            <Link className="h4 mb-0 d-lg-inline-block main-logo-wrapper" to={this.props.loggedIn ? "/inicio" : "/auth/login"}>
              <img
                alt="..."
                className="main-logo"
                src={require("assets/img/brand/Undiscovered-logo.png")}
              />
            </Link>
            <Nav className="align-items-centerd-md-flex top-bar" navbar>
              <UncontrolledDropdown nav>
                <DropdownToggle className="pr-0" nav>
                  <div className="align-items-center menu-hamburger">
                      <img
                        alt="..."
                        src={require("assets/img/icons/menu.svg")}
                      />
                  </div>
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-arrow" right>
                  <DropdownItem to="/info/terminos-condiciones" className="menu-tab-info" tag={Link}>
                    <span>Términos y Condiciones</span>
                  </DropdownItem>
                  <DropdownItem to="/info/politica-privacidad" className="menu-tab-info" tag={Link}>
                    <span>Política de Privacidad</span>
                  </DropdownItem>
                  <DropdownItem to="/info/politica-cookies" className="menu-tab-info" tag={Link}>
                    <span>Política de Cookies</span>
                  </DropdownItem>
                  <DropdownItem to="/info/nosotros" className="menu-tab-info" tag={Link}>
                    <span>Acerca de Undiscovered</span>
                  </DropdownItem>
                  { 
                  this.props.loggedIn ? 
                  <>
                  <DropdownItem divider />
                  <DropdownItem to="/info/eliminar-cuenta" className="menu-tab-info" tag={Link}>
                    <span>Eliminar Cuenta</span>
                  </DropdownItem> </>: null
                  }
                  { 
                  this.props.loggedIn ? 
                  <>
                  <DropdownItem className="menu-tab-info" onClick={()=>{this.logout()}}>
                    <span>Cerrar Sesión</span>
                  </DropdownItem> </>: null
                  }
                  
                </DropdownMenu>
              </UncontrolledDropdown>
            </Nav>
          </Container>
        </Navbar>
      </>
    );
  }
}

export default AdminNavbar;
