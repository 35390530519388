import React from "react";
import {
  Button,
  ButtonGroup,
  FormGroup,
  Form,
  Input,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Row,
  Alert,
  Col,
  Container,
  InputGroup,
} from "reactstrap";

import DatePicker from "react-date-picker";
import ReactHintFactory from "react-hint";
import EditProfilePicture from "./EditProfilePicture";

import utilitiesHelpers from "./../../helpers/utilities";
import userHelpers from "./../../helpers/user";
import validationHelpers from "./../../helpers/validation";

import countries from "./data/countries.json";
import teams from "./data/teams.json";

const ReactHint = ReactHintFactory(React);

/**
 * EditProfile Class.
 * Main Edit Profile for posts component view.
 * @constructor
 */
class EditProfile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dropdown_open: [false, false, false, false, false, false, false],
      form_part: 1,
      countries: {},
      cities: [],
      positions: [],
      teams: [],
      progress: 0,
      isUploading: false,
      error_message: "",
      error_array: [],
      username: "",
      name: "",
      phone: "",
      country: "",
      countryFilter: "",
      birthday: null,
      city: "",
      cityFilter: "",
      current_club: "",
      position: "",
      alt_position: "",
      dominant_side: "",
      fav_team: "",
      fav_team_filter: "",
      instagram: "",
      gender: "Masculino",
      height: "",
      weight: "",
      time30minutes: "",
      time30seconds: "",
      time1000minutes: "",
      time1000seconds: "",
      agent: false,
      contract: false,
      profile_picture: "",
      description: "",
      profile_picture_url: "",
      minor: false,
      tutor_name: "",
      tutor_id: "",
      tutor_relationship: "",
      loading: true,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleChangeCountry = this.handleChangeCountry.bind(this);
    this.handleChangeBirthday = this.handleChangeBirthday.bind(this);
    this.handleChangeCheckbox = this.handleChangeCheckbox.bind(this);
    this.handleChangeTime = this.handleChangeTime.bind(this);
    this.toggleDropdown = this.toggleDropdown.bind(this);
    this.getUtilities = this.getUtilities.bind(this);
    this.updateProfile = this.updateProfile.bind(this);
  }

  /**
   * Upon mounting, get user data depending on user type
   * @method
   */
  async componentDidMount() {
    var uid = localStorage.getItem("userUndiscovered");
    var user_type = await userHelpers.getUserType();
    this.setState({
      user_type: user_type,
      user_toggle: this.props.user_toggle,
      uid: uid,
    });
    await this.getUtilities();

    var parts = this.props.user.birthday.split("/");
    var birthday = new Date(parts[2], parts[1] - 1, parts[0]);
    this.setState({
      username: this.props.user.username,
      name: this.props.user.name,
      phone: this.props.user.phone !== undefined ? this.props.user.phone : "",
      country:
        this.props.user.country !== undefined ? this.props.user.country : "",
      birthday: birthday,
      city: this.props.user.city !== undefined ? this.props.user.city : "",
      position:
        this.props.user.position !== undefined ? this.props.user.position : "",
      alt_position:
        this.props.user.alt_position !== undefined
          ? this.props.user.alt_position
          : "",
      dominant_side:
        this.props.user.dominant_side !== undefined
          ? this.props.user.dominant_side
          : "",
      fav_team:
        this.props.user.fav_team !== undefined ? this.props.user.fav_team : "",
      instagram:
        this.props.user.instagram !== undefined
          ? this.props.user.instagram
          : "",
      current_club:
        this.props.user.current_club !== undefined
          ? this.props.user.current_club
          : "",
      gender:
        this.props.user.gender !== undefined ? this.props.user.gender : "",
      height:
        this.props.user.height !== undefined ? this.props.user.height : "",
      weight:
        this.props.user.weight !== undefined ? this.props.user.weight : "",
      time30minutes:
        this.props.user.time30 !== undefined
          ? this.props.user.time30.split(":")[0] === "00"
            ? ""
            : this.props.user.time30.split(":")[0]
          : "",
      time30seconds:
        this.props.user.time30 !== undefined
          ? this.props.user.time30.split(":")[1] === "00"
            ? ""
            : this.props.user.time30.split(":")[1]
          : "",
      time1000minutes:
        this.props.user.time1000 !== undefined
          ? this.props.user.time1000.split(":")[0] === "00"
            ? ""
            : this.props.user.time1000.split(":")[0]
          : "",
      time1000seconds:
        this.props.user.time1000 !== undefined
          ? this.props.user.time1000.split(":")[1] === "00"
            ? ""
            : this.props.user.time1000.split(":")[1]
          : "",
      agent:
        this.props.user.agent !== undefined ? this.props.user.agent : false,
      contract:
        this.props.user.contract !== undefined
          ? this.props.user.contract
          : false,
      profile_picture: this.props.user.profile_picture,
      profile_picture_url: this.props.user.profile_picture_url,
      minor: utilitiesHelpers.calcAgeFromPicker(birthday) > 14 ? false : true,
      tutor_name:
        this.props.user.tutor_name !== undefined
          ? this.props.user.tutor_name
          : "",
      tutor_id:
        this.props.user.tutor_id !== undefined ? this.props.user.tutor_id : "",
      tutor_relationship:
        this.props.user.tutor_relationship !== undefined
          ? this.props.user.tutor_relationship
          : "",
      description:
        this.props.user.description !== undefined
          ? this.props.user.description
          : "",
    });

    this.setState({
      loading: false,
    });
  }

  /**
   * Get utilities from database
   * @method
   */
  async getUtilities() {
    const positions = await utilitiesHelpers.getUtilitiesUser();
    this.setState({
      positions: positions,
    });
  }

  /**
   * Basic change handle
   * @method
   * @param e Element that fires function
   */
  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  /**
   * Basic country change handle
   * @method
   * @param e Element that fires function
   */
  handleChangeCountry(e) {
    this.setState({
      [e.target.name]: e.target.value,
      cities: countries[e.target.value],
      city: "",
    });
  }

  /**
   * Basic checkbox change handle
   * @method
   * @param e Element that fires function
   */
  handleChangeCheckbox(e) {
    this.setState({
      [e.target.name]: !this.state[e.target.name],
    });
  }

  /**
   * Basic birthday change handle
   * @method
   * @param e Element that fires function
   */
  handleChangeBirthday(birthday) {
    if (birthday !== null && birthday[0]) {
      this.setState({ birthday: birthday[0] });
      var age = utilitiesHelpers.calcAgeFromPicker(birthday[0]);
      if (age < 14) {
        this.setState({ minor: true });
      } else {
        this.setState({ minor: false });
      }
    } else {
      this.setState({ birthday: "" });
    }
  }

  /**
   * Basic time change handle
   * @method
   * @param e Element that fires function
   */
  handleChangeTime(e) {
    if (e.target.value.length <= 2 && e.target.value < 60) {
      this.setState({
        [e.target.name]: e.target.value,
      });
    }
  }

  /**
   * Updates the info shown on profile if all validations pass
   * @method
   */
  async updateProfile() {
    if (this.state.user_type === "player") {
      var time30 = validationHelpers.formatTime(
        this.state.time30minutes,
        this.state.time30seconds
      );
      var time1000 = validationHelpers.formatTime(
        this.state.time1000minutes,
        this.state.time1000seconds
      );
    }
    const [res, errors] = await validationHelpers.updateProfile(
      this.state.username,
      this.state.name,
      this.state.country,
      this.state.city,
      this.state.birthday,
      this.state.tutor_name,
      this.state.tutor_relationship,
      this.state.tutor_id,
      this.state.phone,
      this.state.fav_team,
      this.state.instagram.replace("@", ""),
      this.state.description,
      this.state.gender,
      this.state.user_type,
      this.state.position,
      this.state.alt_position,
      this.state.dominant_side,
      this.state.height,
      this.state.weight,
      this.state.current_club,
      time30,
      time1000,
      this.state.agent,
      this.state.contract
    );

    if (res === "success") {
      window.location.href = window.location.pathname.replace(
        this.props.match.params.username,
        this.state.username
      );
    } else {
      this.setState({
        error_array: errors,
      });
    }
  }

  /**
   * Basic toggle for dropdowns
   * @method
   * @param num index of dropdown to be toggled
   */
  toggleDropdown(num) {
    var tempArray = [...this.state.dropdown_open];
    tempArray[num] = !tempArray[num];
    this.setState({ dropdown_open: tempArray });
  }

  /**
   * Renders the EditProfile component.
   * @see EditProfile Component
   */
  render() {
    let error_message;

    if (Object.keys(this.state.error_array).length > 0) {
      error_message = Object.values(this.state.error_array).map((error, i) => {
        if (this.state.user_type !== "player") {
          if (
            error === "Posición Inválida" ||
            error === "Posición Alternativa Inválida" ||
            error === "Lado Dominante Inválido" ||
            error === "Altura Inválida" ||
            error === "Peso Inválido"
          ) {
            return null;
          } else {
            return (
              <p className="fs-12 mb-0" key={i}>
                {error}
              </p>
            );
          }
        } else {
          return (
            <p className="fs-12 mb-0" key={i}>
              {error}
            </p>
          );
        }
      });
    }

    if (this.state.loading) {
      return null;
    } else {
      let minor_fields;
      if (this.state.minor) {
        minor_fields = (
          <div className="bg-primary px-2 pb-1 mt-2 border-radius-10">
            <Row className="d-flex justify-content-between pt-2 my-2 pb-0 mb-0 px-3 filter-dropdown-row">
              <h4 className="text-center full-width mb-0">
                La edad mínima para el el uso de Undiscovered es de 14 años
              </h4>
              <p className="text-center full-width mb-1">
                Para continuar llene los siguientes campos responsablemente
              </p>
              <FormGroup className="full-width pb-0 mb-0">
                <Input
                  className={
                    "form-control-alternative" +
                    (this.state.error_array.tutor_name ? " error-input" : "")
                  }
                  type="text"
                  name="tutor_name"
                  value={this.state.tutor_name}
                  placeholder="Nombre de Tutor"
                  onChange={this.handleChange}
                />
              </FormGroup>
            </Row>
            <Row className="d-flex pt-0 mt-2 pb-0 mb-0">
              <Dropdown
                isOpen={this.state.dropdown_open[6]}
                toggle={() => this.toggleDropdown(6)}
                className="full-width hz-padded mt-0"
              >
                <DropdownToggle
                  caret
                  className={
                    "full-width btn-white" +
                    (this.state.error_array.tutor_relationship
                      ? " error-input"
                      : "")
                  }
                >
                  {this.state.tutor_relationship !== ""
                    ? this.state.tutor_relationship
                    : "Parentesco "}
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem
                    name="tutor_relationship"
                    value="Padre"
                    onClick={this.handleChange}
                  >
                    Padre
                  </DropdownItem>
                  <DropdownItem
                    name="tutor_relationship"
                    value="Madre"
                    onClick={this.handleChange}
                  >
                    Madre
                  </DropdownItem>
                  <DropdownItem
                    name="tutor_relationship"
                    value="Tutor Legal"
                    onClick={this.handleChange}
                  >
                    Tutor Legal
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </Row>
            <Row className="d-flex justify-content-between pt-0 mt-2 pb-0 mb-0 px-3 filter-dropdown-row">
              <FormGroup className="full-width mb-0">
                <Input
                  className={
                    "form-control-alternative mb-2" +
                    (this.state.error_array.tutor_id ? " error-input" : "")
                  }
                  type="text"
                  name="tutor_id"
                  value={this.state.tutor_id.toUpperCase()}
                  placeholder="No. Identificación de Tutor"
                  onChange={this.handleChange}
                />
              </FormGroup>
            </Row>
          </div>
        );
      }

      let age_verification = (
        <>
          <Row className="d-flex justify-content-between pt-2 pb-0 mb-0 px-3 filter-dropdown-row">
            <FormGroup className="full-width mb-0">
              <p className="full-width text-center btn-label mb-0">
                Fecha de Nacimiento
              </p>
              <InputGroup
                className={
                  "input-group-alternative" +
                  (this.state.error_array.age ? " error-input" : "")
                }
              >
                <DatePicker
                  calendarClassName="date-input-calendar"
                  className="date-input-picker"
                  onChange={(e) => this.handleChangeBirthday(e)}
                  value={this.state.birthday}
                  calendarIcon={null}
                  dayPlaceholder="dd"
                  monthPlaceholder="mm"
                  minDetail="decade"
                  maxDetail="month"
                  yearPlaceholder="aaaa"
                  id="birthday"
                  name="birthday"
                  format="dd-MM-y"
                  onCalendarOpen={null}
                  onCalendarClose={null}
                  nativeInputAriaLabel="Date"
                  returnValue="range"
                 />
              </InputGroup>
            </FormGroup>
          </Row>
          {minor_fields}
        </>
      );

      let city_dropdown;
      if (this.state.cities[0]) {
        city_dropdown = this.state.cities.map((city, i) => {
          var string_norm = this.state.cityFilter
            .toLowerCase()
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "");
          var city_norm = city
            .toLowerCase()
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "");
          if (city_norm.toLowerCase().includes(string_norm)) {
            return (
              <DropdownItem
                key={i}
                name="city"
                value={city}
                onClick={this.handleChange}
              >
                {city}
              </DropdownItem>
            );
          } else {
            return null;
          }
        });
      } else {
        city_dropdown = <DropdownItem disabled>Seleccionar País</DropdownItem>;
      }

      const items = [];
      var string_norm = this.state.fav_team_filter
        .toLowerCase()
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .split(" ")
        .join("");

      Object.entries(teams).map((country, i) => {
        var cn, cnlength;
        for (const [index, value] of Object.entries(country[1])) {
          var team_norm = value
            .toLowerCase()
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
            .split(" ")
            .join("");
          if (team_norm.includes(string_norm)) {
            cn = country[0];
            cnlength = country[1].length;
          }
        }
        items.push(
          <DropdownItem key={country[0]} className="team-country" header>
            {/* {country[0]} <span>({country[1].length})</span> */}
            {cn}
          </DropdownItem>
        );

        for (const [index, value] of Object.entries(country[1])) {
          var team_norm = value
            .toLowerCase()
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
            .split(" ")
            .join("");

          if (team_norm.toLowerCase().includes(string_norm)) {
            items.push(
              <DropdownItem
                key={country[0] + "-" + index}
                name="fav_team"
                className="fav_team-dropdown-item"
                value={value}
                onClick={this.handleChange}
              >
                {value}
              </DropdownItem>
            );
          }
        }
      });
      items.push(
        <DropdownItem
          key={"otro"}
          name="fav_team"
          value={"Otro"}
          onClick={this.handleChange}
        >
          Otro
        </DropdownItem>
      );
      let team_list = items;

      return (
        <>
          <Container>
            <Row>
              <Col md="12">
                <>
                  <EditProfilePicture uid={this.state.uid} />
                  <Form role="form">
                    <FormGroup className="mb-2">
                      <Input
                        className={
                          "form-control-alternative" +
                          (this.state.error_array.username
                            ? " error-input"
                            : "")
                        }
                        type="text"
                        name="username"
                        returnkeytype="done"
                        value={this.state.username
                          .toLowerCase()
                          .replace(" ", "")}
                        placeholder="Nombre de Usuario"
                        onChange={this.handleChange}
                      />
                    </FormGroup>
                    <FormGroup className="mb-2">
                      <Input
                        className={
                          "form-control-alternative" +
                          (this.state.error_array.name ? " error-input" : "")
                        }
                        type="text"
                        name="name"
                        value={this.state.name}
                        placeholder="Nombre Completo"
                        onChange={this.handleChange}
                      />
                    </FormGroup>
                    {age_verification}
                    <FormGroup className="my-2">
                      <Input
                        className="form-control-alternative"
                        type="tel"
                        name="phone"
                        value={this.state.phone}
                        placeholder="Teléfono"
                        onChange={this.handleChange}
                      />
                    </FormGroup>
                    <Row className="d-flex justify-content-between pt-0 mt-0 pb-0 mb-2 filter-dropdown-row">
                      <Dropdown
                        isOpen={this.state.dropdown_open[0]}
                        toggle={() => this.toggleDropdown(0)}
                        className="full-width hz-padded pr-1"
                      >
                        <DropdownToggle
                          caret
                          className={
                            "full-width btn-white" +
                            (this.state.error_array.country
                              ? " error-input"
                              : "")
                          }
                        >
                          {this.state.country !== ""
                            ? this.state.country
                            : "País"}
                        </DropdownToggle>
                        <DropdownMenu>
                          <Input
                            className="form-control-alternative dropdown-search"
                            type="text"
                            name="countryFilter"
                            autoComplete="new-password"
                            value={this.state.countryFilter}
                            placeholder="Buscar..."
                            onChange={this.handleChange}
                          />
                          {Object.keys(countries).map((country, i) => {
                            var string_norm = this.state.countryFilter
                              .toLowerCase()
                              .normalize("NFD")
                              .replace(/[\u0300-\u036f]/g, "");
                            var country_norm = country
                              .toLowerCase()
                              .normalize("NFD")
                              .replace(/[\u0300-\u036f]/g, "");
                            if (
                              country_norm.toLowerCase().includes(string_norm)
                            ) {
                              return (
                                <DropdownItem
                                  key={i}
                                  name="country"
                                  value={country}
                                  onClick={this.handleChangeCountry}
                                >
                                  {country}
                                </DropdownItem>
                              );
                            } else {
                              return null;
                            }
                          })}
                        </DropdownMenu>
                      </Dropdown>
                      <Dropdown
                        isOpen={this.state.dropdown_open[1]}
                        toggle={() => this.toggleDropdown(1)}
                        className="full-width hz-padded pl-1"
                      >
                        <DropdownToggle
                          caret
                          className={
                            "full-width btn-white" +
                            (this.state.error_array.city ? " error-input" : "")
                          }
                        >
                          {this.state.city !== "" ? this.state.city : "Ciudad"}
                        </DropdownToggle>
                        <DropdownMenu>
                          <Input
                            className="form-control-alternative dropdown-search"
                            type="text"
                            name="cityFilter"
                            autoComplete="new-password"
                            value={this.state.cityFilter}
                            placeholder="Buscar..."
                            onChange={this.handleChange}
                          />
                          {city_dropdown}
                        </DropdownMenu>
                      </Dropdown>
                    </Row>
                    <Row>
                      <Dropdown
                        isOpen={this.state.dropdown_open[2]}
                        toggle={() => this.toggleDropdown(2)}
                        className="full-width hz-padded mb-2"
                      >
                        <DropdownToggle caret className="full-width btn-white">
                          {this.state.fav_team !== ""
                            ? this.state.fav_team
                            : "Equipo Favorito"}
                        </DropdownToggle>
                        <DropdownMenu>
                          <Input
                            className="form-control-alternative dropdown-search"
                            type="text"
                            name="fav_team_filter"
                            value={this.state.fav_team_filter}
                            placeholder="Buscar..."
                            onChange={this.handleChange}
                          />
                          {team_list}
                        </DropdownMenu>
                      </Dropdown>
                    </Row>
                    <FormGroup className="mb-2">
                      <Input
                        className="form-control-alternative"
                        type="text"
                        name="instagram"
                        value={this.state.instagram}
                        placeholder="Usuario Instagram"
                        onChange={this.handleChange}
                      />
                    </FormGroup>
                    {/* <Row className="d-flex justify-content-between pt-2 pb-0 mb-0"> */}
                    <ButtonGroup
                      className={
                        "full-width norm-br-radius" +
                        (this.state.error_array.gender ? " error-input" : "")
                      }
                    >
                      <Button
                        color="white"
                        active={this.state.gender === "Masculino"}
                        onClick={() => {
                          this.setState({ gender: "Masculino" });
                        }}
                      >
                        Masculino
                      </Button>
                      <Button
                        color="white"
                        active={this.state.gender === "Femenino"}
                        onClick={() => {
                          this.setState({ gender: "Femenino" });
                        }}
                      >
                        Femenino
                      </Button>
                      <Button
                        color="white"
                        active={this.state.gender === "Otro"}
                        onClick={() => {
                          this.setState({ gender: "Otro" });
                        }}
                      >
                        Otro
                      </Button>
                    </ButtonGroup>
                    {/* </Row> */}
                  </Form>
                </>
                <Input
                  className="form-control-alternative textarea my-2"
                  name="description"
                  value={this.state.description}
                  placeholder={
                    this.state.user_type === "agent"
                      ? "Datos relevantes y experiencia, títulos (entre otros)"
                      : "Datos relevantes y experiencia profesional (academias y equipos en los que has jugado, logros, entre otros)."
                  }
                  rows="3"
                  type="textarea"
                  onChange={this.handleChange}
                />
                {this.state.user_type === "agent" ? null : (
                  <ButtonGroup className="full-width mb--3">
                    <Button
                      color="white"
                      className="half-width"
                      active={this.state.user_type === "player"}
                      onClick={() => {
                        this.setState({ user_type: "player" });
                      }}
                    >
                      Soy Jugador
                    </Button>
                    <Button
                      color="white"
                      className="half-width"
                      active={this.state.user_type === "fan"}
                      onClick={() => {
                        this.setState({ user_type: "fan" });
                      }}
                    >
                      Soy Fan
                    </Button>
                  </ButtonGroup>
                )}

                {this.state.user_type === "player" ? (
                  <>
                    <FormGroup className="pt-4 mb-2">
                      <Input
                        className="form-control-alternative"
                        type="text"
                        name="current_club"
                        value={this.state.current_club}
                        placeholder="Equipo/Club/Academia Actual"
                        onChange={this.handleChange}
                      />
                    </FormGroup>
                    <Row className="d-flex justify-content-between mt-0 pb-0 mb-2 filter-dropdown-row">
                      <Dropdown
                        isOpen={this.state.dropdown_open[3]}
                        toggle={() => this.toggleDropdown(3)}
                        className="full-width hz-padded pr-1"
                      >
                        <DropdownToggle
                          caret
                          className={
                            "full-width btn-white" +
                            (this.state.error_array.position
                              ? " error-input"
                              : "")
                          }
                        >
                          {this.state.position !== ""
                            ? this.state.position
                            : "Posición"}
                        </DropdownToggle>

                        <DropdownMenu>
                          {Object.keys(this.state.positions).map(
                            (position, i) => {
                              if (position !== this.state.alt_position) {
                                return (
                                  <DropdownItem
                                    key={i}
                                    name="position"
                                    value={position}
                                    onClick={this.handleChange}
                                  >
                                    {position}
                                  </DropdownItem>
                                );
                              } else {
                                return (
                                  <DropdownItem
                                    disabled
                                    key={i}
                                    name="position"
                                    value={position}
                                  >
                                    {position}
                                  </DropdownItem>
                                );
                              }
                            }
                          )}
                        </DropdownMenu>
                      </Dropdown>
                      <Dropdown
                        isOpen={this.state.dropdown_open[4]}
                        toggle={() => this.toggleDropdown(4)}
                        className="full-width hz-padded pl-1"
                      >
                        <DropdownToggle
                          caret
                          className={
                            "full-width btn-white" +
                            (this.state.error_array.alt_position
                              ? " error-input"
                              : "")
                          }
                        >
                          {this.state.alt_position !== ""
                            ? this.state.alt_position
                            : "Posición Alternativa"}
                        </DropdownToggle>
                        <DropdownMenu>
                          {Object.keys(this.state.positions).map(
                            (alt_position, i) => {
                              if (alt_position !== this.state.position) {
                                return (
                                  <DropdownItem
                                    key={i}
                                    name="alt_position"
                                    value={alt_position}
                                    onClick={this.handleChange}
                                  >
                                    {alt_position}
                                  </DropdownItem>
                                );
                              } else {
                                return (
                                  <DropdownItem
                                    disabled
                                    key={i}
                                    name="alt_position"
                                    value={alt_position}
                                  >
                                    {alt_position}
                                  </DropdownItem>
                                );
                              }
                            }
                          )}
                        </DropdownMenu>
                      </Dropdown>
                    </Row>
                    <Row className="d-flex justify-content-between pt-0 mt-0 pb-0 mb-2">
                      <Dropdown
                        isOpen={this.state.dropdown_open[5]}
                        toggle={() => this.toggleDropdown(5)}
                        className="full-width hz-padded"
                      >
                        <DropdownToggle
                          caret
                          className={
                            "full-width btn-white" +
                            (this.state.error_array.dominant_side
                              ? " error-input"
                              : "")
                          }
                        >
                          {this.state.dominant_side !== ""
                            ? this.state.dominant_side
                            : "Lado Dominante"}
                        </DropdownToggle>
                        <DropdownMenu>
                          <DropdownItem
                            name="dominant_side"
                            value="Derecho"
                            onClick={this.handleChange}
                          >
                            Derecho
                          </DropdownItem>
                          <DropdownItem
                            name="dominant_side"
                            value="Izquierdo"
                            onClick={this.handleChange}
                          >
                            Izquierdo
                          </DropdownItem>
                          <DropdownItem
                            name="dominant_side"
                            value="Ambos"
                            onClick={this.handleChange}
                          >
                            Ambos
                          </DropdownItem>
                        </DropdownMenu>
                      </Dropdown>
                    </Row>
                    <Row className="d-flex justify-content-between pt-0 mt-0 pb-0 mb-2 filter-dropdown-row">
                      <FormGroup className="mb-0 half-width hz-padded pr-1">
                        <Input
                          className={
                            "form-control-alternative" +
                            (this.state.error_array.height
                              ? " error-input"
                              : "")
                          }
                          type="number"
                          name="height"
                          value={this.state.height}
                          placeholder="Altura (cm)"
                          onChange={this.handleChange}
                        />
                      </FormGroup>
                      <FormGroup className="mb-0 half-width hz-padded pl-1">
                        <Input
                          className={
                            "form-control-alternative" +
                            (this.state.error_array.weight
                              ? " error-input"
                              : "")
                          }
                          type="number"
                          name="weight"
                          value={this.state.weight}
                          placeholder="Peso (kg)"
                          onChange={this.handleChange}
                        />
                      </FormGroup>
                    </Row>
                    <Row className="d-flex justify-content-between pt-0 mt-0 pb-0 mb-2 filter-dropdown-row">
                      <FormGroup className="mb-0  half-width hz-padded pr-1">
                        <label className="fs-12 mb-0">Tiempo 30m</label>
                        <div className="d-flex justify-content-center">
                          <Input
                            className="form-control-alternative min-sec mr-1"
                            type="number"
                            name="time30minutes"
                            value={this.state.time30minutes}
                            placeholder="mm"
                            max="60"
                            onChange={this.handleChangeTime}
                          />
                          <Input
                            className="form-control-alternative min-sec mx-1"
                            type="number"
                            name="time30seconds"
                            value={this.state.time30seconds}
                            placeholder="ss"
                            max="60"
                            onChange={this.handleChangeTime}
                          />
                          <div className="ml-1 hint-wrapper mr-2">
                            <ReactHint className="hint" autoPosition events />
                            <button
                              className="hint-toggle"
                              data-rh="Tiempo en el que corres 30 metros"
                            >
                              i
                            </button>
                          </div>
                        </div>
                      </FormGroup>
                      <FormGroup className="mb-0  half-width hz-padded pl-1">
                        <label className="fs-12 mb-0">Tiempo 1000m</label>
                        <div className="d-flex justify-content-center">
                          <Input
                            className="form-control-alternative min-sec mr-1"
                            type="number"
                            max="60"
                            name="time1000minutes"
                            value={this.state.time1000minutes}
                            placeholder="mm"
                            onChange={this.handleChangeTime}
                          />
                          <Input
                            className="form-control-alternative min-sec mx-1"
                            type="number"
                            max="60"
                            name="time1000seconds"
                            value={this.state.time1000seconds}
                            placeholder="ss"
                            onChange={this.handleChangeTime}
                          />
                          <div className="ml-1 hint-wrapper mr-2">
                            <ReactHint className="hint" autoPosition events />
                            <button
                              className="hint-toggle"
                              data-rh="Tiempo en el que corres 1000 metros (1 km)"
                            >
                              i
                            </button>
                          </div>
                        </div>
                      </FormGroup>
                    </Row>
                    <Row className="mt-3 mb-1">
                      <Col
                        xs="6"
                        className="custom-control custom-checkbox"
                        style={{ display: "flex" }}
                      >
                        <div>
                          <label className="fs-12 mb-0">Agente</label>
                          <span className="clearfix" />
                          <label className="custom-toggle">
                            <input
                              onChange={(e) => this.handleChangeCheckbox(e)}
                              checked={this.state.agent}
                              value={this.state.agent}
                              name="agent"
                              type="checkbox"
                            />
                            <span className="custom-toggle-slider rounded-circle" />
                          </label>
                        </div>
                        <div className="pt-10 ml-1 hint-wrapper mr-2">
                          <ReactHint className="hint" autoPosition events />
                          <button
                            className="hint-toggle"
                            data-rh="¿Cuentas con un representante que negocia en tu nombre contratos y actividades profesionales?"
                          >
                            i
                          </button>
                        </div>
                      </Col>
                      <Col
                        xs="6"
                        className="custom-control custom-checkbox"
                        style={{ display: "flex" }}
                      >
                        <div>
                          <label className="fs-12 mb-0">Contrato</label>
                          <span className="clearfix" />
                          <label className="custom-toggle">
                            <input
                              onChange={(e) => this.handleChangeCheckbox(e)}
                              checked={this.state.contract}
                              value={this.state.contract}
                              name="contract"
                              type="checkbox"
                            />
                            <span className="custom-toggle-slider rounded-circle" />
                          </label>
                        </div>
                        <div className="pt-10 ml-1 hint-wrapper mr-2">
                          <ReactHint className="hint" autoPosition events />
                          <button
                            className="hint-toggle"
                            data-rh="¿Cuentas con un contrato que regule tu relación laboral con clubes/agentes?"
                          >
                            i
                          </button>
                        </div>
                      </Col>
                    </Row>
                  </>
                ) : (
                  <div className="mt-4"></div>
                )}
              </Col>
            </Row>
          </Container>
          <div className="hz-padded">
            <Alert
              color="warning"
              hidden={
                Object.keys(this.state.error_array).length === 0 ? true : false
              }
            >
              {error_message}
            </Alert>
          </div>
          <div className="text-center pb-0 mt-3">
            <Button
              className="txt-black min-width-200 mx-1 mb-2"
              color="white"
              type="button"
              onClick={() => {
                this.props.toggleEditProfile();
              }}
            >
              CANCELAR
            </Button>
            <Button
              className="txt-black min-width-200 mx-1 mb-2"
              color="primary"
              type="button"
              onClick={() => {
                this.updateProfile();
              }}
            >
              GUARDAR
            </Button>
          </div>
        </>
      );
    }
  }
}

export default EditProfile;
