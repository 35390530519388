import React from "react";
import { Container } from "reactstrap";
import { Link } from "react-router-dom";

/**
 * TerminosYCondiciones Class.
 * Main Terms & Conditions component view.
 * @constructor
 */
class TerminosYCondiciones extends React.Component {
  /**
   * Renders the TerminosYCondiciones component.
   * @see TerminosYCondiciones Component
   */
  render() {
    return (
      <>
        {/* Page content */}
        <Container fluid>
          <h2 className="ls-2  text-center">Términos y Condiciones</h2>
          <p className=" text-justify">
            Fecha de entrada en vigor: 15 de junio de 2020
            <br />
            <br />
            Nuestro objetivo es conectar a jugadores de futbol, agentes,
            visores, entrenadores, fanáticos y cualquier persona que se pueda
            relacionar con el futbol de todo el mundo para dotar a los jugadores
            de una mayor visibilidad y a los entrenadores, visores y agentes de
            una red de futbolistas que quieran esperan a ser descubiertos,
            aumentando las oportunidades de jugar en el equipo, club o academia
            adecuada y alcanzar sus sueños profesionales. Nuestros servicios han
            sido diseñados para promover dotar de las mismas oportunidades a
            nuestros usuarios, permitiendo que cada usuario y millones de
            jugadores, entrenadores, visores, agentes, técnicos y/o cualquier
            persona relacionada con el futbol, pueda conocerse, contactarse,
            intercambiar ideas, aprender de los demás y así encontrar un
            desarrollo personal y profesional en una red ejemplar.
            <br />
            <br />
            <h3 className="color-secondary">Relación Jurídica</h3>
            Al hacer clic en "Iniciar sesión", "Crear cuenta", "Seguir" u otro
            enlace similar, al registrarte, acceder o utilizar nuestros
            Servicios (según se describen más adelante), aceptas suscribir un
            contrato jurídicamente vinculante con Undiscovered (incluso si estás
            utilizando nuestros Servicios en nombre de una entidad o persona
            moral). Si no estás conforme y de acuerdo con este contrato (el
            “Contrato” o las “Condiciones de Uso”) no hagas clic en Crear cuenta
            (u otro enlace similar) y no accedas ni utilices de otro modo
            nuestros Servicios. Si así lo deseas, puedes poner fin a este
            Contrato en cualquier momento; para ello, debes cerrar tu cuenta y
            no acceder a nuestros Servicios ni usarlos.
            <br />
            <br />
            <h3 className="color-secondary">Los Servicios</h3>
            Este Contrato se aplica a{" "}
            <a href="https://www.undiscovered.app/">www.undiscovered.app</a>,
            nuestros perfiles en redes sociales (Instagram, Tik Tok, Youtube)
            así como a aquellos sitios relacionados con Undiscovered, incluyendo
            aplicaciones, redes sociales, comunicaciones y cualquier otro tipo
            de servicio que se ofrece en relación con el Contrato (los
            “Servicios”). Lo anterior incluye la recopilación de información y
            datos al margen del sitio web para dichos Servicios, como lo pueden
            ser anuncios y/o complementos. Los usuarios suscritos a nuestros
            Servicios son “Usuarios” ya sea en su capacidad de (i) jugador; (ii)
            agente; o (iii) fan y los usuarios no registrados son “Visitantes”.
            <br />
            <br />
            <h3 className="color-secondary">El Contenido</h3>
            Los Usuarios podrán compartir el Contenido que consideren pertinente
            para utilizar de manera óptima los Servicios. Dicho Contenido podrá
            constar de videos, imágenes, datos, información o cualesquiera otros
            que sean propiedad del Usuario, o que este tenga derecho a utilizar
            de manera libre.
            <br />
            No puedes publicar contenido privado o confidencial de ninguna
            persona, ni llevar a cabo ninguna actividad que infrinja los
            derechos de otra persona, incluidos sus derechos de propiedad
            intelectual o industrial.
            <br />
            <br />
            <h3 className="color-secondary">Undiscovered</h3>
            Estás suscribiendo este Contrato con Undiscovered Talent Discovery,
            S.L. (al que también se hace referencia como “nosotros” o
            “nuestro”).
            <br />
            Utilizamos el término “Países Designados” para hacer referencia a
            los países de la Unión Europea (UE), del Espacio Económico Europeo
            (EEE) y a Suiza.
            <br />
            Si resides en los Países Designados o fuera de ellos, suscribes este
            Contrato con Undiscovered Talent Discovery, S.L., quien será la
            entidad responsable de los datos personales que facilites a nuestros
            Servicios, o que se recopilen o procesen por o para nuestros
            Servicios, o en relación con ellos.
            <br />
            Si eres Visitante o Usuario de nuestros Servicios, cualquier
            recopilación, uso e información compartida en relación con tus datos
            personales quedarán sujetos a esta Política de Privacidad (que
            incluye nuestra{" "}
            <Link to="/info/politica-cookies">Política de Cookies</Link> y otros
            documentos mencionados en esta Política de Privacidad) y sus
            actualizaciones de tiempo en tiempo.
            <br />
            <br />
            <h3 className="color-secondary">Usuarios y Visitantes</h3>
            Al registrarte y unirte al Servicio de Undiscovered o incorporarte
            como seguidor registrado en nuestras redes sociales, se te
            considerará como Usuario. Si has decidido no registrarte en nuestros
            Servicios, puedes acceder a determinadas funcionalidades y ver
            nuestros perfiles sociales como Visitante.
            <br />
            <br />
            <h3 className="color-secondary">
              Cambios (Podemos realizar modificaciones al Contrato)
            </h3>
            Podemos modificar este Contrato, nuestra Política de Privacidad y
            nuestra <Link to="/info/terminos-cookies">Política de Cookies</Link>{" "}
            periódicamente. Si introducimos algún cambio importante en el
            Contrato, te avisaremos a través de nuestros Servicios o por otros
            medios, para darte la oportunidad de revisar los cambios antes de
            que se hagan efectivos. Las modificaciones no pueden ser
            retroactivas. Si no estás de acuerdo con cualquiera de los cambios,
            puedes cerrar tu cuenta. Tu uso continuo de nuestros Servicios
            después de publicar o de enviar un aviso sobre los cambios en estos
            términos se reconoce como una aceptación en las modificaciones
            realizadas desde la fecha en vigor de dichas modificaciones.
            <br />
            <br />
            <br />
            <h3 className="color-primary uppercase">OBLIGACIONES</h3>
            <h3 className="color-secondary">
              Requisitos para utilizar nuestros Servicios (Cumples los
              requisitos para suscribir este Contrato y tienes el Mínimo de Edad
              establecido).
            </h3>
            Los menores de 15 años no pueden utilizar los servicios, si no
            cuentan con el consentimiento de los titulares de la patria
            potestad.
            <br />
            Para utilizar los Servicios, aceptas lo siguiente: (i) solo tendrás
            una cuenta en Undiscovered, que deberá ser relacionada con tu
            persona y nombre real; (ii) tener el “Mínimo de Edad” (según se
            define más adelante; y (iii) que no te hayamos restringido el uso de
            nuestros Servicios.
            <br />
            El Mínimo de Edad significa 15 años, o aquella edad mínima del país
            de tu residencia que permita el uso libre y completo de cualquier
            plataforma social donde se recabe parte de tu información
            particular, sin que sea necesario obtener el consentimiento de tus
            padres o tutores legales. El crear una cuenta con información falsa
            o sin tener el Mínimo de Edad (y contar con el consentimiento de tus
            padres o tutores legales) constituye una violación de este Contrato
            y de los Servicios.
            <br />
            <br />
            <h3 className="color-secondary">
              Tu cuenta (Mantendrás tu contraseña en secreto y no compartirás tu
              cuenta con ninguna otra persona, respetando nuestras normas y la
              legislación aplicable.)
            </h3>
            Los Usuarios son los titulares de las cuentas. Por lo tanto aceptan:
            <ul>
              <li>
                Utilizar una contraseña segura, secreta y mantener la
                confidencialidad de esta;
              </li>
              <li>
                No transferir (ceder, vender, arrendar, etc.) ninguna parte de
                su cuenta (por ejemplo, los contactos, videos, información);
              </li>
              <li>
                Respetar la legislación aplicable, las buenas prácticas sociales
                y el respeto mutuo entre usuarios.
              </li>
            </ul>
            Los Usuarios son responsables de todo lo que suceda en la cuenta
            salvo que la misma sea cerrada o se nos notifique que la misma está
            siendo utilizada por un tercero ajeno de manera indebida.
            <br />
            Entre los demás y tú (incluida la entidad o persona moral a la que
            perteneces), tu cuenta te pertenece de manera individual. Sin
            embargo, si los Servicios fueron adquiridos por terceros para que
            los utilices tú (una agencia de representación o un club), ese
            tercero que pague por los Servicios tendrá derecho de controlar el
            acceso y recibir información del uso de los Servicios que están
            pagando. Lo anterior no otorga ningún tipo de derecho, incluso
            económicos sobre la cuenta personal.
            <br />
            <br />
            <h3 className="color-secondary">Pago</h3>
            Si adquieres alguno de nuestros Servicios de pago (“Servicios
            Premium”), aceptas abonarnos las tasas y los impuestos aplicables y
            cumplir los términos adicionales específicos vinculados a dichos
            Servicios de Premium. El incumplimiento en el pago de las tasas y/o
            los impuestos aplicables, puede dar lugar a la terminación de los
            Servicios Premium.
            <br />
            De igual manera, aceptas que:
            <ul>
              <li>
                La compra puede estar sujeta a comisiones de cambio o a
                diferencias de precios en función de tu ubicación (por ejemplo,
                tipos de cambio).
              </li>
              <li>
                Podemos almacenar y continuar utilizando tu forma de pago (como
                una tarjeta de crédito) para fines de facturación, incluso
                después de haber caducado, para evitar la interrupción de los
                Servicios y utilizarla para pagar otros Servicios que puedas
                adquirir.
              </li>
              <li>
                Si adquieres una suscripción, se cargarán de forma automática a
                tu forma de pago al comienzo de cada periodo de suscripción las
                tasas e impuestos aplicables a ese periodo.
              </li>
              <li>
                Para evitar futuros cargos, cancela la suscripción antes de la
                fecha de renovación.
              </li>
            </ul>
            Podemos calcular los impuestos aplicables en función de la
            información de facturación que nos proporciones en el momento de la
            compra.
            <br />
            <br />
            <h3 className="color-secondary">Avisos y mensajes</h3>
            Aceptas que te enviemos avisos, información y mensajes de las
            siguientes manera: (i) mediante los Servicios, o (ii) a través a la
            información de contacto que nos proporcionaste (por ejemplo, correo
            electrónico, teléfono móvil, dirección postal).
            <br />
            Para poder enviar los avisos de manera oportuna, aceptas mantener
            actualizada tu información de contacto, así como revisar la
            configuración para gestionar y limitar los mensajes que te enviamos.
            <br />
            <br />
            <h3 className="color-secondary">Compartir</h3>
            Nuestros Servicios permiten enviar mensajes y compartir información
            de muchas maneras, como lo son (de manera enunciativa más no
            limitativa):
            <ul>
              <li>en tu perfil;</li>
              <li>mediante enlaces;</li>
              <li>en anuncio;</li>
              <li>
                en mensajes de nuestro sitio o de las demás redes sociales.
              </li>
            </ul>
            La información y el contenido que compartes o publicas pueden ser
            visibles para Usuarios, Visitantes u otras personas (incluso fuera
            del Servicio). Cuando exista la posibilidad de establecer una
            configuración, respetaremos las opciones que escojas sobre quién
            puede ver el contenido o la información (por ejemplo, el contenido
            de mensajes enviado a los destinatarios, compartir contenido solo
            con los contactos de Undiscovered, restringir la visibilidad de tu
            perfil en los motores de búsqueda u optar por no notificar a los
            demás usuarios que has actualizado tu perfil).
            <br />
            No estamos obligados a publicar información o contenido en nuestro
            Servicio y podremos retirarlo sin previo aviso, especialmente si el
            contenido o información pueda afectar los intereses legítimos de un
            tercero.
            <br />
            <br />
            <br />
            <h3 className="color-primary uppercase">DERECHOS Y LIMITACIONES</h3>
            <h3 className="color-secondary">Licencia con Undiscovered </h3>
            El Usuario es el propietario del contenido y de la información que
            proporcione o publique en los Servicios, y solo otorga a
            Undiscovered y a nuestras filiales la siguiente licencia no
            exclusiva:
            <ul>
              <li>
                un derecho mundial, transferible y sujeto a sublicencia para
                usar, copiar, modificar, distribuir, publicar y tratar la
                información y el contenido que nos proporciones a través de
                nuestros Servicios y los servicios de terceros, sin ningún
                consentimiento adicional, notificación o compensación para el
                Usuario o terceros. Estos derechos se limitan de la siguiente
                manera:
                <ul>
                  <li>
                    Puedes finalizar esta licencia para un contenido específico
                    borrando dicho contenido de los Servicios, o en general
                    cerrando tu cuenta, salvo (a) en la medida en que lo hayas
                    compartido con otras personas como parte del Servicio y
                    estas a su vez lo hayan copiado, vuelto a compartir o
                    almacenado y;
                  </li>
                  <li>
                    durante el tiempo razonable que tome el retirar el
                    contenido, de las copias de seguridad y otros sistemas.
                  </li>
                </ul>
              </li>
            </ul>
            Nos concedes permiso para mostrar tu nombre de usuario, tu foto del
            perfil e información sobre las acciones que realizas o tus
            relaciones junto a cuentas, anuncios, ofertas y otro contenido
            patrocinado (o en relación con ellos) que sigues o con los que
            interactúas, sin obtener compensación alguna a cambio.
            <br />
            Por ejemplo, podemos mostrar que te gusta una publicación
            patrocinada creada por una marca, empresa o club que nos ha pagado
            para que mostremos sus anuncios en Undiscovered.
            <br />
            Tenemos derecho, sin indemnizarte a ti ni a terceros, a publicar
            anuncios en relación con tu contenido e información, y tus acciones
            sociales, podrán verse e incluirse junto con los anuncios. Es
            posible que hagamos mención del uso de alguna funcionalidad de los
            Servicios si la utilizas, usando tu nombre o fotografía, con el fin
            de promocionar dicha funcionalidad en nuestros Servicios.
            <br />
            Aunque podemos editar y realizar cambios de formato en tu contenido
            (como traducirlo o transcribirlo, modificar el tamaño, el diseño o
            el tipo de archivo, o eliminar metadatos), no modificaremos el
            significado.
            <br />
            Como eres propietario de tu contenido e información, y solo poseemos
            derechos no exclusivos respecto a los mismos, puedes escoger
            compartirlo con terceros.
            <br />
            Asimismo, acuerdas con Undiscovered que podremos acceder, almacenar,
            procesar y usar cualquier información y dato personal que facilites
            de acuerdo con lo establecido en los términos de la Política de
            Privacidad.
            <br />
            Al enviar a Undiscovered sugerencias u otros comentarios sobre
            nuestros Servicios, aceptas que podemos utilizarlos y compartirlos
            para cualquier finalidad sin compensarte.
            <br />
            Aceptas solo proporcionar contenido e información que no infrinja la
            ley ni los derechos de ninguna persona (incluidos los derechos de
            propiedad intelectual). También acuerdas facilitar información
            verdadera en tu perfil. A Undiscovered se le puede exigir por ley
            retirar determinada información o contenido en ciertos países.
            <br />
            <br />
            <h3 className="color-secondary">
              Disponibilidad de los Servicios (No somos un servicio de
              almacenamiento)
            </h3>
            Podemos cambiar, suspender o retirar cualquiera de nuestros
            Servicios. De la misma forma, podemos modificar nuestros precios con
            carácter prospectivo, previa notificación dentro de un plazo
            razonable y en la medida en la que la legislación lo permita.
            <br />
            No podemos garantizar que almacenaremos o que continuaremos
            mostrando la información o el contenido que hayas publicado.
            Undiscovered no es un servicio de almacenamiento. Aceptas que no
            tenemos la obligación de almacenar, conservar o proporcionarte una
            copia de cualquier contenido o información que nos facilites tú u
            otras personas, salvo en la medida en que se exija por ley.
            <br />
            <br />
            <h3 className="color-secondary">
              Otros tipos de contenido, sitios web y aplicaciones
            </h3>
            Al utilizar los Servicios, puede que encuentres información o
            contenido inexactos, incompletos, atrasados, engañosos, ilegales,
            ofensivos o dañinos. Por lo general, Undiscovered no revisa el
            contenido proporcionado por los Usuarios u otras personas. Aceptas
            que no eres responsable del contenido o de la información de otras
            personas (incluidos otros Usuarios). No siempre podemos evitar dicho
            uso indebido de nuestros Servicios, y aceptas que no somos
            responsables de dicho uso. También reconocemos el riesgo de que tú o
            de que tu organización sean asociados por error a contenido sobre
            otras personas cuando permitimos que los contactos y los seguidores
            sepan que tú o tu organización han sido mencionados en el Contenido.
            <br />
            Undiscovered puede ayudar a que los Usuarios que ofrecen sus
            servicios (representación, futbolistas etc.) conecten con Usuarios
            que buscan dichos servicios o jugadores. Undiscovered no actúa ni
            emplea a particulares para que lleven a cabo estos servicios. En
            caso de contratar los servicios que ofrezca algún Usuario, dicha
            contratación debe de realizarse al margen de los Servicios y bajo
            las disposiciones aplicables de la relación jurídica que
            establecerán dichos Usuarios.
            <br />
            Por lo anterior, reconoces que Undiscovered no supervisa, dirige,
            controla ni lleva a cabo seguimiento alguno de los Usuarios en el
            desempeño de estos servicios y aceptas que (i) Undiscovered no es
            responsable de la oferta, el rendimiento o la adquisición de los
            dichos servicios; (ii) Undiscovered no suscribe en forma alguna los
            servicios ofrecidos por ningún Usuario en particular, y (iii) nada
            creará una relación de empleo, agencia o empresa conjunta entre
            <br />
            Undiscovered y cualquier Usuario que ofrezca servicios. Si eres
            Usuario y ofreces tus servicios, declaras y garantizas que posees
            todas las certificaciones necesarias, así como que proporcionarás
            tus servicios conforme a las mejores prácticas profesionales.
            <br />
            De igual manera, aceptas que (i) Undiscovered no se hace responsable
            de la conducta de ninguno de los Usuarios ni de otros asistentes a
            eventos promocionados en los Servicios, (ii) Undiscovered no aprueba
            ningún evento concreto que se incluya en nuestros Servicios, y (iii)
            Undiscovered no revisa o restringe ninguno de estos eventos.
            <br />
            <br />
            <h3 className="color-secondary">Limitaciones</h3>
            Undiscovered e reserva el derecho a limitar tu uso de los Servicios,
            incluidos tu número de contactos y tu capacidad para contactar con
            otros Usuarios. Undiscovered se reserva el derecho de restringir,
            suspender o cerrar tu cuenta si considera que puedes haber
            incumplido este Contrato, la ley o que estás utilizando
            incorrectamente los Servicios.
            <br />
            <br />
            <h3 className="color-secondary">Propiedad Intelectual</h3>
            Undiscovered se reserva todos sus derechos de propiedad intelectual
            en los Servicios. Las marcas y los logotipos utilizados en relación
            con los Servicios son las marcas comerciales de sus respectivos
            propietarios. Undiscocered y los logotipos utilizados y otras marcas
            de Undiscovered, las marcas de servicio, las imágenes y los
            logotipos utilizados para nuestros Servicios son marcas registradas
            de Undiscovered.
            <br />
            <br />
            <h3 className="color-secondary">Procesamiento automatizado</h3>
            Utilizaremos la información y los datos que nos facilitas, así como
            los datos de los que disponemos sobre los Usuarios, para realizar
            recomendaciones con respecto a contactos, contenido y
            funcionalidades que pueden resultar útiles. Por ejemplo, usamos
            datos e información sobre ti para recomendarte empleos y para
            recomendarte como candidato a los técnicos de selección de personal.
            El mantenimiento de un perfil actualizado con datos precisos nos
            ayuda a realizar recomendaciones más relevantes y rigurosas.
            <br />
            <br />
            <br />
            <h3 className="color-primary uppercase">
              EXENCIÓN Y LIMITACIÓN DE RESPONSABILIDAD{" "}
            </h3>
            <h3 className="color-secondary">Exención de garantía </h3>
            NI UNDISCOVERED NI NINGUNO DE SUS AFILIADOS PROPORCIONARÁN
            DECLARACIONES O GARANTÍA SOBRE LOS SERVICIOS, INCLUIDA CUALQUIER
            DECLARACIÓN QUE ESTABLEZCA QUE LOS SERVICIOS SERÁN ININTERRUMPIDOS O
            QUE ESTÉN LIBRES DE ERRORES, Y PROPORCIONARÁN DICHOS SERVICIOS
            (INCLUIDOS CONTENIDO E INFORMACIÓN) “TAL CUAL” Y “SEGÚN
            DISPONIBILIDAD”. EN LA MEDIDA EN QUE LO PERMITA LA LEGISLACIÓN
            APLICABLE, UNDISCOVERED Y SUS AFILIADOS DECLARAN LA EXENCIÓN DE
            CUALQUIER TIPO DE GARANTÍA IMPLÍCITA O LEGAL, INCLUIDAS LAS
            GARANTÍAS IMPLÍCITAS DE TÍTULO, PRECISIÓN DE LOS DATOS, NO
            INFRACCIÓN, COMERCIABILIDAD O IDONEIDAD PARA UN FIN DETERMINADO
            <br />
            <br />
            <h3 className="color-secondary">Exención de responsabilidad </h3>
            EN LA MEDIDA EN QUE LO PERMITA LA LEY (Y A MENOS QUE UNDISCOVERED
            PARTICIPE EN UN ACUERDO POR ESCRITO INDEPENDIENTE QUE ANULE ESTE
            CONTRATO), UNDISCOVERED Y SUS AFILIADOS, NO SERÁN RESPONSABLES, EN
            RELACIÓN CON ESTE CONTRATO, DE LA PÉRDIDA DE BENEFICIOS O DE
            OPORTUNIDADES DE NEGOCIO, DE LA REPUTACIÓN (POR EJEMPLO,
            DECLARACIONES OFENSIVAS O DIFAMATORIAS), DE LA PÉRDIDA DE DATOS (POR
            EJEMPLO, TIEMPO DE INACTIVIDAD O PÉRDIDA, USO O CAMBIOS EN LA
            INFORMACIÓN O EL CONTENIDO) O DE CUALQUIER DAÑO INDIRECTO,
            ACCIDENTAL, RESULTANTE COMO CONSECUENCIA, ESPECIAL O DE CARÁCTER
            PUNITIVO.
            <br />
            UNDISCOVERED Y SUS AFILIADOS NO SERÁN RESPONSABLES ANTE TI EN
            RELACIÓN CON ESTE CONTRATO POR NINGUNA CANTIDAD QUE EXCEDA (A) LOS
            GASTOS TOTALES ABONADOS O PENDIENTES DE ABONAR POR TU PARTE A
            UNDISCOVERED POR LOS SERVICIOS PRESTADOS DURANTE EL PLAZO
            ESTABLECIDO POR ESTE CONTRATO, SI LOS HUBIERA, O (B) $1,000.00 USD.
            <br />
            <br />
            <h3 className="color-secondary">
              Bases contractuales. Exclusiones
            </h3>
            Las limitaciones de responsabilidad de esta sección forman parte del
            acuerdo establecido entre Undiscovered y tú, y se aplicarán a todas
            las reclamaciones de responsabilidad (como las derivadas de
            garantía, daño, negligencia, responsabilidades contractuales o
            legales), incluso aunque se haya comunicado a Undiscovered y a sus
            afiliados la posibilidad de este daño, e incluso aunque estas
            soluciones no cumplan su objetivo principal.
            <br />
            Estas limitaciones de responsabilidad no se aplican a la
            responsabilidad por muerte o lesiones personales, fraude,
            negligencia grave o infracción dolosa, o en casos de negligencia en
            los que se haya infringido una obligación material, entendiendo por
            obligación material aquella que constituya un requisito previo para
            la prestación de nuestros servicios y en la que puedas confiar
            razonablemente, pero solo en la medida en que los daños se debieran
            directamente al incumplimiento y fueran previsibles al término de
            este Contrato y siempre que sean considerados típicos en el contexto
            del mismo.
            <br />
            <br />
            <br />
            <h3 className="color-primary uppercase">
              FINALIZACIÓN DEL CONTRATO
            </h3>
            Tanto tú como Undiscovered pueden dar por concluido este Contrato en
            cualquier momento tras comunicarlo a la otra parte. Una vez finalice
            este Contrato, perderás el derecho a acceder y utilizar los
            Servicios. Lo siguiente continuará aplicándose tras la finalización:
            <ul>
              <li>Nuestro derecho a utilizar y divulgar tus comentarios;</li>
              <li>
                Los derechos de los Usuarios o Visitantes de volver a compartir
                contenido e información que hayas compartido a través de los
                Servicios;
              </li>
              <li>
                Aquellas secciones que por su naturaleza no deban terminar; y
              </li>
              <li>
                Cualquier importe debido por cualquiera de las Partes antes de
                la finalización continuará debiéndose después de la misma.
              </li>
            </ul>
            <br />
            <h3 className="color-primary uppercase">
              LEGISLACIÓN APLICABLE Y RESOLUCIÓN DE CONFLICTOS{" "}
            </h3>
            Esta sección no anulará la aplicación de ninguna protección
            obligatoria del consumidor al amparo de la legislación de tu país de
            residencia habitual, desde el cual haces uso de los Servicios que te
            prestamos. Si resides en los Países designados: acuerdas con
            Undiscovered que las leyes del Reino de España, excepto en los casos
            de conflicto de jurisdicción, prevalecerán en cualquier disputa
            relacionada con este Contrato y/o los Servicios. Acuerdas con
            Undiscovered que las reclamaciones y disputas se resolverán
            únicamente en Madrid, España, pues esta será la jurisdicción del
            tribunal competente.
            <br />
            En los casos externos a los Países designados, incluidos aquellos
            fuera de los Estados Unidos: acuerdas con Undiscovered que la
            legislación del estado de Jalisco, México, excepto en los casos de
            conflicto de jurisdicción, prevalecerá en cualquier disputa
            relacionada con este Contrato y/o los Servicios. Acuerdas con
            Undiscovered que las reclamaciones y disputas se resolverán
            únicamente en los tribunales estatales o federales de Guadalajara,
            Jalisco, pues esta será la jurisdicción del tribunal competente.
            <br />
            <br />
            <br />
            <h3 className="color-primary uppercase">DISPOSICIONES GENERALES</h3>
            Si un tribunal con autoridad sobre este Contrato determina que no
            puede aplicarse alguna parte del mismo, tú y nosotros aceptamos que
            el tribunal modifique los términos para que esa parte sí pueda
            aplicarse y siga sirviendo su propósito. Si el tribunal no pudiera
            conseguirlo, tú y nosotros acordamos pedir al tribunal que elimine
            esa parte que no puede aplicarse y que mantenga el resto del
            Contrato.
            <br />
            Este Contrato (incluidos los términos adicionales que establezcamos
            cuando interacciones con una funcionalidad de los Servicios) es el
            único acuerdo entre nosotros sobre los Servicios y reemplaza todos
            los acuerdos anteriores sobre los Servicios.
            <br />
            Si no actuamos en caso de incumplimiento de este Contrato, ello no
            significa que Undiscovered haya renunciado a su derecho a hacer
            valer el Contrato. No puedes ceder o transferir este Contrato (o tu
            cuenta o uso de los Servicios) a nadie sin nuestro consentimiento.
            No obstante, aceptas que Uniscovered ceda este Contrato a sus
            filiales o a una tercera parte que lo compre sin tu consentimiento.
            No existen otros terceros beneficiarios del Contrato.
            <br />
            Aceptas que el único modo de enviarnos una notificación legal será
            en la dirección proporcionada en la Sección de{" "}
            <strong>COMO CONTACTARNOS</strong>.
            <br />
            <br />
            <br />
            <h3 className="color-primary uppercase">
              LO QUE SE DEBE Y NO SE DEBE HACER EN UNDISCOVERED
            </h3>
            <h3 className="color-secondary">Lo que se debe hacer:</h3>
            <ul>
              <li>
                Cumplir todas las leyes pertinentes, incluidas, entre otras, las
                leyes de privacidad, las leyes de propiedad intelectual, las
                leyes antispam, las leyes de control de las exportaciones, las
                leyes en materia fiscal y tributaria, y otros requisitos
                regulatorios.
              </li>
              <li>
                Proporcionarnos información exacta y mantenerla actualizada.
              </li>
              <li>Usar tu nombre verdadero en el perfil.</li>
              <li>Usar los Servicios de una manera profesional.</li>
            </ul>
            <h3 className="color-secondary">Lo que no se debe hacer: </h3>
            <ul>
              <li>
                Crear una identidad falsa, falsificar tu identidad, crear un
                perfil de Usuario para alguien que no seas tú (una persona
                física), ni usar o intentar usar la cuenta de otra persona.
              </li>
              <li>
                Desarrollar, apoyar o utilizar programas, dispositivos, scripts,
                robots o cualquier otro medio o proceso (incluidos crawlers,
                plugins de navegación y complementos, o cualquier otra
                tecnología) para plagiar los Servicios o copiar de otro modo
                perfiles u otros datos de los Servicios.
              </li>
              <li>
                Eludir cualquier funcionalidad de seguridad o sortear cualquier
                control de acceso o los límites de uso del Servicio (como
                límites en las búsquedas de palabras clave o visualizaciones del
                perfil).
              </li>
              <li>
                Copiar, utilizar, divulgar o distribuir cualquier información
                obtenida de los Servicios, ya sea de forma directa o a través de
                terceros (como los motores de búsqueda), sin el consentimiento
                de Undiscovered.
              </li>
              <li>
                Revelar información que no tienes derecho a compartir (como
                información confidencial de terceros, incluida tu empresa).
              </li>
              <li>
                Infringir los derechos de propiedad intelectual de terceros,
                como derechos de autor, patentes, marcas, secretos comerciales u
                otros derechos de propiedad. Por ejemplo, no copies ni
                distribuyas (salvo a través de la funcionalidad de compartir
                disponible) las publicaciones u otros tipos de contenido de
                otras personas sin su permiso.
              </li>
              <li>
                Infringir la propiedad intelectual u otros derechos de
                Undiscovered, incluido, sin limitación, (i) copiar o distribuir
                nuestros vídeos de aprendizaje u otros materiales; o (ii) copiar
                o distribuir nuestra tecnología, salvo que se ponga en
                circulación con licencias de código abierto; (iii) utilizar el
                término “Undiscovered” o nuestros logotipos en cualquier nombre
                comercial, correo electrónico o URL excepto en los casos
                descritos en las Directrices de marca.
              </li>
              <li>
                Publicar cualquier cosa que contenga virus informáticos, gusanos
                o cualquier otro código dañino.
              </li>
              <li>
                Utilizar técnicas de ingeniería inversa, descompilar, desarmar,
                descifrar o de otro modo tratar de obtener el código fuente de
                los Servicios o de cualquier tecnología relacionada que no sea
                de código abierto.
              </li>
              <li>
                Dar a entender o declarar que estás asociado o avalado por
                Undiscovered sin nuestro consentimiento expreso (por ejemplo,
                presentarte como formador acreditado de Undiscovered).
              </li>
              <li>
                Alquilar, prestar, comercializar, vender/revender u obtener
                cualquier otro tipo de beneficio de los Servicios o datos
                relacionados, ni acceder a los mismos sin el consentimiento de
                Undiscovered.
              </li>
              <li>
                Establecer enlaces a nuestros Servicios para cualquier otra
                finalidad que la de promover tu perfil o un grupo en nuestros
                Servicios, sin el consentimiento de Undiscovered.
              </li>
              <li>
                Utilizar bots u otros métodos automatizados para acceder a los
                Servicios, para añadir o descargar contactos o para enviar o
                redirigir mensajes.
              </li>
              <li>
                Controlar la disponibilidad, el rendimiento o el funcionamiento
                de los Servicios con fines competitivos.
              </li>
              <li>
                Llevar a cabo prácticas de “framing” o “mirroring”, o simular de
                otro modo la apariencia o función de los Servicios.
              </li>
              <li>
                Superponer o modificar de cualquier otro modo los Servicios o su
                apariencia (como, por ejemplo, insertando elementos en los
                Servicios o eliminando, tapando o disimulando anuncios incluidos
                en los Servicios).
              </li>
              <li>
                Interferir en el funcionamiento o cargar los Servicios de manera
                poco razonable (por ejemplo, spam, ataques de denegación de
                servicio, virus o algoritmos de juego).
              </li>
            </ul>
            <br />
            <h3 className="color-primary uppercase">
              QUEJAS RELATIVAS AL CONTENIDO
            </h3>
            Respetamos los derechos de propiedad intelectual de otras personas.
            Exigimos que la información publicada por los miembros sea exacta y
            que no infrinja los derechos de propiedad intelectual u otros
            derechos de terceros. En caso de cualquier queja contactarnos por
            los medios oficiales.
            <br />
            <br />
            <br />
            <h3 className="color-primary uppercase">COMO CONTACTARNOS</h3>
            Para consultas de tipo general, puedes ponerte en contacto con
            nosotros en línea. Si deseas recibir avisos legales o notificaciones
            judiciales, puedes escribirnos a estas direcciones{" "}
            <a href="mailto:contacto@undiscovered.app">
              contacto@undiscovered.app
            </a>
            , <a href="mailto:admin@undiscovered.app">admin@undiscovered.app</a>
            .
            <br />
            <br />
            <br />
          </p>
        </Container>
      </>
    );
  }
}

export default TerminosYCondiciones;
