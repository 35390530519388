import React from "react";
import {
  Container,
  Row,
  Col,
  Dropdown,
  DropdownToggle,
  Modal,
  ModalBody,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Button,
} from "reactstrap";

import UserFilter from "../components/Filters/UserFilter";
import ProfileCard from "../components/Modules/ProfileCard";

import userHelpers from "../helpers/user";
import utilitiesHelpers from "../helpers/utilities";

import countries from "./../components/Modules/data/countries.json";
import teams from "./../components/Modules/data/teams.json";

const queryString = require("query-string");

/**
 * Search Class.
 * Main search users view.
 * @constructor
 */
class Search extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      users: [],
      loading: true,
      name_username: "",
      filtered_users: false,
      user_type_filter: ["player", "fan", "agent"],
    };
    this.toggleModal = this.toggleModal.bind(this);
    this.handleChangeSearch = this.handleChangeSearch.bind(this);
    this.getUtilities = this.getUtilities.bind(this);
    this.setFilter = this.setFilter.bind(this);
    this.filtersContained = this.filtersContained.bind(this);
    this.setUserTypeFilter = this.setUserTypeFilter.bind(this);
  }

  /**
   * Upon mounting.
   * Get utilities from database.
   * Load data from search url
   * @method
   */
  async componentDidMount() {
    await this.getUtilities();
    const parsed = queryString.parse(this.props.location.search);
    await this.reloadSearch(parsed);
  }

  /**
   * Gets search parameters and checks if the search is filtered
   * @method
   * @param parsed url search parameters
   */
  async reloadSearch(parsed) {
    if (parsed.type && parsed.username) {
      this.setState({
        filteredSearch: true,
        search_type: parsed.type,
        search_username: parsed.username,
      });
      if (parsed.type === "discoveries") {
        await this.getDiscoveries();
      } else {
        await this.getDiscovering();
      }
    } else {
      await this.getUsers();
    }
    await this.getDiscovered();
    this.setState({
      uid: localStorage.getItem("userUndiscovered"),
      loading: false,
    });
  }

  /**
   * Upon update.
   * Checks if the next search query is different from the one in state.
   * @method
   * @param nextProps updated props.
   */
  async componentDidUpdate(nextProps) {
    if (nextProps.location.search !== this.props.location.search) {
      const parsed = queryString.parse(this.props.location.search);
      await this.reloadSearch(parsed);
    }
  }

  /**
   * Gets users that have discovered current user
   * @method
   */
  async getDiscoveries() {
    var user = await userHelpers.getUserByUrl(this.state.search_username);
    var users = await userHelpers.getDiscoveries(user.id);
    this.setState({
      users: users,
    });
  }

  /**
   * Gets users that the current user is discovering
   * @method
   */
  async getDiscovering() {
    var user = await userHelpers.getUserByUrl(this.state.search_username);
    var users = await userHelpers.getDiscovering(user.id);
    this.setState({
      users: users,
    });
  }

  /**
   * Basic toggle for modal.
   * @method
   */
  toggleModal() {
    this.setState({ modal: !this.state.modal });
  }

  /**
   * Gets all users to show.
   * @method
   */
  async getUsers() {
    let users = await userHelpers.getAllUsers();
    this.setState({ users: users });
  }

  /**
   * Checks what users are discovered and which ones are not
   * @method
   */
  async getDiscovered() {
    const discovered = await userHelpers.getDiscoveredUsers();
    let temp_users = this.state.users;
    for (let i = 0; i < temp_users.length; i++) {
      for (let j = 0; j < discovered.length; j++) {
        if (temp_users[i].id === discovered[j]) {
          temp_users[i].discovered = true;
        }
      }
    }
    this.setState({ users: temp_users });
  }

  /**
   * Gets utilities from db and external files.
   * @method
   */
  async getUtilities() {
    const positions = await utilitiesHelpers.getUtilitiesUser();
    this.setState({
      countries: countries,
      positions: positions,
      teams: teams,
      filtered_users: false,
    });
  }

  /**
   * Basic search change handle.
   * @method
   * @param name_username name/or username value filtered
   */
  async handleChangeSearch(name_username) {
    this.setState({ name_username: name_username });
  }

  /**
   * Sets filtered values to state
   * @method
   * @param country filtered value
   * @param city filtered value
   * @param gender filtered value
   * @param min_age filtered value
   * @param max_age filtered value
   * @param position filtered value
   * @param dominant_side filtered value
   * @param time30min filtered value
   * @param time30max filtered value
   * @param time1000min filtered value
   * @param time1000max filtered value
   * @param height_min filtered value
   * @param height_max filtered value
   * @param weight_min filtered value
   * @param weight_max filtered value
   * @param agent filtered value
   * @param contract filtered value
   */
  async setFilter(
    country,
    city,
    gender,
    min_age,
    max_age,
    position,
    dominant_side,
    time30min,
    time30max,
    time1000min,
    time1000max,
    height_min,
    height_max,
    weight_min,
    weight_max,
    agent,
    contract
  ) {
    this.setState({
      country: country,
      city: city,
      gender: gender,
      min_age: min_age,
      max_age: max_age,
      position: position,
      dominant_side: dominant_side,
      time30min: time30min,
      time30max: time30max,
      time1000min: time1000min,
      time1000max: time1000max,
      height_min: height_min,
      height_max: height_max,
      weight_min: weight_min,
      weight_max: weight_max,
      agent: agent,
      contract: contract,
      filtered_users: true,
    });
  }

  /**
   * Checks if current user is contained in filtered values
   * @method
   * @param country current user value
   * @param city current user value
   * @param gender current user value
   * @param birthday current user value
   * @param position current user value
   * @param alt_position current user value
   * @param dominant_side current user value
   * @param time30 current user value
   * @param time1000 current user value
   * @param height current user value
   * @param weight current user value
   * @param agent current user value
   * @param contract current user value
   */
  filtersContained(
    country,
    city,
    gender,
    birthday,
    position,
    alt_position,
    dominant_side,
    time30,
    time1000,
    height,
    weight,
    agent,
    contract,
    user_type
  ) {
    if (this.state.country && country !== this.state.country) return false;
    if (this.state.city && city !== this.state.city) return false;
    if (this.state.gender && gender !== this.state.gender) return false;
    if (this.state.dominant_side && dominant_side !== this.state.dominant_side)
      return false;

    if (this.state.agent && agent !== this.state.agent) return false;

    if (this.state.contract && contract !== this.state.contract) return false;

    if (this.state.agent && user_type !== "player") return false;

    if (
      this.state.min_age &&
      utilitiesHelpers.calcAge(birthday) < this.state.min_age
    )
      return false;

    if (
      this.state.max_age &&
      utilitiesHelpers.calcAge(birthday) > this.state.max_age
    )
      return false;

    if (
      this.state.time30min !== "00:00" &&
      (!time30 || time30 < this.state.time30min)
    )
      return false;

    if (
      this.state.time30max !== "00:00" &&
      (!time30 || time30 > this.state.time30max)
    )
      return false;

    if (
      this.state.time1000min !== "00:00" &&
      (!time1000 || time1000 < this.state.time1000min)
    )
      return false;

    if (
      this.state.time1000max !== "00:00" &&
      (!time1000 || time1000 > this.state.time1000max)
    )
      return false;

    if (this.state.height_min && (!height || height < this.state.height_min))
      return false;

    if (this.state.height_max && (!height || height > this.state.height_max))
      return false;

    if (this.state.weight_min && (!weight || weight < this.state.weight_min))
      return false;

    if (this.state.weight_max && (!weight || weight > this.state.weight_max))
      return false;

    if (
      this.state.position &&
      position !== this.state.position &&
      alt_position !== this.state.position
    ) {
      return false;
    }

    if (!this.state.user_type_filter.includes(user_type)) {
      return false;
    }
    return true;
  }

  setUserTypeFilter(user_type) {
    Array.prototype.remove = function () {
      var what,
        a = arguments,
        L = a.length,
        ax;
      while (L && this.length) {
        what = a[--L];
        while ((ax = this.indexOf(what)) !== -1) {
          this.splice(ax, 1);
        }
      }
      return this;
    };

    var user_type_filter = this.state.user_type_filter;
    if (user_type_filter.includes(user_type)) {
      user_type_filter.remove(user_type);
    } else {
      user_type_filter.push(user_type);
    }
    this.setState({
      user_type_filter: user_type_filter,
    });
  }

  /**
   * Renders the Search component.
   * @see Home Component
   */
  render() {
    let user_display;
    if (this.state.users.length > 0) {
      user_display = this.state.users.map((user) => {
        if (!user.blocked) {
          if (user.username) {
            if (
              user.id === localStorage.getItem("userUndiscovered") ||
              user.blocked === true
            ) {
              return null;
            } else if (
              user.username
                .toLowerCase()
                .includes(this.state.name_username.toLowerCase()) ||
              user.name
                .toLowerCase()
                .includes(this.state.name_username.toLowerCase())
            ) {
              if (this.state.filtered_users) {
                if (
                  this.filtersContained(
                    user.country,
                    user.city,
                    user.gender,
                    user.birthday,
                    user.position,
                    user.alt_position,
                    user.dominant_side,
                    user.time30,
                    user.time1000,
                    user.height,
                    user.weight,
                    user.agent,
                    user.contract,
                    user.user_type
                  )
                ) {
                  return (
                    <ProfileCard {...this.props} key={user.id} user={user} />
                  );
                } else {
                  return null;
                }
              } else {
                if (this.state.user_type_filter.includes(user.user_type)) {
                  return (
                    <ProfileCard {...this.props} key={user.id} user={user} />
                  );
                } else {
                  return null;
                }
              }
            } else {
              return null;
            }
          } else {
            return null;
          }
        } else {
          return null;
        }
      });
    } else {
      user_display = (
        <h4 className="text-center full-width mt-4">
          No hay usuarios que mostrar para esta búsqueda.
        </h4>
      );
    }

    if (this.state.loading) {
      return (
        <div className="preloader-wrapper">
          <embed
            className="preloader-icon"
            src={require("assets/img/brand/preloader.svg")}
          />
        </div>
      );
    } else {
      return (
        <>
          {/* Page content */}
          <Container className="mt-0 mt-lg-0" fluid>
            {this.state.filteredSearch ? (
              <div
                className="back-button"
                onClick={() => this.props.history.goBack()}
              >
                <img
                  alt="Undiscovered Atrás"
                  src={require("assets/img/icons/back.svg")}
                />
              </div>
            ) : null}

            <Row>
              <Col
                className="order-lg-1 mb-2 mb-lg-0 mt-3 mt-lg-3 no-mobile"
                lg="4"
              >
                <div className="p-sticky-1">
                  <div className="d-flex justify-content-center mb-3">
                    <Button
                      className="discovered button small"
                      color={
                        this.state.user_type_filter.includes("player")
                          ? "primary"
                          : "white"
                      }
                      size="sm"
                      onClick={() => {
                        this.setUserTypeFilter("player");
                      }}
                    >
                      JUGADORES
                    </Button>
                    <Button
                      className="discovered button small"
                      color={
                        this.state.user_type_filter.includes("fan")
                          ? "primary"
                          : "white"
                      }
                      size="sm"
                      onClick={() => {
                        this.setUserTypeFilter("fan");
                      }}
                    >
                      FANS
                    </Button>
                    <Button
                      className="discovered button small"
                      color={
                        this.state.user_type_filter.includes("agent")
                          ? "primary"
                          : "white"
                      }
                      size="sm"
                      onClick={() => {
                        this.setUserTypeFilter("agent");
                      }}
                    >
                      AGENTES/CLUBES
                    </Button>
                  </div>
                  <UserFilter
                    handleChangeSearch={this.handleChangeSearch}
                    setFilter={this.setFilter}
                    countries={this.state.countries}
                    positions={this.state.positions}
                    getUtilities={() => this.getUtilities()}
                    hiddenFilters={
                      this.state.user_type_filter.includes("player")
                        ? false
                        : true
                    }
                  />
                </div>
              </Col>
              <div className="user-type-filter full-width justify-content-center mt-3 mb-0 no-desktop">
                <Button
                  className="discovered button small"
                  color={
                    this.state.user_type_filter.includes("player")
                      ? "primary"
                      : "white"
                  }
                  size="sm"
                  onClick={() => {
                    this.setUserTypeFilter("player");
                  }}
                >
                  JUGADORES
                </Button>
                <Button
                  className="discovered button small"
                  color={
                    this.state.user_type_filter.includes("fan")
                      ? "primary"
                      : "white"
                  }
                  size="sm"
                  onClick={() => {
                    this.setUserTypeFilter("fan");
                  }}
                >
                  FANS
                </Button>
                <Button
                  className="discovered button small"
                  color={
                    this.state.user_type_filter.includes("agent")
                      ? "primary"
                      : "white"
                  }
                  size="sm"
                  onClick={() => {
                    this.setUserTypeFilter("agent");
                  }}
                >
                  AGENTES/CLUBES
                </Button>
              </div>
              <Col
                className="d-flex justify-content-space-between mb-0 mt-3 mt-lg-1 no-desktop"
                xs="12"
                lg="0"
              >
                <InputGroup className="form-control-alternative search-bar no-desktop mb-0 mobile-bigger-dropdown">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <img
                        alt="Undiscovered"
                        src={require("assets/img/icons/search.svg")}
                        className="icon search-icon"
                      />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    className="search-input"
                    type="text"
                    name="search-input"
                    placeholder="Buscar Usuario..."
                    autoComplete="new-password"
                    onChange={(e) => this.handleChangeSearch(e.target.value)}
                  />
                </InputGroup>
                <Dropdown
                  isOpen={false}
                  className="no-desktop"
                  toggle={() => {
                    this.toggleModal();
                  }}
                >
                  <DropdownToggle
                    caret
                    color="white"
                    className="filter-button mobile-bigger-dropdown"
                  >
                    Filtrar
                  </DropdownToggle>
                </Dropdown>
                <Modal
                  isOpen={this.state.modal}
                  unmountOnClose={false}
                  className="posts-filter-modal extra-bottom-margin-modal"
                >
                  <ModalBody className="p-0">
                    <UserFilter
                      className="bg-primary"
                      handleChangeSearch={this.handleChangeSearch}
                      setFilter={this.setFilter}
                      countries={this.state.countries}
                      positions={this.state.positions}
                      getUtilities={() => this.getUtilities()}
                      toggleModal={() => this.toggleModal()}
                      is_modal={true}
                      hiddenFilters={
                        this.state.user_type_filter.includes("player")
                          ? false
                          : true
                      }
                    />
                  </ModalBody>
                </Modal>
              </Col>

              <Col className="order-lg-2 mb-0 mb-xl-0 mt-2" lg="8">
                {user_display}
                {this.state.users[0] ? (
                  <div className="h4 ls-2 full-width text-center">
                    No hay más usuarios para mostrar.
                  </div>
                ) : null}
              </Col>
            </Row>
          </Container>
        </>
      );
    }
  }
}

export default Search;
