import React from "react";
import Typography from "@material-ui/core/Typography";

import { withStyles, makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import PauseIcon from "@material-ui/icons/Pause";
import VolumeUpIcon from "@material-ui/icons/VolumeUp";
import FullScreenIcon from "@material-ui/icons/Fullscreen";
import FullscreenExitIcon from "@material-ui/icons/FullscreenExit";
import VolumeOffIcon from "@material-ui/icons/VolumeOff";
import Duration from "./Duration";
const useStyles = makeStyles({
  root: {
    height: 4,
  },
  controlsWrapper: {
    position: "relative",
    left: 0,
    right: 0,
    background: "rgba(0,0,0,1)",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    zIndex: 1,
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
    marginTop: -1,
  },
  controlsWrapperAbsolute: {
    position: "absolute",
    left: 0,
    right: 0,
    background: "rgba(0,0,0,0.6)",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    zIndex: 100,
    borderRadius: 10,
  },
  controlIcons: {
    color: "#777",
    fontSize: 30,
    transform: "scale(0.9)",
    "&:hover": {
      color: "#fff",
      transform: "scale(1)",
    },
  },
  bottomIcons: {
    color: "#999",
    fontSize: 29,
    "&:hover": {
      color: "#fff",
    },
  },
  volumeSlider: {
    width: 100,
  },
  video_grid: {
    paddingHorizontal: 15,
    paddingVertical: 5,
  },
  slider: {
    "-webkit-appearance": "none",
    appearance: "none",
    width: "100%",
    height: 6.5,
    background: "#d3d3d3",
    outline: "none",
    opacity: 0.7,
    "&::-moz-range-thumb": {
      width: 23,
      height: 23,
      background: "#00EED9",
      cursor: "pointer",
      borderRadius: "100%",
      border: "none",
    },
    "&::-webkit-slider-thumb": {
      "-webkit-appearance": "none",
      appearance: "none",
      width: 23,
      height: 23,
      background: "#00EED9",
      cursor: "pointer",
      borderRadius: "100%",
      border: "none",
    },
  },
});

export default (props) => {
  const classes = useStyles();
  var styles;
  const controlsWrapper = {};
  if (props.is_fullscreen) {
    styles = classes.controlsWrapperAbsolute;
    if (props.ios) {
      controlsWrapper.top = 0;
    } else {
      controlsWrapper.bottom = 0;
    }
  } else {
    styles = classes.controlsWrapper;
  }

  return (
    <div className={styles} style={controlsWrapper}>
      <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="center"
        className="video_grid"
      >
        <Grid item xs={12}>
          <input
            className={classes.slider}
            type="range"
            step="any"
            min={0}
            max={0.99999}
            value={props.played}
            // onMouseDown={props.handleSeekMouseDown}
            onChange={props.handleSeekChange}
            // onMouseUp={props.handleSeekMouseUp}
          />
        </Grid>
        <Grid item>
          <Grid container alignItems="center" direction="row">
            <IconButton
              onClick={props.handleClickPlay}
              className={classes.bottomIcons}
            >
              {props.playing ? (
                <PauseIcon fontSize="inherit" />
              ) : (
                <PlayArrowIcon fontSize="inherit" />
              )}
            </IconButton>

            <IconButton
              onClick={props.handleToggleMuted}
              className={classes.bottomIcons}
            >
              {!props.muted ? (
                <VolumeUpIcon fontSize="inherit" />
              ) : (
                <VolumeOffIcon fontSize="inherit" />
              )}
            </IconButton>
            <Button
              className="duration_label"
              variant="text"
              style={{ color: "#fff", marginLeft: 10 }}
            >
              <Typography>
                <Duration seconds={props.duration * (1 - props.played)} />
              </Typography>
            </Button>
          </Grid>
        </Grid>
        <Grid
          item
          onClick={() =>
            props.is_fullscreen
              ? props.handleClickFullscreenOut()
              : props.handleClickFullscreen()
          }
        >
          {props.is_fullscreen ? (
            <IconButton className={classes.bottomIcons}>
              <FullscreenExitIcon fontSize="inherit" />
            </IconButton>
          ) : (
            <IconButton className={classes.bottomIcons}>
              <FullScreenIcon fontSize="inherit" />
            </IconButton>
          )}
        </Grid>
      </Grid>
    </div>
  );
};
