import React from "react";
import {
  Container,
  Button,
  Col,
  Card,
  Modal,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Input,
  Alert,
} from "reactstrap";
import firebase from "../../config/firebase.js";
import userHelpers from "../../helpers/user";

/**
 * Eliminar Class.
 * Main Delete User component view.
 * @constructor
 */
class Eliminar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error_message: "",
      modal: false,
      email: "",
      password: "",
      username: "",
    };
    this.openModal = this.openModal.bind(this);
    this.deleteUser = this.deleteUser.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  async componentDidMount(){
    var username = await userHelpers.getUsername(
      localStorage.getItem("userUndiscovered")
    );
    this.setState({
      username: username,
    });
  }

  /**
   * Basic change handle for inputs
   * @method
   * @param e Element firing the function
   */
  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  /**
   * Reauthenticates user and deletes them from db.
   * @method
   */
  async deleteUser() {
    try {
      const user = firebase.auth().currentUser;
      const credential = firebase.auth.EmailAuthProvider.credential(
        this.state.email,
        this.state.password
      );
      await user.reauthenticateWithCredential(credential);
      await userHelpers.deleteUser();
      localStorage.removeItem("userUndiscovered");
      this.props.history.push("/auth/login");
    } catch (err) {
      this.setState({
        error_message: "Correo y/o contraseña incorrectos.",
      });
    }
  }

  /**
   * Basig modal toggle.
   * Resets modal values.
   * @method
   */
  openModal() {
    this.setState({
      modal: !this.state.modal,
      email: "",
      password: "",
      error_message: "",
    });
  }

   /**
   * Renders the Eliminar component.
   * @see Eliminar Component
   */
  render() {
    return (
      <>
        {/* Page content */}
        <Container fluid>
          <h2 className="ls-2  text-center">ELIMINAR MI CUENTA</h2>
          <Col md={{ size: 6, offset: 3 }} className="mb-8">
            <p className="text-center">
              Al eliminar tu cuenta se borrarán todos los datos que UNDISCOVERED
              almacena sobre ella y ya no podrás tener acceso a tu cuenta y los
              videos e información que hayas subido. Solamente podrás crear una
              cuenta nueva en caso de que quieras seguir utilizando la
              aplicación de UNDISCOVERED.
              <br />
              <br />
            </p>
            <Card className="shadow my-3 mt-4 py-4">
              <h3 className="text-center mb-2">Esta acción es irreversible.</h3>
              <div className="d-flex justify-content-center">
                <Button color="white" onClick={() => this.openModal()}>
                  ELIMINAR CUENTA
                </Button>
              </div>
            </Card>
            <Modal
              isOpen={this.state.modal}
              toggle={() => this.openModal()}
              className="mt-8"
            >
              <ModalBody className="text-center mt-2 pb-0">
                <p className="mb-2">
                  ¿Estas seguro de que quieres eliminar tu cuenta de
                  UNDISCOVERED?
                </p>

                <Form role="form">
                  <FormGroup className="mb-2">
                    <Input
                      className="form-control-alternative"
                      type="text"
                      name="email"
                      value={this.state.email}
                      placeholder="Correo"
                      onChange={this.handleChange}
                    />
                  </FormGroup>
                  <FormGroup className="mb-2">
                    <Input
                      className="form-control-alternative"
                      type="password"
                      name="password"
                      placeholder="Contraseña"
                      value={this.state.password}
                      onChange={this.handleChange}
                    />
                  </FormGroup>
                  <Alert
                    color="warning"
                    hidden={this.state.error_message === "" ? true : false}
                  >
                    <strong>Error:</strong> {this.state.error_message}
                  </Alert>
                </Form>
              </ModalBody>
              <ModalFooter className="d-flex justify-content-center">
                <Button color="primary" onClick={() => this.openModal()}>
                  CANCELAR
                </Button>{" "}
                <Button color="white" onClick={() => this.deleteUser()}>
                  ELIMINAR CUENTA
                </Button>
              </ModalFooter>
            </Modal>
            <hr />
            <div className="d-flex justify-content-center mb-8">
              <Button color="primary" onClick={()=> this.props.history.push("/perfil/" + this.state.username)}>IR A MI PERFIL</Button>
            </div>
          </Col>
        </Container>
      </>
    );
  }
}

export default Eliminar;
