import React from "react";
import { Button } from "reactstrap";
import FileUploader from "react-firebase-file-uploader";
import firebase from "./../../config/firebase.js";
import utilitiesHelpers from "./../../helpers/utilities";
import userHelpers from "helpers/user.jsx";
import ImageTools from "./data/ImageTools.js";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";

/**
 * EditProfilePicture Class.
 * Main Edit Profile Picture for posts component view.
 * @constructor
 */
class EditProfilePicture extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      profile_picture_url: "",
      progress: 0,
      isUploading: false,
      showCrop: false,
      crop: {
        unit: "%",
        width: 100,
        aspect: 1 / 1,
      },
    };
  }

  /**
   * Upon mounting, get user data
   * @method
   */
  async componentDidMount() {
    const uid = localStorage.getItem("userUndiscovered");
    var user = await userHelpers.getUserData(uid);
    this.setState({
      profile_picture_url: user.profile_picture_url,
    });
  }

  /**
   * Rotates the image
   * @method
   */
  handleChange = (event) => {
    this.setState({ progress: 0, isUploading: true });
    var file = event.target.files[0];
    this.getOrientation(file, (orientation) => {
      this.getBase64(file, orientation);
    });
  };

  /**
   * Gets the orientation of the image before upload
   * @method
   * @param file the file being uploaded
   * @param callback the orientation of the image that will be passed
   */

  getOrientation(file, callback) {
    var reader = new FileReader();

    reader.onload = function (event) {
      var view = new DataView(event.target.result);

      if (view.getUint16(0, false) != 0xffd8) return callback(-2);

      var length = view.byteLength,
        offset = 2;

      while (offset < length) {
        var marker = view.getUint16(offset, false);
        offset += 2;

        if (marker == 0xffe1) {
          if (view.getUint32((offset += 2), false) != 0x45786966) {
            return callback(-1);
          }
          var little = view.getUint16((offset += 6), false) == 0x4949;
          offset += view.getUint32(offset + 4, little);
          var tags = view.getUint16(offset, little);
          offset += 2;

          for (var i = 0; i < tags; i++)
            if (view.getUint16(offset + i * 12, little) == 0x0112)
              return callback(view.getUint16(offset + i * 12 + 8, little));
        } else if ((marker & 0xff00) != 0xff00) break;
        else offset += view.getUint16(offset, false);
      }
      return callback(-1);
    };

    reader.readAsArrayBuffer(file.slice(0, 64 * 1024));
  }

  /**
   * Resets the orientation of the image
   * @method
   * @param srcBase64 the file being uploaded in Base64
   * @param srcOrientation the original orientation of the image
   * @param callback the image in Base64 that will be passed
   */
  resetOrientation(srcBase64, srcOrientation, callback) {
    var img = new Image();

    img.onload = () => {
      var width = img.width,
        height = img.height,
        canvas = document.createElement("canvas"),
        ctx = canvas.getContext("2d");

      // set proper canvas dimensions before transform & export
      if (srcOrientation > 4 && srcOrientation < 9) {
        canvas.width = width;
        canvas.height = height;
      } else {
        canvas.width = width;
        canvas.height = height;
      }

      // Honestamente no tengo idea porqué funciona clonandolo...
      var new_canvas = document.createElement("canvas"),
        new_canvas = canvas;

      var ctx2 = new_canvas.getContext("2d");
      new_canvas.width = canvas.width;
      new_canvas.height = canvas.height;

      // transform context before drawing image
      switch (srcOrientation) {
        case 2:
          ctx.transform(-1, 0, 0, 1, width, 0);
          ctx2.transform(-1, 0, 0, 1, width, 0);
          break;
        case 3:
          ctx.transform(-1, 0, 0, -1, width, height);
          ctx2.transform(-1, 0, 0, -1, width, height);
          break;
        case 4:
          ctx.transform(1, 0, 0, -1, 0, height);
          ctx2.transform(1, 0, 0, -1, 0, height);
          break;
        case 5:
          ctx.transform(0, 1, 1, 0, 0, 0);
          ctx2.transform(0, 1, 1, 0, 0, 0);
          break;
        case 6:
          ctx.transform(0, 1, 1, 0, 0, 0);
          ctx2.transform(0, 1, 1, 0, 0, 0);
          break;
        case 7:
          ctx.transform(0, -1, -1, 0, height, width);
          ctx2.transform(0, -1, -1, 0, height, width);
          break;
        case 8:
          ctx.transform(0, -1, 1, 0, 0, width);
          ctx2.transform(0, -1, 1, 0, 0, width);
          break;
      }

      // draw image
      ctx.drawImage(img, 0, 0);
      ctx2.drawImage(img, 0, 0);

      // export base64
      callback(new_canvas.toDataURL());
    };

    img.src = srcBase64;
  }

  /**
   * Re-sizes the image to a max of 400x400px
   * @method
   * @param dataURI the file being uploaded in Base64
   */
  dataURItoBlob(dataURI) {
    // convert base64 to raw binary data held in a string
    var byteString = atob(dataURI.split(",")[1]);

    // write the bytes of the string to an ArrayBuffer
    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    // write the ArrayBuffer to a blob, and you're done
    var bb = new Blob([ab], { type: "image/jpeg" });
    ImageTools.resize(
      bb,
      { width: 400, height: 400 },
      async (blob, didItResize) => {
        var reader = new FileReader();
        reader.readAsDataURL(blob);

        reader.addEventListener("load", () => {
          this.setState({ src: reader.result, showCrop: true });
        });
      }
    );
    return bb;
  }
  /**
   * Transforms the image to Base64
   * @method
   * @param file the File being passed
   * @param orientation the orientation of the current image
   */

  getBase64(file, orientation) {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      var base64 = reader.result;
      this.resetOrientation(base64, orientation, (resetBase64Image) => {
        this.dataURItoBlob(resetBase64Image);
      });
    };
    reader.onerror = (error) => {
      console.log("Error: ", error);
    };
  }

  /**
   * Shows loading progress info
   * @method
   * @param progress percentage of upload completed
   */
  handleProgress = (progress) => {
    this.setState({ progress });
  };

  /**
   * Shows error in upload and stops loading
   * @method
   * @param error Reason for upload stopping
   */
  handleUploadError = (error) => {
    console.log(error);
    this.setState({ isUploading: false });
  };

  // If you setState the crop in here you should return false.
  onImageLoaded = (image) => {
    this.imageRef = image;
    this.setState({ progress: 0, isUploading: false });
  };

  onCropComplete = (crop) => {
    this.makeClientCrop(crop);
  };

  onCropChange = (crop, percentCrop) => {
    this.setState({ crop });
  };

  async makeClientCrop(crop) {
    if (this.imageRef && crop.width && crop.height) {
      const croppedImageUrl = await this.getCroppedImg(
        this.imageRef,
        crop,
        "profile_picture.jpeg"
      );
      this.setState({ croppedImageUrl });
    }
  }

  getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob((blob) => {
        if (!blob) {
          //reject(new Error('Canvas is empty'));
          console.error("Canvas is empty");
          return;
        }
        blob.name = fileName;
        window.URL.revokeObjectURL(this.fileUrl);
        this.fileUrl = window.URL.createObjectURL(blob);
        this.setState({ blob });
        resolve(this.fileUrl);
      }, "image/jpeg");
    });
  }

  /**
   * Sets the profile url in db and state.
   * Activates showing of profile picture in render
   * @method
   * @param filename Designated filename for profile picture
   */
  async finishUpload() {
    const url = await utilitiesHelpers.uploadProfilePicture(
      this.props.uid,
      this.state.blob
    );

    this.setState({
      profile_picture_url: url,
      progress: 100,
      isUploading: false,
      showCrop: false,
    });
  }

  /**
   * Renders the EditProfilePicture component.
   * @see EditProfilePicture Component
   */
  render() {
    return (
      <div className="mb-2">
        {this.state.isUploading && (
          <p className="description uploading">
            {this.state.progress}% Cargado
          </p>
        )}
        {this.state.src && this.state.showCrop ? (
          <div className="d-flex flex-vertical">
            <div>
              <ReactCrop
                src={this.state.src}
                crop={this.state.crop}
                ruleOfThirds
                onImageLoaded={this.onImageLoaded}
                onComplete={this.onCropComplete}
                onChange={this.onCropChange}
                style={{ maxWidth: "100%" }}
              />
            </div>
            <div>
              <Button
                className="button txt-black"
                color="primary"
                href=""
                onClick={() => {
                  this.finishUpload();
                }}
                size="md"
              >
                GUARDAR FOTO
              </Button>
            </div>
          </div>
        ) : (
          <>
            <div className="profile-picture-wrapper mt-3 mb-2">
              <img
                alt="..."
                className="profile-picture m-0 edit"
                src={
                  this.state.profile_picture_url !== "" &&
                  this.state.profile_picture_url !== undefined
                    ? this.state.profile_picture_url
                    : require("assets/img/brand/ProfilePlaceholder.png")
                }
              />
            </div>
            <label className="edit-photo link">
              Editar Foto
              <FileUploader
                hidden
                accept="image/*"
                name="profile_picture"
                filename="profile_picture"
                storageRef={firebase.storage().ref(`users/${this.props.uid}`)}
                metadata={{ cacheControl: "max-age=3600" }}
                maxWidth="200"
                maxHeight="200"
                onChange={this.handleChange}
                onUploadError={this.handleUploadError}
                onUploadSuccess={this.handleUploadSuccess}
                onProgress={this.handleProgress}
              />
            </label>
          </>
        )}
      </div>
    );
  }
}
export default EditProfilePicture;
