import React from "react";
import { Row, Card, Button, Col } from "reactstrap";

/**
 * PostFilter Class.
 * Main Filter for posts component view.
 * @constructor
 */
class PostFilter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      skills_active: "all",
      physical_skills: {},
      technical_skills: {},
      type_skills: {},
    };
    this.toggleVideoType = this.toggleVideoType.bind(this);
    this.filter = this.filter.bind(this);
    this.clearFilter = this.clearFilter.bind(this);
  }

  /**
   * Upon mounting, get utilities from props
   * @method
   */
  componentDidMount() {
    this.setState({
      physical_skills: this.props.physical_skills,
      technical_skills: this.props.technical_skills,
      type_skills: this.props.type_skills,
    });
  }

  /**
   * Resets all the filter values
   * @method
   */
  async clearFilter() {
    await this.props.getUtilities();
    this.setState(
      {
        physical_skills: this.props.physical_skills,
        technical_skills: this.props.technical_skills,
        type_skills: this.props.type_skills,
        video_type: "all",
      }
      // await this.props.setSkills(
      //   "all",
      //   this.state.physical_skills,
      //   this.state.technical_skills,
      //   this.state.type_skills,
      //   false
      // )
    );
    this.props.toggleVideoType("all");

    if (this.props.is_modal) {
      this.props.toggleModal();
    }
  }

  /**
   * Basic change handle for video type
   * @method
   * @param type skill/game video type
   */
  toggleVideoType(type) {
    this.setState({ video_type: type });
  }

  /**
   * Basic change handle for skills
   * @method
   * @param skill_type skill name
   * @param index index of current skill
   */
  async filter() {
    await this.props.setSkills(
      this.state.video_type,
      this.state.physical_skills,
      this.state.technical_skills,
      this.state.type_skills,
      true
    );
  }

  /**
   * Basic change handle for skills
   * @method
   * @param skill_type skill name
   * @param index index of current skill
   */
  handleSkillChange(skill_type, index) {
    var temp = { ...this.state[skill_type] };
    temp[index].active = !temp[index].active;
    this.setState({
      [skill_type]: temp,
    });
  }

  /**
   * Renders the PostFilter component.
   * @see PostFilter Component
   */
  render() {
    let skillsFilter;
    if (this.state.video_type === "skill") {
      skillsFilter = (
        <Row className="justify-content-center pt-0 mt-1 mb-1 pb-4">
          <Col className="text-center">
            <h5 className="full-width text-center mb-0">
              Filtrar habilidades físicas
            </h5>
            {this.state.physical_skills && Object.values(this.state.physical_skills).map((skill, i) => {
              return (
                <Button
                  className="button small mb-2"
                  color={skill.active ? "black" : "white"}
                  size="sm"
                  key={i}
                  name={skill.value}
                  onClick={() => {
                    this.handleSkillChange("physical_skills", i);
                  }}
                >
                  {skill.value}
                </Button>
              );
            })}
            <h5 className="full-width text-center mt-1 mb-0">
              Filtrar habilidades técnicas
            </h5>
            {this.state.technical_skills && Object.values(this.state.technical_skills).map((skill, i) => {
              return (
                <Button
                  className="button small mb-2"
                  color={skill.active ? "black" : "white"}
                  size="sm"
                  key={i}
                  name={skill.value}
                  onClick={() => {
                    this.handleSkillChange("technical_skills", i);
                  }}
                >
                  {skill.value}
                </Button>
              );
            })}
            <h5 className="full-width text-center mt-1 mb-0">
              Filtrar tipo de post
            </h5>
            {this.state.type_skills && Object.values(this.state.type_skills).map((skill, i) => {
              return (
                <Button
                  className="button small mb-2"
                  color={skill.active ? "black" : "white"}
                  size="sm"
                  key={i}
                  name={skill.value}
                  onClick={() => {
                    this.handleSkillChange("type_skills", i);
                  }}
                >
                  {skill.value}
                </Button>
              );
            })}
          </Col>
        </Row>
      );
    }

    return (
      <>
        {/* Page content */}
        <Card className="card-profile shadow p-3">
          <div className="h4 text-center ls-2">FILTRAR POSTS</div>
          <Row className="justify-content-center pt-2 mt--2 pb-2">
            <div
              onClick={() => this.toggleVideoType("game")}
              className={
                "post-type-select-filter h4 txt-primary " +
                (this.state.video_type === "game" ? "active" : null)
              }
            >
              PARTIDOS
            </div>
            <div className="separator-skills color-primary">|</div>
            <div
              onClick={() => this.toggleVideoType("skill")}
              className={
                "post-type-select-filter h4 txt-primary " +
                (this.state.video_type === "skill" ? "active" : null)
              }
            >
              HABILIDADES
            </div>
          </Row>

          <Row>
            <Col>
              {skillsFilter}
              <div className="d-flex justify-content-around full-width">
                <Button
                  className="action button"
                  color="white"
                  onClick={() => {
                    this.clearFilter();
                  }}
                  size="lg"
                >
                  CANCELAR
                </Button>
                <Button
                  className="action button"
                  color="primary"
                  onClick={() => {
                    this.filter();
                    if (this.props.is_modal) {
                      this.props.toggleModal();
                    }
                  }}
                  size="lg"
                >
                  FILTRAR
                </Button>
              </div>
            </Col>
          </Row>
        </Card>
      </>
    );
  }
}

export default PostFilter;
