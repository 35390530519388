/*eslint-disable*/
import React from "react";
import { NavLink as NavLinkRRD, Link } from "react-router-dom";
import { PropTypes } from "prop-types";
import { Navbar, NavItem, NavLink, Nav, Container } from "reactstrap";

import userHelpers from "./../../helpers/user";

var ps;

/**
 * Sidebar Class.
 * Main Sidebar
 * @constructor
 */
class Sidebar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      collapseOpen: false,
    };
    this.activeRoute.bind(this);
  }

  /**
   * Upon mounting.
   * Gets user type & username
   * @method
   */
  async componentDidMount() {
    var user_type = await userHelpers.getUserType();
    var username = await userHelpers.getUsername(
      localStorage.getItem("userUndiscovered")
    );
    this.setState({
      user_type: user_type,
      username: username,
    });
  }

  // verifies if routeName is the one active (in browser input)
  activeRoute(routeName) {
    return this.props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
  }
  // toggles collapse between opened and closed (true/false)
  toggleCollapse = () => {
    this.setState({
      collapseOpen: !this.state.collapseOpen,
    });
  };
  // closes the collapse
  closeCollapse = () => {
    this.setState({
      collapseOpen: false,
    });
  };
  // creates the links that appear in the left menu / Sidebar
  createLinks = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "") {
        if (prop.name === "Add") {
          return (
            <NavItem key={key}>
              <NavLink
                to={prop.layout + prop.path}
                tag={NavLinkRRD}
                onClick={this.closeCollapse}
                className="add-post-button"
                activeClassName="active"
              >
                <div className={prop.icon} />
              </NavLink>
            </NavItem>
          );
        } else if (prop.name === "Profile") {
          return (
            <NavItem key={key}>
              <NavLink
                to={prop.layout + "/perfil/" + this.state.username}
                tag={NavLinkRRD}
                onClick={this.closeCollapse}
                activeClassName="active"
              >
                <div className={prop.icon} />
                {/* {prop.name} */}
              </NavLink>
            </NavItem>
          );
        } else {
          return (
            <NavItem key={key}>
              <NavLink
                to={prop.layout + prop.path}
                tag={NavLinkRRD}
                onClick={this.closeCollapse}
                activeClassName="active"
              >
                <div className={prop.icon} />
                {/* {prop.name} */}
              </NavLink>
            </NavItem>
          );
        }
      } else {
        return null;
      }
    });
  };

  /**
   * Renders the SideBar component.
   * @see SideBar Component
   */
  render() {
    const { routes, logo } = this.props;
    let navbarBrandProps;
    if (logo && logo.innerLink) {
      navbarBrandProps = {
        to: logo.innerLink,
        tag: Link,
      };
    } else if (logo && logo.outterLink) {
      navbarBrandProps = {
        href: logo.outterLink,
        target: "_blank",
      };
    }
    return (
      <Navbar
        className="navbar-vertical fixed-left navbar-light"
        expand="md"
        id="sidenav-main"
        style={{zIndex:10}}
      >
        <Container fluid>
          {/* Navigation */}
          <Nav navbar>{this.createLinks(routes)}</Nav>
        </Container>
      </Navbar>
    );
  }
}

Sidebar.defaultProps = {
  routes: [{}],
};

Sidebar.propTypes = {
  // links that will be displayed inside the component
  routes: PropTypes.arrayOf(PropTypes.object),
  logo: PropTypes.shape({
    // innerLink is for links that will direct the user within the app
    // it will be rendered as <Link to="...">...</Link> tag
    innerLink: PropTypes.string,
    // outterLink is for links that will direct the user outside the app
    // it will be rendered as simple <a href="...">...</a> tag
    outterLink: PropTypes.string,
    // the image src of the logo
    imgSrc: PropTypes.string.isRequired,
    // the alt for the img
    imgAlt: PropTypes.string.isRequired,
  }),
};

export default Sidebar;
