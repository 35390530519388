import React from "react";
import { Container, Card, Col } from "reactstrap";

/**
 * Nosotros Class.
 * Main Nosotros component view.
 * @constructor
 */
class Nosotros extends React.Component {
  
  /**
   * Renders the Nosotros component.
   * @see Nosotros Component
   */
  render() {
    return (
      <>
        {/* Page content */}
        <Container fluid>
          <h2 className="ls-2  text-center">Acerca de UNDISCOVERED</h2>
          <h3 className="text-center mt-6 mb-2 color-secondary">LA PRÓXIMA GENERACIÓN DE FÚTBOL</h3>
          <Col md={{size:6, offset:3}} className="no-padding">
          <p className="text-center">
            Undiscovered es una plataforma digital enfocada en el descubrimiento
            de talentos futbolísticos a través de nuevas tecnologías y con la
            ayuda de los fans. Queremos revolucionar la forma como los talentos
            son descubiertos y ayudar a realizar el sueño de los jóvenes de ser
            profesionales. <br /><br />
            Creemos que todos los jóvenes deben tener las mismas oportunidades
            de sobresalir independiente del lugar y las condiciones. Con la
            evolución tecnológica creemos que podemos alcanzar esta visión.
          </p>
          </Col>
          <h3 className="text-center mt-6 mt-2 color-secondary">CÓMO FUNCIONA</h3>
          <div className="d-flex justify-content-center mb-6 change-mobile-direction">
          <Card className="p-2 text-center shadow m-2">
              <h3 className="color-primary">JUGADOR</h3>
              <p className="info-text-md p-2">
                Crea tu perfil y sube videos con tus habilidades. ¡Muestra tu
                talento a clubes, agentes, ojeadores y fans
              </p>
            </Card>
            <Card className="p-2 text-center shadow m-2">
              <h3 className="color-primary">FAN</h3>
              <p className="info-text-md p-2">
                Únete a la App para empoderar tus jugadores favoritos o ayuda a
                los clubes a encontrar el próximo talento
              </p>
            </Card>
            <Card className="p-2 text-center shadow m-2">
              <h3 className="color-primary">OJEADORES, AGENTES Y CLUBES</h3>
              <p className="info-text-md p-2">
                Accede a un grupo más amplio de talento y filtra los perfiles de
                acuerdo con tus necesidades
              </p>
            </Card>
          </div>
          
        </Container>
      </>
    );
  }
}

export default Nosotros;
