import React from "react";
import { Button, Container, Row, Col, Input, Alert, Card } from "reactstrap";
import ReactPlayer from "react-player";
import utilitiesHelpers from "./../../helpers/utilities";
import validationHelpers from "./../../helpers/validation";
import FileUploader from "react-firebase-file-uploader";
import firebase from "./../../config/firebase.js";
var ffmpeg = require("ffmpeg");

/**
 * Add Post Class
 * @constructor
 */
class AddHostedVideo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dropdownOpen: false,
      video_published: false,
      physical_skills: {},
      active_physical_skills: 0,
      technical_skills: {},
      active_technical_skills: 0,
      type_skills: {},
      active_type_skills: 0,
      post_id: "",
      title: "",
      description: "",
      error_message: "",
      edit_post: false,
      post_video: "",
      isUploading: false,
      progress: 0,
      link: "",
      files: [],
      sizeError: false,
    };
    this.publishPost = this.publishPost.bind(this);
    this.handleVideoUpload = this.handleVideoUpload.bind(this);
  }

  /**
   * Upon mounting, check get username from id in localstorage.
   * Check if post is being edited or added for the first time.
   * @method
   */
  async componentDidMount() {
    this.setState({
      uid: this.props.uid,
      username: this.props.username,
      user_type: this.props.user.user_type,
      link: this.props.link,
      title: this.props.title,
      description: this.props.description,
      edit_post: this.props.edit_post,
      post_id: this.props.post_id,
      physical_skills: this.props.physical_skills,
      technical_skills: this.props.technical_skills,
      type_skills: this.props.type_skills,
    });

    if (!this.props.edit_post) {
      var post_id = await validationHelpers.addPost();
      this.setState({ post_id: post_id });
    }

    if (
      this.props.physical_skills[0] === undefined &&
      this.props.technical_skills[0] === undefined &&
      this.props.type_skills[0] === undefined
    ) {
      await this.getUtilities();
    }
  }

  /**
   * Gets the values from the skills utilities in db.
   * @method
   */
  async getUtilities() {
    const [
      physical_skills,
      technical_skills,
      type_skills,
    ] = await utilitiesHelpers.getUtilitiesSkills();
    this.setState({
      physical_skills: physical_skills,
      technical_skills: technical_skills,
      type_skills: type_skills,
    });

    console.log(type_skills);
  }

  handleVideoUpload = (path) => {
    try {
      var process = new ffmpeg(path);
      process.then(
        function (video) {
          console.log("The video is ready to be processed");
          // Video metadata
          console.log(video.metadata);
          // FFmpeg configuration
          console.log(video.info_configuration);
        },
        function (err) {
          console.log("Error: " + err);
        }
      );
    } catch (e) {
      console.log(e.code);
      console.log(e.msg);
    }
  };

  /**
   * Completes the post publishing or post update.
   * Saves data to db.
   * @method
   */
  async publishPost() {
    var validate = await validationHelpers.validatePostData(
      this.state.link,
      this.state.title,
      this.state.description,
      this.state.physical_skills,
      this.state.technical_skills,
      this.state.type_skills,
      this.props.user.user_type,
      this.props.user.user_type !== "agent"
    );

    if (validate) {
      this.setState({
        error_message: validate,
      });
      return;
    }
    if (this.state.title !== "" && this.state.description !== "") {
      try {
        await validationHelpers.editPostSkill(
          this.state.post_id,
          this.state.link,
          this.state.title,
          this.state.description,
          this.state.physical_skills,
          this.state.technical_skills,
          this.state.type_skills
        );
        this.setState({ video_published: true });
        return true;
      } catch (err) {
        console.log(err);
        this.setState({
          error_message: "Ocurrió un error, favor de intentar más tarde",
        });
        return false;
      }
    } else {
      this.setState({ error_message: "Campos Inválidos." });
    }
  }
  /**
   * Basic change handle for inputs
   * @method
   * @param e Element firing the function
   */
  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value, error_message: "" });
  }

  /**
   * Change skills between the main utilities imported and the skills selected during post upload/edit.
   * @method
   * @param skill_type  Skill/Game type
   * @param index Index of selected value
   */
  handleSkillChange(skill_type, index) {
    var temp = { ...this.state[skill_type] };
    var active_type = "active_" + skill_type;
    var count = 0;
    temp[index].active ? (count = -1) : (count = 1);

    if (this.state[active_type] < 3) {
      temp[index].active = !temp[index].active;
      this.setState({
        [skill_type]: temp,
        [active_type]: this.state[active_type] + count,
      });
    } else {
      if (count < 0) {
        temp[index].active = !temp[index].active;
        this.setState({
          [skill_type]: temp,
          [active_type]: this.state[active_type] + count,
        });
      }
    }
  }

  handleUploadStart = () => {
    this.setState({ isUploading: true, progress: 0 });
  };
  handleProgress = (progress) => this.setState({ progress });
  handleUploadError = (error) => {
    this.setState({ isUploading: false });
    console.error(error);
  };
  handleUploadSuccess = (filename) => {
    this.setState({ post_video: filename, progress: 100, isUploading: false });
    firebase
      .storage()
      .ref(`users/${this.state.uid}/posts`)
      .child(filename)
      .getDownloadURL()
      .then((url) => {
        this.setState({ link: url });
        this.handleVideoUpload(url);
      });
  };

  onChangeHandler = (event) => {
    this.setState({ durationError: false });
    const {
      target: { files },
    } = event;
    const filesToStore = [];
    console.log(files);

    if (files[0].size > 500000000) {
      this.setState({
        error_message: "Intenta subir un video de menos de 500 MB",
      });
      return;
    } else if (
      this.state.error_message === "Intenta subir un video de menos de 500 MB"
    ) {
      this.setState({ error_message: "" });
    }

    filesToStore.push(files[0]);

    this.setState({ files: filesToStore });
    console.log("HANDLING END");
    this.startUploadManually(filesToStore);
  };

  startUploadManually = (files) => {
    console.log(typeof files);
    console.log(files);
    files.forEach((file) => {
      console.log("loading", file);
      this.fileUploader.startUpload(file);
    });
  };

  /**
   * Renders the Add Video component with state options.
   * @see Add Video Component
   */
  render() {
    let skillsFilter = (
      <Row className="justify-content-center pt-0 mt-2 pb-0">
        <Col xs="12" className="text-center mb-2">
          <h5 className="full-width text-center mb-0">
            Elegir habilidades físicas
          </h5>
          <p className="full-width info-text-sm mt-0 mb-1 text-center">
            Máximo 3
          </p>
          {Object.values(this.state.physical_skills).map((skill, i) => {
            return (
              <Button
                className="button small mb-2"
                color={skill.active ? "black" : "white"}
                size="sm"
                key={i}
                name={skill.value}
                onClick={() => {
                  this.handleSkillChange("physical_skills", i);
                }}
              >
                {skill.value}
              </Button>
            );
          })}
          <h5 className="full-width text-center mt-1 mb-0">
            Elegir habilidades técnicas
          </h5>
          <p className="full-width info-text-sm mt-0 mb-1 text-center">
            Máximo 3
          </p>
          {Object.values(this.state.technical_skills).map((skill, i) => {
            return (
              <Button
                className="button small mb-2"
                color={skill.active ? "black" : "white"}
                size="sm"
                key={i}
                name={skill.value}
                onClick={() => {
                  this.handleSkillChange("technical_skills", i);
                }}
              >
                {skill.value}
              </Button>
            );
          })}

          <h5 className="full-width text-center mt-1 mb-0">
            Elegir tipo de video
          </h5>
          <p className="full-width info-text-sm mt-0 mb-1 text-center">
            Máximo 3
          </p>
          {Object.values(this.state.type_skills).map((skill, i) => {
            return (
              <Button
                className="button small mb-2"
                color={skill.active ? "black" : "white"}
                size="sm"
                key={i}
                name={skill.value}
                onClick={() => {
                  this.handleSkillChange("type_skills", i);
                }}
              >
                {skill.value}
              </Button>
            );
          })}
        </Col>
      </Row>
    );

    if (this.state.video_published) {
      return (
        <Container className="mt-0 py-6 my-8" fluid>
          <Row>
            <Col className="mb-3 offset-lg-3" lg="6">
              <div className="h4 ls-2 my-5 text-center color-primary">
                ¡VIDEO PUBLICADO!
              </div>
              <div className="d-flex justify-content-center full-width mb-3 text-center">
                <Col>
                  <Button
                    className="action button m-1"
                    color="white"
                    onClick={() => {
                      window.location.reload();
                    }}
                    size="lg"
                  >
                    PUBLICAR OTRO POST
                  </Button>
                  <Button
                    className="action button m-1"
                    color="primary"
                    onClick={() => {
                      this.props.history.push("/perfil/" + this.state.username);
                    }}
                    size="lg"
                  >
                    IR A PERFIL
                  </Button>
                </Col>
              </div>
            </Col>
          </Row>
        </Container>
      );
    }

    return (
      <>
        <ReactPlayer
          url={this.state.link}
          className="youtube-video border-radius-10"
          playing
          loop
          controls={false}
          volume={1}
          playsinline
        />
        {this.state.isUploading ? (
          <p className="loading_video">Cargando...</p>
        ) : null}
        <div className="d-flex justify-content-around full-width mb-2 mt-2">
          {this.state.post_id ? (
            <label className="edit-photo link">
              Agregar Video
              <FileUploader
                hidden
                accept="video/*"
                name="video"
                filename={this.state.post_id}
                storageRef={firebase
                  .storage()
                  .ref(`users/${this.state.uid}/posts/`)}
                metadata={{ cacheControl: "max-age=3600" }}
                maxWidth="400"
                maxHeight="400"
                onUploadError={this.handleUploadError}
                onUploadSuccess={this.handleUploadSuccess}
                onProgress={this.handleProgress}
                onUploadStart={this.handleUploadStart}
                onChange={this.onChangeHandler}
                ref={(instance) => (this.fileUploader = instance)}
              />
            </label>
          ) : null}
        </div>

        <Input
          className="form-control-alternative mb-2"
          type="text"
          value={this.state.title || ""}
          maxLength="60"
          name="title"
          onChange={(e) => this.handleChange(e)}
          placeholder="Título del Video"
        />
        <Input
          className="form-control-alternative textarea mb-2"
          placeholder="Descripción del video ..."
          rows="3"
          type="textarea"
          maxLength="280"
          value={this.state.description || ""}
          name="description"
          onChange={(e) => this.handleChange(e)}
        />
        <Card className="bg-primary shadow px-2">{skillsFilter}</Card>

        <Alert
          className="mt-2"
          color="warning"
          hidden={this.state.error_message === "" ? true : false}
        >
          <strong>Error:</strong> {this.state.error_message}
        </Alert>
        <div className="d-flex justify-content-around full-width mb-3 mt-3">
          {this.state.link !== "" ? (
            <Button
              className="action button"
              color="black"
              onClick={() => {
                this.publishPost();
              }}
              size="lg"
            >
              PUBLICAR
            </Button>
          ) : null}
        </div>
      </>
    );
  }
}

export default AddHostedVideo;
