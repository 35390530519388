import React from "react";
import { Event } from "../../components/Analytics";
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  Alert,
  Col,
} from "reactstrap";
import validationHelpers from "./../../helpers/validation";
import validator from "validator";

/**
 * Login Class.
 * Main Login component view.
 * @constructor
 */
class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      error_message: "",
      email_recovery: "",
      password_recovery: false,
      recovery_message: "",
      valid_recovery: false,
      error_array: [],
    };

    this.handleChange = this.handleChange.bind(this);
    this.login = this.login.bind(this);
    this.forgotPassword = this.forgotPassword.bind(this);
  }

  /**
   * Basic change handle for inputs
   * @method
   * @param e Element firing the function
   */
  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  /**
   * Logs in user with firebase and redirects to correct endopoint
   * @method
   */
  async login(e) {
    e.preventDefault();
    Event("AUTHENTICATION", "Login", "Login Page");
    var [res, errors] = await validationHelpers.loginUser(
      this.state.email,
      this.state.password
    );

    if (res.id) {
      console.log(res.user.id);
      this.props.history.push("../inicio");
      this.setState({
        error_array: errors,
      });
    } else {
      this.setState({
        error_array: errors,
      });
    }
  }

  /**
   * Sends email with password reset.
   * @method
   */
  async forgotPassword(e) {
    Event("AUTHENTICATION", "Forgot Password", "Login Page");
    e.preventDefault();
    var res = await validationHelpers.forgotPassword(this.state.email_recovery);
    if (res !== true) {
      var error_array = [];
      if (!validator.isEmail(this.state.email) || this.state.email === "") {
        error_array.email_recovery = "Correo inválido";
      }

      this.setState({
        recovery_message: res,
        error_array: error_array,
      });
    } else {
      this.setState({
        recovery_message: "",
        valid_recovery: true,
        error_array: [],
      });
    }
  }

  /**
   * Renders the Login component.
   * @see Login Component
   */
  render() {
    let error_message;

    if (Object.keys(this.state.error_array).length > 0) {
      error_message = Object.values(this.state.error_array).map((error, i) => {
        return (
          <p className="fs-12 mb-0" key={i}>
            {error}
          </p>
        );
      });
    }

    let form_type;
    if (this.state.password_recovery) {
      let valid_recovery;
      if (!this.state.valid_recovery) {
        valid_recovery = (
          <Form role="form">
            <FormGroup className="mb-3">
              <Input
                className={
                  "form-control-alternative" +
                  (this.state.error_array.email_recovery ? " error-input" : "")
                }
                type="text"
                name="email_recovery"
                value={this.state.email_recovery}
                placeholder="Correo"
                onChange={this.handleChange}
              />
            </FormGroup>
            <Alert
              color="warning"
              hidden={this.state.recovery_message === "" ? true : false}
            >
              <strong>Error:</strong> {this.state.recovery_message}
            </Alert>
            <div className="text-center pb-0 m-0">
              <Button
                className="my-1 txt-black min-width-200"
                color="primary"
                type="button"
                onClick={this.forgotPassword}
              >
                ENVIAR
              </Button>
            </div>
            <div className="text-center pb-0 m-0">
              <Button
                className="my-1 txt-black min-width-200"
                color="white"
                type="button"
                onClick={() => {
                  this.setState({ password_recovery: false });
                }}
              >
                CANCELAR
              </Button>
            </div>
            <div
              className="text-center mt-1"
              hidden={!this.state.valid_recovery ? true : false}
            >
             Te hemos enviando un link de recuperación a tu correo (si no lo
              encuentras en tu bandeja de entrada por favor verifica en spam),
              por favor abre el correo y da click al link para continuar. Una
              vez hayas cambiado tu contraseña puedes recargar esta página para
              continuar.
            </div>
          </Form>
        );
      } else {
        valid_recovery = (
          <>
            <p className="text-center">
              Te hemos enviando un link de recuperación a tu correo (si no lo
              encuentras en tu bandeja de entrada por favor verifica en spam),
              por favor abre el correo y da click al link para continuar. Una
              vez hayas cambiado tu contraseña puedes recargar esta página para
              continuar.
            </p>
            <Form role="form">
              <div className="text-center pb-0 m-0">
                <Button
                  className="my-1 txt-black min-width-200"
                  color="primary"
                  type="button"
                  onClick={() => {
                    this.setState({
                      password_recovery: false,
                      valid_recovery: false,
                      recovery_message: "",
                    });
                  }}
                >
                  INICIAR SESIÓN
                </Button>
              </div>
            </Form>
          </>
        );
      }
      form_type = (
        <Card className="bg-white shadow border-0">
          <CardBody className="px-lg-5 py-lg-5">
            <div className="h3 ls-2 text-center">RECUPERA TU CUENTA</div>
            {valid_recovery}
          </CardBody>
        </Card>
      );
    } else {
      form_type = (
        <Card className="bg-white shadow border-0">
          <CardBody className="px-lg-5 py-lg-5">
            <div className="h3 ls-2 text-center">INICIAR SESIÓN</div>
            <Form role="form">
              <FormGroup className="mb-2">
                <Input
                  className={
                    "form-control-alternative" +
                    (this.state.error_array.email ? " error-input" : "")
                  }
                  type="text"
                  name="email"
                  value={this.state.email}
                  placeholder="Correo"
                  onChange={this.handleChange}
                />
              </FormGroup>
              <FormGroup className="mb-2">
                <Input
                  className={
                    "form-control-alternative" +
                    (this.state.error_array.password ? " error-input" : "")
                  }
                  type="password"
                  name="password"
                  placeholder="Contraseña"
                  value={this.state.password}
                  onChange={this.handleChange}
                />
              </FormGroup>
              <Alert
                color="warning"
                hidden={
                  Object.keys(this.state.error_array).length === 0
                    ? true
                    : false
                }
              >
                {error_message}
              </Alert>
              <div className="text-center pb-0 m-0">
                <Button
                  className="my-1 txt-black min-width-200"
                  color="primary"
                  type="button"
                  onClick={this.login}
                >
                  INICIAR SESIÓN
                </Button>
              </div>
              <div className="text-center pb-0 m-0">
                <Button
                  className="my-1 txt-black min-width-200"
                  color="white"
                  type="button"
                  onClick={() => {
                    this.props.history.push("registro");
                  }}
                >
                  CREAR CUENTA
                </Button>
              </div>
              <div className="text-center mt-2 mb-0">
                <p
                  className="link mb-0"
                  onClick={() => {
                    if (this.state.email !== "") {
                      this.setState({ email_recovery: this.state.email });
                    }
                    this.setState({ password_recovery: true });
                  }}
                >
                  ¿Olvidaste tu contraseña?
                </p>
              </div>
            </Form>
          </CardBody>
        </Card>
      );
    }
    return (
      <>
        <Col lg="5" md="7">
          <div className="full-width text-center justify-content-center mb-4 mt-6">
            <a
              href="https://www.undiscovered.app/"
              target="_blank"
              rel="noopener noreferrer"
              className="h4 mb-0 d-lg-inline-block"
            >
              <img
                alt="..."
                className="main-logo"
                src={require("assets/img/brand/Undiscovered-logo-black.png")}
              />
            </a>
          </div>
          {form_type}
        </Col>
      </>
    );
  }
}

export default Login;
