
/**
* Check during render if the post skill is contained in the active filtered skills
* @method
* @param activePhysicalSkills Filtered active physical skills
* @param activeTechnicalSkills Filtered active technical skills
* @param activeTypeSkills Filtered active type skills
*/
export function skillContained(
 activePhysicalSkills,
 activeTechnicalSkills,
 activeTypeSkills
) {
 const arrayPhysical = Object.values(activePhysicalSkills)
   .filter((s) => s.active)
   .map((s) => s.value);
 const arrayTechnical = Object.values(activeTechnicalSkills)
   .filter((s) => s.active)
   .map((s) => s.value);
 const arrayType = Object.values(activeTypeSkills)
   .filter((s) => s.active)
   .map((s) => s.value);

 const stateActivePhysical = Object.values(this.state.physical_skills)
   .filter((s) => s.active)
   .map((s) => s.value);
 const stateActiveTechnical = Object.values(this.state.technical_skills)
   .filter((s) => s.active)
   .map((s) => s.value);
 const stateActiveType = Object.values(this.state.type_skills)
   .filter((s) => s.active)
   .map((s) => s.value);

 return stateActivePhysical.reduce(
   (t, s) => t && arrayPhysical.includes(s),
   true
 ) &&
   stateActiveTechnical.reduce(
     (t, s) => t && arrayTechnical.includes(s),
     true
   ) &&
   stateActiveType.reduce(
     (t, s) => t && arrayType.includes(s),
     true
   );
}