import React from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  Dropdown,
  DropdownToggle,
  Modal,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Input,
  Alert,
} from "reactstrap";
import * as _ from "lodash";
import Video from "../components/Videos/Video";
import userHelpers from "../helpers/user";
import utilitiesHelpers from "../helpers/utilities";
import EditProfile from "components/Modules/EditProfile";
import PostFilter from "../components/Filters/PostFilter";
import emailjs from "emailjs-com";
import {skillContained} from '../helpers/skills';

/**
 * Profile Class.
 * Main profile view.
 * @constructor
 */
class Profile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      editProfile: false,
      video_type: "all",
      posts: [],
      dropdownOpen: false,
      user_toggle: true,
      technical_skills: {},
      physical_skills: {},
      type_skills: {},
      loading: true,
      discoveries: [],
      discovering: [],
      modal: false,
      reportModal: false,
      reason: "",
      error_message: "",
      user_report_response: "",
      blocked_user: false,
      user: {
        id: "",
        agent: "",
        alt_position: "",
        birthday: "",
        city: "",
        contract: "",
        country: "",
        description: "",
        dominant_side: "",
        email: "",
        fav_team: "",
        gender: "",
        height: "",
        name: "",
        position: "",
        time1000: "",
        time30: "",
        tutor_id: "",
        tutor_name: "",
        tutor_relationship: "",
        user_type: "",
        username: "",
        weight: "",
      },
      own_profile: false,
      discovered: false,
      error404: false,
    };
    this.toggleModal = this.toggleModal.bind(this);
    this.toggleDropdown = this.toggleDropdown.bind(this);
    this.toggleEditProfile = this.toggleEditProfile.bind(this);
    this.reloadProfile = this.reloadProfile.bind(this);
    this.toggleVideoType = this.toggleVideoType.bind(this);
    this.getUtilities = this.getUtilities.bind(this);
    this.setSkills = this.setSkills.bind(this);
    this.skillContained = skillContained.bind(this);
    this.discoverUser = this.discoverUser.bind(this);
    this.openModal = this.openModal.bind(this);
    this.reportUser = this.reportUser.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  /**
   * Upon mounting.
   * Get profile data.
   * Get utilities from database.
   * @method
   */
  async componentDidMount() {
    await this.reloadProfile();
    await this.getUtilities();
    this.setState({ loading: false });
  }

  /**
   * Gets discovered users from db
   * @method
   */
  async componentDidUpdate(nextProps) {
    if (this.props.match.params.username !== nextProps.match.params.username) {
      await this.reloadProfile();
    }
  }

  /**
   * Gets discovered users from db
   * @method
   */
  async getDiscoveriesDiscovering() {
    var user = await userHelpers.getUserByUrl(this.props.match.params.username);
    var [
      discoveries,
      discovering,
    ] = await userHelpers.getDiscoveriesDiscovering(user.id);
    this.setState({
      discovering: discovering,
      discoveries: discoveries,
    });
  }

  /**
   * Gets physical/technical skills utilities from db
   * @method
   */
  async getUtilities() {
    const [
      physical_skills,
      technical_skills,
      type_skills,
    ] = await utilitiesHelpers.getUtilitiesSkills();
    this.setState({
      physical_skills: physical_skills,
      technical_skills: technical_skills,
      type_skills: type_skills,
      filtered_skills: false,
    });
  }

  /**
   * Loads all necessary data for profile view
   * @method
   */
  async reloadProfile() {
    var user = await userHelpers.getUserByUrl(this.props.match.params.username);
    if (user === undefined) {
      this.setState({
        error404: true,
        loading: false,
      });
    } else {
      if (user.blocked === true) {
        this.setState({
          blocked_user: true,
        });
      } else {
        if (user.id === localStorage.getItem("userUndiscovered")) {
          this.setState({
            own_profile: true,
            // editProfile: true,
            user: user,
          });
        } else {
          var u = await userHelpers.getUserData(
            localStorage.getItem("userUndiscovered")
          );
          this.setState({
            own_profile: false,
            user: user,
            own_user: u,
          });
        }
        if (user.user_type === "agent") {
          this.setState({ user_toggle: false });
        }
        var posts = await userHelpers.getUserPosts(user.id);
        console.log(posts);
        this.setState({
          posts: posts,
        });

        await this.getDiscoveriesDiscovering();
        await this.checkIfDiscovered();
      }
    }
  }

  /**
   * Checks what users are discovered and which ones are not
   * @method
   */
  async checkIfDiscovered() {
    const discovered = await userHelpers.getDiscoveredUsers();
    for (let i = 0; i < discovered.length; i++) {
      if (this.state.user.id === discovered[i]) {
        this.setState({ discovered: true });
      }
    }
  }

  /**
   * Change user to discovered/undiscovered
   * @method
   */
  async discoverUser() {
    if (this.state.discovered === false) {
      await userHelpers.discoverUser(this.state.user.id);
    } else {
      await userHelpers.undiscoverUser(this.state.user.id);
    }
    this.setState({
      discovered: !this.state.discovered,
    });
  }

  /**
   * Basic toggle for dropdown
   * @method
   */
  toggleDropdown() {
    this.setState({ dropdownOpen: !this.state.dropdownOpen });
  }

  /**
   * Basic toggle for editing profile
   * @method
   */
  toggleEditProfile() {
    this.setState({ editProfile: !this.state.editProfile });
  }

  /**
   * Basic toggle for modal
   * @method
   */
  toggleModal() {
    this.setState({ modal: !this.state.modal });
  }

  /**
   * Toggle video type.
   * @method
   * @param type Skill/Game video type
   */
  toggleVideoType(type) {
    this.setState({ video_type: type });
  }

  /**
   * Set the filtered skills from Filter Posts component
   * @method
   * @param video_type Skill/Game video type
   * @param physical_skills Filtered active pysical skills
   * @param technical_skills Filtered active technical skills
   * @param filtered Boolean flag to see if filter must be applied
   */
  async setSkills(
    video_type,
    physical_skills,
    technical_skills,
    type_skills,
    filtered
  ) {
    this.setState({
      video_type: video_type,
      physical_skills: physical_skills,
      technical_skills: technical_skills,
      type_skills: type_skills,
      filtered_skills: filtered,
    });
  }

  // /**
  //  * Check during render if the post skill is contained in the active filtered skills
  //  * @method
  //  * @param activePhysicalSkills Filtered active physical skills
  //  * @param activeTechnicalSkills Filtered active technical skills
  //  */
  // skillContained(activePhysicalSkills, activeTechnicalSkills) {
  //   var contained = false;
  //   var count = 0;
  //   for (let i = 0; i < Object.values(activePhysicalSkills).length; i++) {
  //     if (this.state.physical_skills[i].active) {
  //       if (activePhysicalSkills[i].active) {
  //         contained = true;
  //       }
  //     } else {
  //       count++;
  //     }
  //   }

  //   for (let i = 0; i < Object.values(activeTechnicalSkills).length; i++) {
  //     if (this.state.technical_skills[i].active) {
  //       if (activeTechnicalSkills[i].active) {
  //         contained = true;
  //       }
  //     } else {
  //       count++;
  //     }
  //   }
  //   var sum =
  //     Object.values(activePhysicalSkills).length +
  //     Object.values(activeTechnicalSkills).length;
  //   if (count === sum) {
  //     contained = true;
  //   }
  //   return contained;
  // }

  async reportUser() {
    if (this.state.reason === "") {
      this.setState({
        error_message: "Es necesario poner una razón para el reporte.",
      });
    } else {
      var success = await userHelpers.reportUser(
        this.state.user.id,
        this.state.reason
      );
      if (success) {
        try {
          emailjs
            .sendForm(
              "service_town7n5",
              "template_ib814nv",
              "#reportForm",
              "user_5Ye8HPD0sH2KC7wy4mfeE"
            )
            .then(
              (result) => {
                this.setState({
                  user_report_response: "El usuario fue reportado.",
                });
                console.log(result);
              },
              (error) => {
                console.log(error);
                this.setState({
                  user_report_response:
                    "No pudimos reportar al usuario en este momento, por favor intenta más tarde.",
                });
              }
            );
        } catch (e) {
          console.log(e);
        }
      } else {
        this.setState({
          user_report_response:
            "No pudimos reportar al usuario en este momento, por favor intenta más tarde.",
        });
      }
    }
  }

  /**
   * Basic change handle for inputs
   * @method
   * @param e Element firing the function
   */
  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  /**
   * Basig modal toggle.
   * Resets modal values.
   * @method
   */
  openModal() {
    this.setState({
      reportModal: !this.state.reportModal,
      reason: "",
      error_message: "",
      user_report_response: "",
    });
  }

  /**
   * Renders the Profile component.
   * @see Profile Component
   */
  render() {
    if (this.state.loading) {
      return (
        <div className="preloader-wrapper">
          <embed
            className="preloader-icon"
            src={require("assets/img/brand/preloader.svg")}
          />
        </div>
      );
    } else {
      if (this.state.error404) {
        return (
          <h4 className="full-width text-center mt-4">
            Lo sentimos. No pudimos encontrar este perfil.
          </h4>
        );
      } else if (this.state.blocked_user) {
        return (
          <h4 className="full-width text-center mt-4">
            Lo sentimos. Este usuario se encuentra bloqueado.
          </h4>
        );
      } else {
        let user_type;
        let send_message;
        let report_user;
        let report_modal = (
          <Modal
            isOpen={this.state.reportModal}
            toggle={() => this.openModal()}
            className="mt-8"
          >
            <ModalBody className="text-center mt-2 pb-0">
              {this.state.user_report_response === "" ? (
                <>
                  <p className="mb-2">
                    ¿Estas seguro de que quieres reportar a este usuario?
                  </p>
                  <Form role="form" id="reportForm">
                    <FormGroup className="mb-2">
                      <Input
                        className="form-control-alternative"
                        rows="3"
                        type="textarea"
                        name="reason"
                        value={this.state.reason}
                        placeholder="Razón del reporte..."
                        onChange={this.handleChange}
                      />

                      <Input
                        hidden
                        type="text"
                        name="report_user_id"
                        value={this.state.user.id}
                        onChange={() => {}}
                      />
                      <Input
                        hidden
                        type="text"
                        name="report_video_id"
                        value="N/A"
                        onChange={() => {}}
                      />
                    </FormGroup>
                    <Alert
                      color="warning"
                      hidden={this.state.error_message === "" ? true : false}
                    >
                      <strong>Error:</strong> {this.state.error_message}
                    </Alert>
                  </Form>
                </>
              ) : (
                <p className="mb-2">{this.state.user_report_response}</p>
              )}
            </ModalBody>
            <ModalFooter className="d-flex justify-content-center">
              <Button color="primary" onClick={() => this.openModal()}>
                {this.state.user_report_response === ""
                  ? "CANCELAR"
                  : "TERMINAR"}
              </Button>
              {this.state.user_report_response === "" ? (
                <Button color="white" onClick={() => this.reportUser()}>
                  REPORTAR
                </Button>
              ) : null}
            </ModalFooter>
          </Modal>
        );

        var userTeam = "";
        if (this.state.user.user_type === "player") {
          if (
            this.state.user.current_club !== "" &&
            this.state.user.current_club
          ) {
            userTeam = (
              <h4 className="mt-1">
                Equipo/Club/Academia Actual: {this.state.user.current_club}
              </h4>
            );
          }
        } else {
          if (this.state.user.fav_team !== "" && this.state.user.fav_team) {
            userTeam = (
              <h4 className="mt-1">
                Equipo Favorito: {this.state.user.fav_team}
              </h4>
            );
          }
        }

        if (this.state.user.username !== "undiscovered") {
          if (this.state.user.user_type === "player") {
            user_type = (
              <h4 className="mb-0 color-primary" color="primary">
                JUGADOR
              </h4>
            );
          } else if (this.state.user.user_type === "fan") {
            user_type = (
              <h4 className="mb-0 color-primary" color="primary">
                FAN
              </h4>
            );
          } else if (this.state.user.user_type === "agent") {
            user_type = (
              <h4 className="mb-0 color-primary" color="primary">
                AGENTE
              </h4>
            );
          }
        }
        if (!this.state.own_profile) {
          send_message = (
            <Button
              className="discovered button mr-0"
              color="primary"
              href={
                "mailto:contacto@undiscovered.app?subject=Contacto UNDISCOVERED&body=ID usuario a contactar: " +
                this.state.user.id +
                "%0D%0A Username usuario a contactar: " +
                this.state.user.username +
                "%0D%0A%0D%0A ID usuario contactando: " +
                this.state.own_user.id +
                "%0D%0A Correo usuario contactando: " +
                this.state.own_user.email +
                "%0D%0A Username usuario contactando: " +
                this.state.own_user.username +
                "%0D%0A%0D%0A%0D%0A ******No borrar información anterior. Escribir después de la línea.******%0D%0A________________________________________________________%0D%0A"
              }
              size="sm"
            >
              ENVIAR CORREO
            </Button>
          );

          report_user = (
            <Button
              className="discovered button mr-0"
              color="white"
              size="sm"
              onClick={() => this.openModal()}
            >
              REPORTAR USUARIO
            </Button>
          );
        }
        if (this.state.editProfile) {
          return (
            <Container className="mt-3" fluid>
              <Row>
                <Col
                  className="order-xl-1 mb-0 mb-xl-0 text-center"
                  md={{ size: 8, offset: 2 }}
                  lg={{ size: 6, offset: 3 }}
                >
                  <EditProfile
                    {...this.props}
                    user={this.state.user}
                    user_toggle={this.state.user_toggle}
                    toggleEditProfile={() => this.toggleEditProfile()}
                    reloadProfile={() => this.reloadProfile()}
                  />
                </Col>
              </Row>
            </Container>
          );
        } else {
          if (this.state.user.user_type !== "player") {
            return (
              <>
                {/* Page content */}
                <Container className="mt-6" fluid>
                  {!this.state.own_profile ? (
                    <div
                      className="back-button"
                      onClick={() => this.props.history.goBack()}
                    >
                      <img
                        alt="Undiscovered Atrás"
                        src={require("assets/img/icons/back.svg")}
                      />
                    </div>
                  ) : null}
                  <Row>
                    <Col className="order-xl-1 mb-3 mb-xl-0" lg="4">
                      <Card className="card-profile shadow p-sticky overflow-visible">
                        <div className="justify-content-center">
                          <div className="username">
                            <div className="h4 text-center mt--1 mb-1">
                              {this.state.user.username}
                            </div>
                          </div>
                          <Col lg="12">
                            <div className="card-profile-image mt--5 mb-5 text-center">
                              <img
                                alt="..."
                                className="rounded-circle"
                                src={
                                  this.state.user.profile_picture_url !==
                                  undefined
                                    ? this.state.user.profile_picture_url
                                    : require("assets/img/brand/ProfilePlaceholder.png")
                                }
                              />
                            </div>
                          </Col>
                        </div>
                        <CardHeader className="text-center border-0 p-0 mt--6">
                          <div className="d-flex justify-content-center">
                            {this.state.own_profile ? (
                              <Button
                                className="discovered button"
                                color="primary"
                                href=""
                                onClick={() => {
                                  this.toggleEditProfile();
                                }}
                                size="sm"
                              >
                                EDITAR PERFIL
                              </Button>
                            ) : this.state.user.username !== "undiscovered" ? (
                              <Button
                                className="discovered button"
                                color={
                                  this.state.discovered ? "primary" : "white"
                                }
                                onClick={this.discoverUser}
                                size="sm"
                              >
                                {this.state.discovered
                                  ? "DISCOVERED"
                                  : "UNDISCOVERED"}
                              </Button>
                            ) : (
                              <div className="mt-4"></div>
                            )}
                          </div>
                        </CardHeader>
                        <CardBody className="pt-2 pb-3 px-3">
                          <div className="text-center">
                            {user_type}
                            <h3 className="mb-0">
                              {this.state.user.name
                                ? this.state.user.name.substr(0, 30) +
                                  (this.state.user.username !== "undiscovered"
                                    ? ", "
                                    : "")
                                : null}
                              {this.state.user.username !== "undiscovered" ? (
                                <span className="font-weight-light">
                                  {utilitiesHelpers.calcAge(
                                    this.state.user.birthday
                                  )}
                                </span>
                              ) : null}
                            </h3>
                            {this.state.user.username !== "undiscovered" &&
                            this.state.user.city ? (
                              <div className="h4 font-weight-300 lh-10">
                                {this.state.user.city},{" "}
                                {this.state.user.country}
                              </div>
                            ) : null}
                            {send_message}
                            {this.state.user.username !== "undiscovered" ? (
                              <>
                                {report_user}
                                {report_modal}
                              </>
                            ) : null}

                            {this.state.user.username !== "undiscovered" ? (
                              <Row>
                                <div className="col">
                                  <div className="card-profile-stats d-flex justify-content-center mt-lg-2 ">
                                    <div>
                                      <span className="heading">
                                        {this.state.posts.length}
                                      </span>
                                      <span className="description">
                                        Publicaciones
                                      </span>
                                    </div>
                                    <div
                                      className="link"
                                      onClick={() => {
                                        this.props.history.push(
                                          "/buscar?type=discoveries&username=" +
                                            this.state.user.username
                                        );
                                      }}
                                    >
                                      <span className="heading">
                                        {this.state.discoveries.length}
                                      </span>
                                      <span className="description">
                                        Discovered by
                                      </span>
                                    </div>
                                    <div
                                      className="link"
                                      onClick={() => {
                                        this.props.history.push(
                                          "/buscar?type=discovering&username=" +
                                            this.state.user.username
                                        );
                                      }}
                                    >
                                      <span className="heading">
                                        {this.state.discovering.length}
                                      </span>
                                      <span className="description">
                                        Discovering
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </Row>
                            ) : null}
                            <hr className="my-2" />
                            <p className="profile-description my-1">
                              {this.state.user.description}
                            </p>
                            {userTeam}
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col className="order-xl-1 m-0 mt-md--6 mt-lg--5" lg="8">
                      <Row className="px-3 no-mobile">
                        <PostFilter
                          toggleVideoType={this.toggleVideoType}
                          getUtilities={() => this.getUtilities()}
                          setSkills={this.setSkills}
                          physical_skills={this.state.physical_skills}
                          technical_skills={this.state.technical_skills}
                          type_skills={this.state.type_skills}
                        />
                      </Row>
                      <Row className="justify-content-center bg-primary pt-2 mt--1 pb-2 no-desktop">
                        <Dropdown
                          isOpen={false}
                          className="full-width hz-padded"
                          toggle={() => {
                            this.toggleModal();
                          }}
                        >
                          <DropdownToggle
                            caret
                            className="full-width btn-white mobile-bigger-dropdown"
                          >
                            Filtrar Posts
                          </DropdownToggle>
                        </Dropdown>
                      </Row>
                      <Modal
                        isOpen={this.state.modal}
                        // toggle={()=>this.toggleModal()}
                        className="posts-filter-modal"
                      >
                        <ModalBody className="p-0">
                          <PostFilter
                            toggleVideoType={this.toggleVideoType}
                            getUtilities={() => this.getUtilities()}
                            setSkills={this.setSkills}
                            physical_skills={this.state.physical_skills}
                            technical_skills={this.state.technical_skills}
                            type_skills={this.state.type_skills}
                            toggleModal={() => this.toggleModal()}
                            is_modal={true}
                          />
                        </ModalBody>
                      </Modal>

                      <Card className="card-profile shadow p-3 mt-2 pt-2 pb-2">
                        <Row className="justify-content-around">
                          {_.sortBy(this.state.posts, "dateCreated")
                            .reverse()
                            .map((post) => {
                              post.user = this.state.user;

                              if (
                                this.state.video_type === "all" ||
                                post.video_type === this.state.video_type
                              ) {
                                if (this.state.video_type === "skill") {
                                  if (post.video_type === "skill") {
                                    if (this.state.filtered_skills) {
                                      if (
                                        this.skillContained(
                                          post.physical_skills,
                                          post.technical_skills
                                        )
                                      ) {
                                        return (
                                          <Video
                                            {...this.props}
                                            // userDiscoveryUpdated={
                                            //   this.userDiscoveryUpdated
                                            // }
                                            key={
                                              post.id +
                                              ":" +
                                              post.user.discovered
                                            }
                                            showButton={
                                              localStorage.getItem(
                                                "userUndiscovered"
                                              ) === post.user.id
                                                ? false
                                                : true
                                            }
                                            showMenu={
                                              localStorage.getItem(
                                                "userUndiscovered"
                                              ) === post.user.id
                                                ? true
                                                : false
                                            }
                                            user={post.user}
                                            post={post}
                                          />
                                        );
                                      } else {
                                        return null;
                                      }
                                    } else {
                                      return (
                                        <Video
                                          {...this.props}
                                          // userDiscoveryUpdated={
                                          //   this.userDiscoveryUpdated
                                          // }
                                          key={
                                            post.id + ":" + post.user.discovered
                                          }
                                          showButton={
                                            localStorage.getItem(
                                              "userUndiscovered"
                                            ) === post.user.id
                                              ? false
                                              : true
                                          }
                                          showMenu={
                                            localStorage.getItem(
                                              "userUndiscovered"
                                            ) === post.user.id
                                              ? true
                                              : false
                                          }
                                          user={post.user}
                                          post={post}
                                        />
                                      );
                                    }
                                  } else {
                                    return null;
                                  }
                                } else if (this.state.video_type === "game") {
                                  if (post.video_type === "game") {
                                    return (
                                      <Video
                                        {...this.props}
                                        // userDiscoveryUpdated={
                                        //   this.userDiscoveryUpdated
                                        // }
                                        key={
                                          post.id + ":" + post.user.discovered
                                        }
                                        showButton={
                                          localStorage.getItem(
                                            "userUndiscovered"
                                          ) === post.user.id
                                            ? false
                                            : true
                                        }
                                        showMenu={
                                          localStorage.getItem(
                                            "userUndiscovered"
                                          ) === post.user.id
                                            ? true
                                            : false
                                        }
                                        user={post.user}
                                        post={post}
                                      />
                                    );
                                  } else {
                                    return null;
                                  }
                                } else if (this.state.video_type === "all") {
                                  return (
                                    <Video
                                      {...this.props}
                                      // userDiscoveryUpdated={
                                      //   this.userDiscoveryUpdated
                                      // }
                                      key={post.id + ":" + post.user.discovered}
                                      showButton={
                                        localStorage.getItem(
                                          "userUndiscovered"
                                        ) === post.user.id
                                          ? false
                                          : true
                                      }
                                      showMenu={
                                        localStorage.getItem(
                                          "userUndiscovered"
                                        ) === post.user.id
                                          ? true
                                          : false
                                      }
                                      user={post.user}
                                      post={post}
                                    />
                                  );
                                } else {
                                  return null;
                                }
                              } else {
                                return null;
                              }
                            })}
                          {this.state.posts[0] ? (
                            <div className="h4 ls-2">
                              No hay más posts para mostrar.
                            </div>
                          ) : (
                            <div className="h4 ls-2">
                              No hay posts publicados.
                            </div>
                          )}
                        </Row>
                      </Card>
                    </Col>
                  </Row>
                </Container>
              </>
            );
          } else {
            return (
              <>
                {/* Page content */}
                <Container className="mt-6" fluid>
                  {!this.state.own_profile ? (
                    <div
                      className="back-button"
                      onClick={() => this.props.history.goBack()}
                    >
                      <img
                        alt="Undiscovered Atrás"
                        src={require("assets/img/icons/back.svg")}
                      />
                    </div>
                  ) : null}

                  <Row>
                    <Col className="order-xl-1 mb-3 mb-xl-0" lg="4">
                      <Card className="card-profile shadow p-sticky overflow-visible">
                        <div className="justify-content-center">
                          <div className="username">
                            <div className="h4 text-center mt--1 mb-1">
                              {this.state.user.username}
                            </div>
                          </div>
                          <Col lg="12">
                            <div className="card-profile-image mt--5 mb-5 text-center">
                              <img
                                alt="..."
                                className="rounded-circle"
                                src={
                                  this.state.user.profile_picture_url !==
                                  undefined
                                    ? this.state.user.profile_picture_url
                                    : require("assets/img/brand/ProfilePlaceholder.png")
                                }
                              />
                            </div>
                          </Col>
                        </div>
                        <CardHeader className="text-center border-0 p-0 mt--6">
                          <div className="d-flex justify-content-center">
                            {this.state.own_profile ? (
                              <Button
                                className="discovered button"
                                color="primary"
                                href=""
                                onClick={() => {
                                  this.toggleEditProfile();
                                }}
                                size="sm"
                              >
                                EDITAR PERFIL
                              </Button>
                            ) : this.state.user.username !== "undiscovered" ? (
                              <Button
                                className="discovered button"
                                color={
                                  this.state.discovered ? "primary" : "white"
                                }
                                onClick={this.discoverUser}
                                size="sm"
                              >
                                {this.state.discovered
                                  ? "DISCOVERED"
                                  : "UNDISCOVERED"}
                              </Button>
                            ) : (
                              <div className="mt-4"></div>
                            )}
                          </div>
                        </CardHeader>
                        <CardBody className="pt-2 pb-3 px-3">
                          <div className="text-center">
                            {user_type}
                            <h3 className="mb-0">
                              {this.state.user.name
                                ? this.state.user.name.substr(0, 30) + ", "
                                : null}
                              {this.state.user.username !== "undiscovered" ? (
                                <span className="font-weight-light">
                                  {utilitiesHelpers.calcAge(
                                    this.state.user.birthday
                                  )}
                                </span>
                              ) : null}
                            </h3>
                            {this.state.user.username !== "undiscovered" &&
                            this.state.user.city ? (
                              <div className="h4 font-weight-300 lh-10">
                                {this.state.user.city},{" "}
                                {this.state.user.country}
                              </div>
                            ) : null}
                            {send_message}
                            {this.state.user.username !== "undiscovered" ? (
                              <>
                                {report_user}
                                {report_modal}
                              </>
                            ) : null}
                            <Row>
                              <div className="col">
                                <div className="card-profile-stats d-flex justify-content-center mt-lg-2 ">
                                  <div>
                                    <span className="heading">
                                      {this.state.posts.length}
                                    </span>
                                    <span className="description">
                                      Publicaciones
                                    </span>
                                  </div>
                                  {this.state.user.username !==
                                  "undiscovered" ? (
                                    <div
                                      className="link"
                                      onClick={() => {
                                        this.props.history.push(
                                          "/buscar?type=discoveries&username=" +
                                            this.state.user.username
                                        );
                                      }}
                                    >
                                      <span className="heading">
                                        {this.state.discoveries.length}
                                      </span>
                                      <span className="description">
                                        Discovered by
                                      </span>
                                    </div>
                                  ) : null}

                                  <div
                                    className="link"
                                    onClick={() => {
                                      this.props.history.push(
                                        "/buscar?type=discovering&username=" +
                                          this.state.user.username
                                      );
                                    }}
                                  >
                                    <span className="heading">
                                      {this.state.discovering.length}
                                    </span>
                                    <span className="description">
                                      Discovering
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </Row>

                            {this.state.user.current_club !== "" &&
                            this.state.user.current_club ? (
                              <hr className="my-2" />
                            ) : null}
                            <p className="profile-description mb-1 mt-2">
                              {this.state.user.description}
                            </p>
                            {userTeam}
                          </div>
                          {this.state.user.username !== "undiscovered" &&
                          this.state.user.gender ? (
                            <Row>
                              <Col className="p-0">
                                <div className="card-profile-stats pt-2 pb-1 scrolling-wrapper border-radius-10 text-center">
                                  <Card className="card-profile shadow py-2 px-3 ml-3 d-inline-flex justify-content-between">
                                    <h4 className="mb--3 color-secondary pb-1 fs-14">
                                      BIOGRAFÍA
                                    </h4>
                                    <div>
                                      <img
                                        alt="undiscovered-agente"
                                        className="profile-card-icon-small mb-1"
                                        src={require("assets/img/icons/gender.svg")}
                                      />
                                      <div className="h5 profile-stats-value-1 mb--1">
                                        {this.state.user.gender}
                                        <p className="profile-stats">Género</p>
                                      </div>
                                    </div>
                                    <div>
                                      <img
                                        alt="undiscovered-agente"
                                        className="profile-card-icon-small mb-1"
                                        src={require("assets/img/icons/height.svg")}
                                      />
                                      <div className="h5 profile-stats-value-1 mb--1">
                                        {this.state.user.height}cm
                                        <p className="profile-stats">Altura</p>
                                      </div>
                                    </div>
                                    <div>
                                      <img
                                        alt="undiscovered-agente"
                                        className="profile-card-icon-small mb-1"
                                        src={require("assets/img/icons/weight.svg")}
                                      />
                                      <div className="h5 profile-stats-value-1 mb--1">
                                        {this.state.user.weight}kg
                                        <p className="profile-stats">Peso</p>
                                      </div>
                                    </div>
                                  </Card>
                                  <Card className="card-profile shadow py-2 px-3 ml-3 d-inline-flex justify-content-between">
                                    <h4 className="color-secondary fs-14">
                                      DATOS TÉCNICOS
                                    </h4>
                                    <div className="my-half1">
                                      <div className="h5 profile-stats-value-1 mb--1">
                                        {this.state.user.position}
                                      </div>
                                      <p className="profile-stats">Posición</p>
                                    </div>
                                    <hr className="my-0" />
                                    <div className="my-half1">
                                      <div className="h5 profile-stats-value-1 mb--1">
                                        {this.state.user.alt_position}
                                      </div>
                                      <p className="profile-stats">
                                        Posición Alternativa
                                      </p>
                                    </div>
                                    <hr className="my-0" />
                                    <div className="my-half1">
                                      <div className="h5 profile-stats-value-1 mb--1">
                                        {this.state.user.dominant_side}
                                      </div>
                                      <p className="profile-stats">
                                        Lado Dominante
                                      </p>
                                    </div>
                                    <hr className="my-0" />
                                    <div className="my-half1">
                                      <div className="h5 profile-stats-value-1 mb--1">
                                        {this.state.user.time30 === "00:00"
                                          ? "N/A"
                                          : this.state.user.time30}
                                      </div>
                                      <p className="profile-stats">
                                        Tiempo 30m
                                      </p>
                                    </div>
                                    <hr className="my-0" />
                                    <div className="my-half1">
                                      <div className="h5 profile-stats-value-1 mb--1">
                                        {this.state.user.time1000 === "00:00"
                                          ? "N/A"
                                          : this.state.user.time1000}
                                      </div>
                                      <p className="profile-stats">
                                        Tiempo 1000m
                                      </p>
                                    </div>
                                  </Card>
                                  <Card className="card-profile shadow py-2 px-3 mx-3 d-inline-flex justify-content-between">
                                    <h4 className="color-secondary fs-14">
                                      DATOS PROFESIONALES
                                    </h4>
                                    <div>
                                      <div className="h5 profile-stats-value-1 mb--1">
                                        <img
                                          alt="undiscovered-agente"
                                          className="profile-card-icon mb-2"
                                          src={
                                            this.state.user.agent
                                              ? require("assets/img/icons/check.svg")
                                              : require("assets/img/icons/cross.svg")
                                          }
                                        />
                                      </div>
                                      <p className="profile-stats-bigger">
                                        Agente
                                      </p>
                                    </div>
                                    <hr className="my-2" />
                                    <div className="mb-3">
                                      <div className="h5 profile-stats-value-1 mb--1">
                                        <img
                                          alt="undiscovered-contrato"
                                          className="profile-card-icon mb-2"
                                          src={
                                            this.state.user.contract
                                              ? require("assets/img/icons/check.svg")
                                              : require("assets/img/icons/cross.svg")
                                          }
                                        />
                                      </div>
                                      <p className="profile-stats-bigger">
                                        Contrato
                                      </p>
                                    </div>
                                  </Card>
                                </div>
                              </Col>
                            </Row>
                          ) : null}
                        </CardBody>
                      </Card>
                    </Col>
                    <Col className="order-xl-1 m-0 mt-md--6 mt-lg--5" lg="8">
                      <Row className="px-3 no-mobile">
                        <PostFilter
                          toggleVideoType={this.toggleVideoType}
                          getUtilities={() => this.getUtilities()}
                          setSkills={this.setSkills}
                          physical_skills={this.state.physical_skills}
                          technical_skills={this.state.technical_skills}
                          type_skills={this.state.type_skills}
                        />
                      </Row>
                      <Row className="justify-content-center bg-primary pt-2 mt--1 pb-2 no-desktop">
                        <Dropdown
                          isOpen={false}
                          className="full-width hz-padded"
                          toggle={() => {
                            this.toggleModal();
                          }}
                        >
                          <DropdownToggle
                            caret
                            className="full-width btn-white mobile-bigger-dropdown"
                          >
                            Filtrar Posts
                          </DropdownToggle>
                        </Dropdown>
                      </Row>
                      <Modal
                        isOpen={this.state.modal}
                        // toggle={()=>this.toggleModal()}
                        className="posts-filter-modal"
                      >
                        <ModalBody className="p-0">
                          <PostFilter
                            toggleVideoType={this.toggleVideoType}
                            getUtilities={() => this.getUtilities()}
                            setSkills={this.setSkills}
                            physical_skills={this.state.physical_skills}
                            technical_skills={this.state.technical_skills}
                            type_skills={this.state.type_skills}
                            toggleModal={() => this.toggleModal()}
                            is_modal={true}
                          />
                        </ModalBody>
                      </Modal>

                      <Card className="card-profile shadow p-3 mt-2 pt-2 pb-2">
                        <Row className="justify-content-around">
                          {_.sortBy(this.state.posts, "dateCreated")
                            .reverse()
                            .map((post) => {
                              post.user = this.state.user;
                              if (
                                this.state.video_type === "all" ||
                                post.video_type === this.state.video_type
                              ) {
                                if (this.state.video_type === "skill") {
                                  if (post.video_type === "skill") {
                                    if (this.state.filtered_skills) {
                                      if (
                                        this.skillContained(
                                          post.physical_skills,
                                          post.technical_skills
                                        )
                                      ) {
                                        return (
                                          <Video
                                            {...this.props}
                                            // userDiscoveryUpdated={
                                            //   this.userDiscoveryUpdated
                                            // }
                                            key={
                                              post.id +
                                              ":" +
                                              post.user.discovered
                                            }
                                            showButton={
                                              localStorage.getItem(
                                                "userUndiscovered"
                                              ) === post.user.id
                                                ? false
                                                : true
                                            }
                                            showMenu={
                                              localStorage.getItem(
                                                "userUndiscovered"
                                              ) === post.user.id
                                                ? true
                                                : false
                                            }
                                            user={post.user}
                                            post={post}
                                          />
                                        );
                                      } else {
                                        return null;
                                      }
                                    } else {
                                      return (
                                        <Video
                                          {...this.props}
                                          // userDiscoveryUpdated={
                                          //   this.userDiscoveryUpdated
                                          // }
                                          key={
                                            post.id + ":" + post.user.discovered
                                          }
                                          showButton={
                                            localStorage.getItem(
                                              "userUndiscovered"
                                            ) === post.user.id
                                              ? false
                                              : true
                                          }
                                          showMenu={
                                            localStorage.getItem(
                                              "userUndiscovered"
                                            ) === post.user.id
                                              ? true
                                              : false
                                          }
                                          user={post.user}
                                          post={post}
                                        />
                                      );
                                    }
                                  } else {
                                    return null;
                                  }
                                } else if (this.state.video_type === "game") {
                                  if (post.video_type === "game") {
                                    return (
                                      <Video
                                        {...this.props}
                                        // userDiscoveryUpdated={
                                        //   this.userDiscoveryUpdated
                                        // }
                                        key={
                                          post.id + ":" + post.user.discovered
                                        }
                                        showButton={
                                          localStorage.getItem(
                                            "userUndiscovered"
                                          ) === post.user.id
                                            ? false
                                            : true
                                        }
                                        showMenu={
                                          localStorage.getItem(
                                            "userUndiscovered"
                                          ) === post.user.id
                                            ? true
                                            : false
                                        }
                                        user={post.user}
                                        post={post}
                                      />
                                    );
                                  } else {
                                    return null;
                                  }
                                } else if (this.state.video_type === "all") {
                                  return (
                                    <Video
                                      {...this.props}
                                      // userDiscoveryUpdated={
                                      //   this.userDiscoveryUpdated
                                      // }
                                      key={post.id + ":" + post.user.discovered}
                                      showButton={
                                        localStorage.getItem(
                                          "userUndiscovered"
                                        ) === post.user.id
                                          ? false
                                          : true
                                      }
                                      showMenu={
                                        localStorage.getItem(
                                          "userUndiscovered"
                                        ) === post.user.id
                                          ? true
                                          : false
                                      }
                                      user={post.user}
                                      post={post}
                                    />
                                  );
                                } else {
                                  return null;
                                }
                              } else {
                                return null;
                              }
                            })}
                          {this.state.posts[0] ? (
                            <div className="h4 ls-2">
                              No hay más posts para mostrar.
                            </div>
                          ) : (
                            <div className="h4 ls-2">
                              No hay posts publicados.
                            </div>
                          )}
                        </Row>
                      </Card>
                    </Col>
                  </Row>
                </Container>
              </>
            );
          }
        }
      }
    }
  }
}

export default Profile;
