import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
// reactstrap components
import { Container } from "reactstrap";
// core components
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import Footer from "components/Footers/Footer.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import userHelpers from "../helpers/user";
import firebase from "../config/firebase.js";
import { Button } from "reactstrap";
import routes from "routes.js";

/**
 * AdminLayout Class.
 * @constructor
 */
class Admin extends React.Component {
  constructor(props) {
    super(props);
    this.state = { loggedIn: false, isBlocked: false };
  }

  /**
   * Upon mounting, check get username from id in localstorage.
   * Check if logged in
   * @method
   */
  componentDidMount() {
    const uid = localStorage.getItem("userUndiscovered");
    if (uid !== undefined) {
      this.setState({ loggedIn: true });
      userHelpers
        .getUserData(uid)
        .then((user) => this.setState({ isBlocked: user.blocked || false }));
    } else {
      this.setState({ loggedIn: false });
    }
  }

  /**
   * Upon update, scroll to top.
   * @method
   */
  componentDidUpdate(e) {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    if (this.refs && this.refs.mainContent) {
      this.refs.mainContent.scrollTop = 0;
    }
  }

  /**
   * Get routes for this layout
   * @method
   */
  getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  /**
   * Get name of the Page
   * @method
   */
  getBrandText = (path) => {
    for (let i = 0; i < routes.length; i++) {
      if (
        this.props.location.pathname.indexOf(
          routes[i].layout + routes[i].path
        ) !== -1
      ) {
        return routes[i].name;
      }
    }
    return "Brand";
  };

  /**
   * Renders the AdminLayout component.
   * @see AdminLayout Component
   */
  render() {
    return (
      <>
        {this.state.isBlocked ? (
          <>
            <div style={{ textAlign: "center" }}>
              <h1>Usuario Bloqueado</h1>
              <h2>Contáctanos para resolver el problema</h2>
              <div>
                <a href="mailto:contacto@undiscovered.com">
                  contacto@undiscovered.com
                </a>
              </div>
              <div>
                <Button
                  onClick={() =>
                    firebase
                      .auth()
                      .signOut()
                      .then(() => {
                        localStorage.removeItem("userUndiscovered");
                        this.props.history.push("/auth/login");
                      })
                  }
                >
                  Cerrar sesión
                </Button>
              </div>
            </div>
          </>
        ) : (
          <>
            <Sidebar
              {...this.props}
              routes={routes}
              logo={{
                innerLink: "/inicio",
                imgSrc: require("assets/img/brand/argon-react.png"),
                imgAlt: "...",
              }}
            />
            <div className="main-content" ref="mainContent">
              <AdminNavbar
                loggedIn={this.state.loggedIn}
                {...this.props}
                brandText={this.getBrandText(this.props.location.pathname)}
              />
              <Switch>
                {this.getRoutes(routes)}
                <Redirect from="*" to="/inicio" />
              </Switch>
              <Container fluid>
                <Footer />
              </Container>
            </div>
          </>
        )}
      </>
    );
  }
}

export default Admin;
