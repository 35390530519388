import firebase from "./../config/firebase.js";

const utilitiesHelpers = {
  /**
   * Gets all positions available in utilities
   * @method
   */
  getUtilitiesUser: async () => {
    const utilities = [];
    await firebase
      .firestore()
      .collection("utilities")
      .doc("positions")
      .get()
      .then((doc) => {
        utilities.push({ ...doc.data() });
      });
    var positions = utilities[0];
    return positions;
  },

  /**
   * Gets all skill utilities
   * @method
   */
  getUtilitiesSkills: async () => {
    const utilities = [];
    await firebase
      .firestore()
      .collection("utilities")
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          if (doc.id === "physical_skills" || doc.id === "technical_skills" || doc.id === "type_skills") {
            var temp = Object.values(doc.data());
            var u = [];
            for (let i = 0; i < temp.length; i++) {
              u.push({ value: temp[i], active: false });
            }
            utilities.push({ ...u });
          }
        });
      });
    const physical_skills = utilities[0];
    const technical_skills = utilities[1];
    const type_skills = utilities[2];
    return [physical_skills, technical_skills, type_skills];
  },

  /**
   * Calculates age during registration and edit profile
   * @method
   * @param dob date of birth entered in input
   */
  calcAgeFromPicker: (dob) => {
    var today = new Date();
    var pattern = /(\d{2})\/(\d{2})\/(\d{4})/;
    let birthday;
    if (typeof dob === "string") {
      birthday = new Date(dob.replace(pattern, "$3-$2-$1")); // create a date object directly from `dob1` argument
    } else {
      birthday = new Date(dob.toString().replace(pattern, "$3-$2-$1")); // create a date object directly from `dob1` argument
    }
    var age_now = today.getFullYear() - birthday.getFullYear();
    var m = today.getMonth() - birthday.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthday.getDate())) {
      age_now--;
    }
    return age_now;
  },

  /**
   * Calculates age for render
   * @method
   * @param dob date of birth registered for user
   */
  calcAge: (dob) => {
    var today = new Date();
    var parts = dob.toString().split("/");
    var birthday = new Date(parts[2], parts[1] - 1, parts[0]);
    var age_now = today.getFullYear() - birthday.getFullYear();
    var m = today.getMonth() - birthday.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthday.getDate())) {
      age_now--;
    }
    return age_now;
  },

  /**
   * Uploads profile picture to database
   * @method
   * @param uid current user id
   * @param filename filename of profile picture
   */
  uploadProfilePicture: async (uid, blob) => {
    const profile_picture_url = await firebase
      .storage()
      .ref(`users/${uid}`)
      .child("ProfilePicture")
      .put(blob)
      .then(function (snapshot) {
        var url = snapshot.ref.getDownloadURL().then((downloadURL) =>{
          firebase.firestore().collection("users").doc(uid).update({
            profile_picture_url: downloadURL,
          });
          return downloadURL;
        })
        return url;
      });
    try {
      await firebase.auth().currentUser.updateProfile({
        photoURL: profile_picture_url,
      });
    } catch (err) {
      console.log(err);
    }

    return profile_picture_url;
  },

  /**
   * Formats text to name format
   * @method
   * @param str string to be formatted
   */
  toCapitalize: (str) => {
    str = str.toLowerCase();
    str = str.split(" ");
    for (var i = 0; i < str.length; i++) {
      str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1);
    }
    str = str.join(" ");
    str = str = str.split("-");
    for (var j = 0; j < str.length; j++) {
      str[j] = str[j].charAt(0).toUpperCase() + str[j].slice(1);
    }
    return str.join("-");
  },
};

export default utilitiesHelpers;
