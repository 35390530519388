import React from "react";
import { Container } from "reactstrap";

/**
 * Challenges Class, future implementation.
 * @constructor
 */
class Challenges extends React.Component {
  state = {};

  /**
   * Renders the Challenges component.
   * @see Challenges Component
   */
  render() {
    return (
      <>
        {/* Page content */}
        <Container
          className="justify-content-center vertical-center mt--1 text-center"
          style={{ height: "90vh", paddingTop: "35vh" }}
          fluid
        >
          <div className="h2 ls-2">PRÓXIMAMENTE</div>
          <p className="info-text-md">
          En esta sección podrás ver y participar en competencias organizadas por undiscovered u organizaciones registradas en nuestra plataforma.{" "}
          </p>
        </Container>
      </>
    );
  }
}

export default Challenges;
