import React from "react";
import {
  Button,
  Container,
  Row,
  Col,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Alert,
} from "reactstrap";

import validationHelpers from "./../../helpers/validation";
import ReactPlayer from "react-player";

/**
 * AddYoutubeVideo Post Class
 * @constructor
 */
class AddYoutubeVideo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dropdownOpen: false,
      video_published: false,
      link: "",
      title: "",
      description: "",
      error_message: "",
      edit_post: false,
    };
    this.publishPost = this.publishPost.bind(this);
  }

  /**
   * Upon mounting, check get username from id in localstorage.
   * Check if post is being edited or added for the first time.
   * @method
   */
  async componentDidMount() {
    this.setState({
      uid: this.props.uid,
      username: this.props.username,
      link: this.props.link,
      title: this.props.title,
      description: this.props.description,
      edit_post: this.props.edit_post,
      post_id: this.props.post_id,
    });

    if (!this.props.edit_post) {
      var post_id = await validationHelpers.addPost();
      this.setState({ post_id: post_id });
    }
  }

  /**
   * Completes the post publishing or post update.
   * Saves data to db.
   * @method
   */
  async publishPost() {
    if (
      this.state.link !== "" ||
      (this.state.title !== "") | (this.state.description !== "")
    ) {
      try {
        await validationHelpers.editPostGame(
          this.state.post_id,
          this.state.link,
          this.state.title,
          this.state.description
        );
        this.setState({ video_published: true });
        return true;
      } catch (err) {
        console.log(err)
        this.setState({
          error_message: "Ocurrió un error, favor de intentar más tarde",
        });
        return false;
      }
    } else {
      this.setState({ error_message: "Campos Inválidos." });
    }
  }
  /**
   * Basic change handle for inputs
   * @method
   * @param e Element firing the function
   */
  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  /**
   * Basic change for video url
   * @method
   * @param e Element firing the function
   */
  handleLinkChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  /**
   * Renders the AddYoutubeVideo component with state options.
   * @see AddYoutubeVideo Component
   */
  render() {
    if (this.state.loading) {
      return (
        <div className="preloader-wrapper">
          <embed
            className="preloader-icon"
            src={require("assets/img/brand/preloader.svg")}
          />
        </div>
      );
    } else {
      if (this.state.video_published) {
        return (
          <Container className="mt-0 py-6 my-8" fluid>
            <Row>
              <Col className="mb-3 offset-lg-3" lg="6">
                <div className="h4 ls-2 my-5 text-center color-primary">
                  ¡VIDEO PUBLICADO!
                </div>
                <div className="d-flex justify-content-center full-width mb-3 text-center">
                  <Col>
                    <Button
                      className="action button m-1"
                      color="white"
                      onClick={() => {
                        window.location.reload();
                      }}
                      size="lg"
                    >
                      PUBLICAR OTRO POST
                    </Button>
                    <Button
                      className="action button m-1"
                      color="primary"
                      onClick={() => {
                        this.props.history.push(
                          "/perfil/" + this.state.username
                        );
                      }}
                      size="lg"
                    >
                      IR A PERFIL
                    </Button>
                  </Col>
                </div>
              </Col>
            </Row>
          </Container>
        );
      }

      return (
        <>
          <ReactPlayer
            url={this.state.link}
            className="youtube-video border-radius-10"
            playing
            loop
            controls={false}
            volume={1}
            playsinline
          />

          <InputGroup className="form-control-alternative search-bar mb-2 mt-2">
            <InputGroupAddon addonType="prepend">
              <InputGroupText>
                <img
                  alt="Undiscovered"
                  src={require("assets/img/icons/youtube.svg")}
                  className="icon search-icon"
                />
              </InputGroupText>
            </InputGroupAddon>
            <Input
              className="search-input"
              type="text"
              placeholder="Link de Video"
              value={this.state.link}
              name="link"
              onChange={(e) => this.handleLinkChange(e)}
            />
          </InputGroup>
          <Input
            className="form-control-alternative mb-2"
            type="text"
            value={this.state.title}
            maxLength="60"
            name="title"
            onChange={(e) => this.handleChange(e)}
            placeholder="Título del Video"
          />
          <Input
            className="form-control-alternative textarea mb-2"
            placeholder="Descripción del video ..."
            rows="3"
            type="textarea"
            maxLength="280"
            value={this.state.description}
            name="description"
            onChange={(e) => this.handleChange(e)}
          />
          <Alert
            className="mt-2"
            color="warning"
            hidden={this.state.error_message === "" ? true : false}
          >
            <strong>Error:</strong> {this.state.error_message}
          </Alert>
          <div className="d-flex justify-content-around full-width mb-3 mt-3">
            {this.state.post_id ? (
              <Button
                className="action button"
                color="black"
                onClick={() => {
                  this.publishPost();
                }}
                size="lg"
              >
                PUBLICAR
              </Button>
            ) : null}
          </div>
        </>
      );
    }
  }
}

export default AddYoutubeVideo;
