import React from "react";
import {
  Card,
  Row,
  Dropdown,
  DropdownToggle,
  ButtonGroup,
  Button,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  DropdownItem,
  DropdownMenu,
  Col,
  Form,
} from "reactstrap";

import validationHelpers from "./../../helpers/validation";

/**
 * UserFilter Class.
 * Main Filter for users component view.
 * @constructor
 */
class UserFilter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dropdown_open: [false, false, false, false, false, false, false],
      discoveredActive: true,
      countries: {},
      cities: [],
      positions: [],
      teams: [],
      error_message: "",
      name_username: "",
      country: "",
      countryFilter: "",
      min_age: "",
      max_age: "",
      city: "",
      cityFilter: "",
      position: "",
      alt_position: "",
      dominant_side: "",
      fav_team: "",
      fav_team_filter: "",
      gender: "",
      time30minutes_min: "",
      time30seconds_min: "",
      time30minutes_max: "",
      time30seconds_max: "",
      time1000minutes_min: "",
      time1000seconds_min: "",
      time1000minutes_max: "",
      time1000seconds_max: "",
      height_min: "",
      height_max: "",
      weight_min: "",
      weight_max: "",
      agent: "",
      contract: "",
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleChangeSearch = this.handleChangeSearch.bind(this);
    this.handleChangeCountry = this.handleChangeCountry.bind(this);
    this.handleChangeCheckbox = this.handleChangeCheckbox.bind(this);
    this.toggleDiscovered = this.toggleDiscovered.bind(this);
    this.toggleDropdown = this.toggleDropdown.bind(this);
    this.handleChangeTime = this.handleChangeTime.bind(this);
  }

  /**
   * Upon mounting, get utilities from props
   * @method
   */
  async componentDidMount() {
    this.setState({
      hiddenFilters: this.props.hiddenFilters,
      countries: this.props.countries,
      positions: this.props.positions,
    });
  }

  /**
   * Toggle for discovered/undiscovered users
   * @method
   */
  toggleDiscovered() {
    this.setState({ discoveredActive: !this.state.discoveredActive });
  }

  /**
   * Resets all the filter values
   * @method
   */
  async clearFilter() {
    await this.props.getUtilities();
    this.setState({
      countries: this.props.countries,
      positions: this.props.positions,
      name_username: "",
      country: "",
      countryFilter: "",
      min_age: "",
      max_age: "",
      city: "",
      cityFilter: "",
      position: "",
      alt_position: "",
      dominant_side: "",
      fav_team: "",
      fav_team_filter: "",
      gender: "",
      time30minutes_min: "",
      time30seconds_min: "",
      time30minutes_max: "",
      time30seconds_max: "",
      time1000minutes_min: "",
      time1000seconds_min: "",
      time1000minutes_max: "",
      time1000seconds_max: "",
      height_min: "",
      height_max: "",
      weight_min: "",
      weight_max: "",
      agent: "",
      contract: "",
    });
  }

  /**
   * Returns values to parent component from the values set in filter form
   * @method
   */
  async filter() {
    var time30min = validationHelpers.formatTime(
      this.state.time30minutes_min,
      this.state.time30seconds_min
    );
    var time30max = validationHelpers.formatTime(
      this.state.time30minutes_max,
      this.state.time30seconds_max
    );
    var time1000min = validationHelpers.formatTime(
      this.state.time1000minutes_min,
      this.state.time1000seconds_min
    );
    var time1000max = validationHelpers.formatTime(
      this.state.time1000minutes_max,
      this.state.time1000seconds_max
    );
    await this.props.setFilter(
      this.state.country,
      this.state.city,
      this.state.gender,
      this.state.min_age,
      this.state.max_age,
      this.state.position,
      this.state.dominant_side,
      time30min,
      time30max,
      time1000min,
      time1000max,
      this.state.height_min,
      this.state.height_max,
      this.state.weight_min,
      this.state.weight_max,
      this.state.agent,
      this.state.contract
    );
  }

  /**
   * Basic search change handle.
   * @method
   * @param e Element firing the function
   */
  async handleChangeSearch(e) {
    this.setState({ [e.target.name]: e.target.value });
    await this.props.handleChangeSearch(e.target.value);
  }

  /**
   * Basic change handle.
   * @method
   * @param e Element firing the function
   */
  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  /**
   * Basic country change handle.
   * @method
   * @param e Element firing the function
   */
  handleChangeCountry(e) {
    this.setState({
      [e.target.name]: e.target.value,
      cities: this.state.countries[e.target.value],
      city: "",
    });
  }

  /**
   * Basic checkbox toggle handle.
   * Resets to "" to avoid wrong clearing of filter
   * @method
   * @param e Element firing the function
   */
  handleChangeCheckbox(e) {
    if (this.state[e.target.name] === true) {
      this.setState({
        [e.target.name]: "",
      });
    } else {
      this.setState({
        [e.target.name]: !this.state[e.target.name],
      });
    }
  }

  /**
   * Basic time toggle handle.
   * @method
   * @param e Element firing the function
   */
  handleChangeTime(e) {
    if (e.target.value.length <= 2 && e.target.value < 60) {
      this.setState({
        [e.target.name]: e.target.value,
      });
    }
  }

  /**
   * Basic dropdown toggle handle.
   * @method
   * @param num index of dropdown to be opened
   */
  toggleDropdown(num) {
    var tempArray = [...this.state.dropdown_open];
    tempArray[num] = !tempArray[num];
    this.setState({ dropdown_open: tempArray });
  }

  /**
   * Renders the UserFilter component.
   * @see UserFilter Component
   */
  render() {
    let city_dropdown;
    if (this.state.cities[0]) {
      city_dropdown = this.state.cities.map((city, i) => {
        var string_norm = this.state.cityFilter
          .toLowerCase()
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "");
        var city_norm = city
          .toLowerCase()
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "");
        if (city_norm.toLowerCase().includes(string_norm)) {
          return (
            <DropdownItem
              key={i}
              name="city"
              value={city}
              onClick={this.handleChange}
            >
              {city}
            </DropdownItem>
          );
        } else {
          return null;
        }
      });
    } else {
      city_dropdown = <DropdownItem disabled>Seleccionar País</DropdownItem>;
    }

    return (
      <>
        <Card className="card-profile shadow p-3">
          <Form>
            <div className="no-mobile">
              <InputGroup className="form-control-alternative search-bar d-flex  mb-3">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <img
                      alt="Undiscovered"
                      src={require("assets/img/icons/search.svg")}
                      className="icon search-icon"
                    />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  className="search-input"
                  type="text"
                  name="name_username"
                  onChange={this.handleChangeSearch}
                  placeholder="Buscar Usuario..."
                  autoComplete="new-password"
                />
              </InputGroup>
            </div>
            <div className="h4 text-center ls-2 mb-1">FILTRAR USUARIOS</div>
            <hr className="my-1" />
            <div className="h5 text-center">DATOS DEMOGRÁFICOS</div>
            <Row className="d-flex justify-content-between pt-0 mt--1 pb-0 mb-2 filter-dropdown-row">
              <Dropdown
                isOpen={this.state.dropdown_open[0]}
                toggle={() => this.toggleDropdown(0)}
                className="full-width hz-padded pr-1"
              >
                <DropdownToggle caret className="full-width btn-white">
                  {this.state.country !== "" ? this.state.country : "País"}
                </DropdownToggle>
                <DropdownMenu>
                  <Input
                    className="form-control-alternative dropdown-search"
                    type="text"
                    name="countryFilter"
                    value={this.state.countryFilter}
                    placeholder="Buscar..."
                    autoComplete="new-password"
                    onChange={this.handleChange}
                  />
                  {Object.keys(this.state.countries).map((country, i) => {
                    var string_norm = this.state.countryFilter
                      .toLowerCase()
                      .normalize("NFD")
                      .replace(/[\u0300-\u036f]/g, "");
                    var country_norm = country
                      .toLowerCase()
                      .normalize("NFD")
                      .replace(/[\u0300-\u036f]/g, "");
                    if (country_norm.toLowerCase().includes(string_norm)) {
                      return (
                        <DropdownItem
                          key={i}
                          name="country"
                          value={country}
                          onClick={this.handleChangeCountry}
                        >
                          {country}
                        </DropdownItem>
                      );
                    } else {
                      return null;
                    }
                  })}
                </DropdownMenu>
              </Dropdown>
              <Dropdown
                isOpen={this.state.dropdown_open[1]}
                toggle={() => this.toggleDropdown(1)}
                className="full-width hz-padded pl-1"
              >
                <DropdownToggle caret className="full-width btn-white">
                  {this.state.city !== "" ? this.state.city : "Ciudad"}
                </DropdownToggle>
                <DropdownMenu>
                  <Input
                    className="form-control-alternative dropdown-search"
                    type="text"
                    name="cityFilter"
                    value={this.state.cityFilter}
                    placeholder="Buscar..."
                    autoComplete="new-password"
                    onChange={this.handleChange}
                  />
                  {city_dropdown}
                </DropdownMenu>
              </Dropdown>
            </Row>
            <Row className="justify-content-center pt-0 mt-0 pb-0">
              <ButtonGroup className="full-width hz-padded mb-2">
                <Button
                  color="white"
                  active={this.state.gender === "Masculino"}
                  onClick={() => {
                    this.setState({ gender: "Masculino" });
                  }}
                >
                  Masculino
                </Button>
                <Button
                  color="white"
                  active={this.state.gender === "Femenino"}
                  onClick={() => {
                    this.setState({ gender: "Femenino" });
                  }}
                >
                  Femenino
                </Button>
                <Button
                  color="white"
                  active={this.state.gender === "Otro"}
                  onClick={() => {
                    this.setState({ gender: "Otro" });
                  }}
                >
                  Otro
                </Button>
              </ButtonGroup>
            </Row>
            <Row className="d-flex justify-content-between pt-0 mt-0 pr-3 pl-3 mb-2">
              <Input
                className="filter-range form-control-alternative"
                type="number"
                name="min_age"
                placeholder="min"
                onChange={this.handleChange}
              />
              <p className="filter-range-label">EDAD</p>
              <Input
                className="filter-range form-control-alternative"
                type="number"
                name="max_age"
                placeholder="max"
                onChange={this.handleChange}
              />
            </Row>
            {this.props.hiddenFilters ? (
              <div className="mt-4"></div>
            ) : (
              <>
                <hr className="my-1" />
                <div className="h5 text-center">DATOS FÍSICOS</div>
                <Row className="d-flex justify-content-between pt-0 pb-0 mb-2 filter-dropdown-row">
                  <Dropdown
                    isOpen={this.state.dropdown_open[3]}
                    toggle={() => this.toggleDropdown(3)}
                    className="full-width hz-padded pr-1"
                  >
                    <DropdownToggle caret className="full-width btn-white">
                      {this.state.position !== ""
                        ? this.state.position
                        : "Posición"}
                    </DropdownToggle>
                    <DropdownMenu>
                      {Object.keys(this.state.positions).map((position, i) => {
                        return (
                          <DropdownItem
                            key={i}
                            name="position"
                            value={position}
                            onClick={this.handleChange}
                          >
                            {position}
                          </DropdownItem>
                        );
                      })}
                    </DropdownMenu>
                  </Dropdown>
                  <Dropdown
                    isOpen={this.state.dropdown_open[5]}
                    toggle={() => this.toggleDropdown(5)}
                    className="full-width hz-padded"
                  >
                    <DropdownToggle caret className="full-width btn-white">
                      {this.state.dominant_side !== ""
                        ? this.state.dominant_side
                        : "Lado Dominante"}
                    </DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem
                        name="dominant_side"
                        value="Derecho"
                        onClick={this.handleChange}
                      >
                        Derecho
                      </DropdownItem>
                      <DropdownItem
                        name="dominant_side"
                        value="Izquierdo"
                        onClick={this.handleChange}
                      >
                        Izquierdo
                      </DropdownItem>
                      <DropdownItem
                        name="dominant_side"
                        value="Ambos"
                        onClick={this.handleChange}
                      >
                        Ambos
                      </DropdownItem>
                    </DropdownMenu>
                  </Dropdown>
                </Row>
                <Row className="d-flex justify-content-between pt-0 mt-0 pr-3 pl-3 mb-2">
                  <div className="d-flex justify-content-center time-filter">
                    <Input
                      className="form-control-alternative min-sec mr-1"
                      type="number"
                      name="time30minutes_min"
                      value={this.state.time30minutes_min}
                      placeholder="mm"
                      max="60"
                      onChange={this.handleChangeTime}
                    />
                    <Input
                      className="form-control-alternative min-sec ml-1"
                      type="number"
                      name="time30seconds_min"
                      value={this.state.time30seconds_min}
                      placeholder="ss"
                      max="60"
                      onChange={this.handleChangeTime}
                    />
                  </div>
                  <p className="filter-range-label">- T. 30m -</p>
                  <div className="d-flex justify-content-center time-filter">
                    <Input
                      className="form-control-alternative min-sec mr-1"
                      type="number"
                      name="time30minutes_max"
                      value={this.state.time30minutes_max}
                      placeholder="mm"
                      max="60"
                      onChange={this.handleChangeTime}
                    />
                    <Input
                      className="form-control-alternative min-sec ml-1"
                      type="number"
                      name="time30seconds_max"
                      value={this.state.time30seconds_max}
                      placeholder="ss"
                      max="60"
                      onChange={this.handleChangeTime}
                    />
                  </div>
                </Row>
                <Row className="d-flex justify-content-between pt-0 mt-0 pr-3 pl-3 mb-2">
                  <div className="d-flex justify-content-center time-filter">
                    <Input
                      className="form-control-alternative min-sec mr-1"
                      type="number"
                      name="time1000minutes_min"
                      value={this.state.time1000minutes_min}
                      placeholder="mm"
                      max="60"
                      onChange={this.handleChangeTime}
                    />
                    <Input
                      className="form-control-alternative min-sec ml-1"
                      type="number"
                      name="time1000seconds_min"
                      value={this.state.time1000seconds_min}
                      placeholder="ss"
                      max="60"
                      onChange={this.handleChangeTime}
                    />
                  </div>
                  <p className="filter-range-label">- T. 1000m -</p>
                  <div className="d-flex justify-content-center time-filter">
                    <Input
                      className="form-control-alternative min-sec mr-1"
                      type="number"
                      name="time1000minutes_max"
                      value={this.state.time1000minutes_max}
                      placeholder="mm"
                      max="60"
                      onChange={this.handleChangeTime}
                    />
                    <Input
                      className="form-control-alternative min-sec ml-1"
                      type="number"
                      name="time1000seconds_max"
                      value={this.state.time1000seconds_max}
                      placeholder="ss"
                      max="60"
                      onChange={this.handleChangeTime}
                    />
                  </div>
                </Row>
                <Row className="d-flex justify-content-between pt-0 mt-0 pr-3 pl-3 mb-2">
                  <Input
                    className="filter-range form-control-alternative"
                    type="text"
                    name="height_min"
                    value={this.state.height_min}
                    placeholder="min cm"
                    onChange={this.handleChange}
                  />
                  <p className="filter-range-label">- ALTURA -</p>
                  <Input
                    className="filter-range form-control-alternative"
                    type="text"
                    name="height_max"
                    value={this.state.height_max}
                    placeholder="max cm"
                    onChange={this.handleChange}
                  />
                </Row>
                <Row className="d-flex justify-content-between pt-0 mt-0 pr-3 pl-3 mb-2">
                  <Input
                    className="filter-range form-control-alternative"
                    type="text"
                    name="weight_min"
                    value={this.state.weight_min}
                    placeholder="min kg"
                    onChange={this.handleChange}
                  />
                  <p className="filter-range-label">- PESO -</p>
                  <Input
                    className="filter-range form-control-alternative"
                    type="text"
                    name="weight_max"
                    value={this.state.weight_max}
                    placeholder="max kg"
                    onChange={this.handleChange}
                  />
                </Row>
                <Row className="d-flex justify-content-around pt-0 mt-0 pr-3 pl-3 mb-1 text-center">
                  <Col xs="6" className="custom-control custom-checkbox">
                    <label className="fs-12 mb-0">Agente</label>
                    <span className="clearfix" />
                    <label className="custom-toggle">
                      <input
                        onChange={(e) => this.handleChangeCheckbox(e)}
                        checked={this.state.agent}
                        value={this.state.agent}
                        name="agent"
                        type="checkbox"
                      />
                      <span className="custom-toggle-slider rounded-circle" />
                    </label>
                  </Col>
                  <Col xs="6" className="custom-control custom-checkbox">
                    <label className="fs-12 mb-0">Contrato</label>
                    <span className="clearfix" />
                    <label className="custom-toggle">
                      <input
                        onChange={(e) => this.handleChangeCheckbox(e)}
                        checked={this.state.contract}
                        value={this.state.contract}
                        name="contract"
                        type="checkbox"
                      />
                      <span className="custom-toggle-slider rounded-circle" />
                    </label>
                  </Col>
                </Row>
              </>
            )}

            <div className="d-flex justify-content-around full-width">
              <Button
                className="action button"
                color="white"
                size="lg"
                onClick={() => {
                  this.clearFilter();
                  if (this.props.is_modal) {
                    this.props.toggleModal();
                  }
                }}
              >
                BORRAR FILTROS
              </Button>
              <Button
                className="action button"
                color="primary"
                onClick={() => {
                  this.filter();
                  if (this.props.is_modal) {
                    this.props.toggleModal();
                  }
                }}
                size="lg"
              >
                FILTRAR
              </Button>
            </div>
          </Form>
        </Card>
      </>
    );
  }
}

export default UserFilter;
