import React from "react";
import firebase from "./../../config/firebase.js";
import { Button, Card, CardBody, Col, Alert } from "reactstrap";

//var admin = require('firebase-admin');

const queryString = require("query-string");

/**
 * EmailVerification Class.
 * Email verification component
 * @constructor
 */
class EmailVerification extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isSent: false,
      counter: 60,
      error: false,
    };
    this.resendVerification = this.resendVerification.bind(this);
  }

  async componentDidMount() {
    const parsed = queryString.parse(this.props.location.search);

    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        if (user.emailVerified) {
          this.props.history.push("../inicio");
        } else {
          this.setState({ error: true });
        }
      } else {
        this.logout();
      }
    });

    if (parsed.mode === "verifyEmail") {
      await firebase
        .auth()
        .handleVerifyEmail(
          parsed.auth,
          parsed.actionCode,
          parsed.continueUrl,
          parsed.lang
        )
        .then(() => {
          this.props.history.push("completar-perfil");
        });
    }
  }

  /**
   * Re-sends the verification code to users email
   * @method
   */
  resendVerification() {
    firebase
      .auth()
      .currentUser.sendEmailVerification({
        url: "https://undiscovered.app/inicio",
      })
      .then(() => {
        this.setState({ isSent: true });

        this.myInterval = setInterval(() => {
          const { counter } = this.state;
          if (counter > 0) {
            this.setState(({ counter }) => ({
              counter: counter - 1,
            }));
          }
          if (counter === 0) {
            this.setState({ isSent: false });
            clearInterval(this.myInterval);
          }
        }, 1000);
      });
  }

  /**
   * Logout function.
   * Removes id from local storage and signs out of firebase.
   * Redirects to login.
   * @method
   */
  async logout() {
    await firebase
      .auth()
      .signOut()
      .then(() => {
        localStorage.removeItem("userUndiscovered");
        this.props.history.push("/auth/login");
      })
      .catch(function (error) {});
  }

  /**
   * Renders the EmailVerification component.
   * @see EmailVerification Component
   */
  render() {
    return (
      <>
        <Col lg="6" md="8">
          <div className="full-width text-center justify-content-center my-4">
            <a
              href="https://www.undiscovered.app/"
              target="_blank"
              rel="noopener noreferrer"
              className="h4 mb-0 d-lg-inline-block"
            >
              <img
                alt="..."
                className="main-logo"
                src={require("assets/img/brand/Undiscovered-logo-black.png")}
              />
            </a>
          </div>

          {this.state.error ? (
            <Alert color="danger">
              Tienes que validar tu cuenta para poder continuar.
            </Alert>
          ) : null}

          <Card className="bg-white shadow border-0">
            <CardBody className="px-lg-5 py-lg-5 ">
              <div className="h3 ls-2 text-center">VERIFICAR CORREO</div>
              <p className="text-center">
                {" "}
                Te hemos enviando un link de verificación a tu correo (si no lo
                encuentras en tu bandeja de entrada por favor verifica en spam),
                por favor abre el correo y da click al link para continuar. Una
                vez hayas confirmado tu correo puedes recargar esta página para
                continuar.
              </p>
              <div className="pb-4 pt-3">
                <p className="text-center"> ¿No te llegó el correo?</p>
                <div className="text-center pb-0 m-0">
                  <Button
                    className="my-1 txt-black min-width-200"
                    color="primary"
                    type="button"
                    onClick={() => this.resendVerification()}
                    disabled={this.state.isSent}
                  >
                    REENVIAR CORREO{" "}
                    {this.state.isSent ? "(" + this.state.counter + ")" : ""}
                  </Button>
                </div>
                <p className="text-center">
                  {this.state.isSent ? "El correo ha sido enviado" : ""}
                </p>
                <p className="text-center mb-0">
                  <Button
                    className="m-0 txt-black min-width-200"
                    color="white"
                    type="button"
                    onClick={() => this.props.history.push("/auth/login")}
                  >
                    FINALIZAR{" "}
                  </Button>
                </p>
              </div>
            </CardBody>
          </Card>
        </Col>
      </>
    );
  }
}

export default EmailVerification;
