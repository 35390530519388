import React from "react";
import { Button, Container, Row, Col } from "reactstrap";

import userHelpers from "../helpers/user";

import AddYoutubeVideo from "./../components/Modules/AddYoutubeVideo.js";
import AddHostedVideo from "./../components/Modules/AddHostedVideo.js";

const queryString = require("query-string");

/**
 * Add Post Class
 * @constructor
 */
class Add extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dropdownOpen: false,
      video_published: false,
      skills_active: true,
      edit_post: false,
      loading: true,
      physical_skills: {},
      technical_skills: {},
      type_skills:{}
    };
    this.toggleSkills = this.toggleSkills.bind(this);
  }

  /**
   * Upon mounting, check get username from id in localstorage.
   * Check if post is being edited or added for the first time.
   * @method
   */
  async componentDidMount() {
    var uid = localStorage.getItem("userUndiscovered");
    var user = await userHelpers.getUserData(uid);
    this.setState({ uid: uid, username: user.username, user: user });
    const parsed = queryString.parse(this.props.location.search);
    if (parsed.post !== "" && parsed.post !== {} && parsed.post !== undefined) {
      await this.setValues(parsed.post);
    }
    this.setState({
      loading: false,
    });
  }
  /**
   * Sets the values for the post if it is being edited.
   * @method
   */
  async setValues(post_id) {
    var post = await userHelpers.getPostData(post_id);
    if (post.dateCreated !== undefined) {
      if (post.video_type === "skill") {
        this.setState({
          skills_active: true,
          physical_skills: post.physical_skills,
          technical_skills: post.technical_skills,
          type_skills:post.type_skills,
        });
      } else {
        this.setState({ skills_active: false });
      }
      this.setState({
        link: post.link,
        link_available: true,
        yt_video_id: post.yt_video_id,
        title: post.title,
        description: post.description,
        edit_post: true,
        post_id: post.id,
      });
    }
  }

  /**
   * Basic toggle for active skills
   * @method
   */
  toggleSkills() {
    this.setState({ skills_active: !this.state.skills_active });
  }

  /**
   * Renders the Add Video component with state options.
   * @see Add Video Component
   */
  render() {
    if (this.state.loading) {
      return (
        <div className="preloader-wrapper">
          <embed
            className="preloader-icon"
            src={require("assets/img/brand/preloader.svg")}
          />
        </div>
      );
    } else {
      if (this.state.video_published) {
        return (
          <Container className="mt-0 py-6 my-8" fluid>
            <Row>
              <Col className="mb-3 offset-lg-3" lg="6">
                <div className="h3 ls-2 my-5 text-center color-primary">
                  ¡VIDEO PUBLICADO!
                </div>
                <div className="d-flex justify-content-center full-width mb-3 text-center">
                  <Col>
                    <Button
                      className="action button m-1"
                      color="white"
                      onClick={() => {
                        window.location.reload();
                      }}
                      size="lg"
                    >
                      PUBLICAR OTRO POST
                    </Button>
                    <Button
                      className="action button m-1"
                      color="primary"
                      onClick={() => {
                        this.props.history.push(
                          "/perfil/" + this.state.username
                        );
                      }}
                      size="lg"
                    >
                      IR A PERFIL
                    </Button>
                  </Col>
                </div>
              </Col>
            </Row>
          </Container>
        );
      }

      return (
        <>
          <Container className="mt-0 pt-3 min-100vh" fluid>
            <Row>
              <Col className="mb-3 offset-lg-3" lg="6">
                <div className="h4 ls-2 text-center">AGREGAR VIDEO</div>
                <div className="h5 mt-3 ls-1 mb-1 text-center ">
                  Tipo de Video
                </div>
                <Row className="justify-content-center pt-2 mt--2 pb-3 border-radius-10-top">
                  <div
                    onClick={() => this.toggleSkills()}
                    className={
                      "post-type-select h4 txt-black " +
                      (this.state.skills_active ? "active" : null)
                    }
                  >
                    HABILIDAD
                  </div>
                  <div className="separator-skills">|</div>
                  <div
                    onClick={() => this.toggleSkills()}
                    className={
                      "post-type-select h4 txt-black " +
                      (this.state.skills_active ? "" : "active")
                    }
                  >
                    PARTIDO
                  </div>
                </Row>

                {this.state.skills_active ? (
                  <AddHostedVideo
                    {...this.props}
                    uid={this.state.uid}
                    username={this.state.username}
                    user={this.state.user}
                    physical_skills={this.state.physical_skills}
                    technical_skills={this.state.technical_skills}
                    type_skills={this.state.type_skills}
                    link={this.state.link}
                    link_available={this.state.link_available}
                    yt_video_id={this.state.yt_video_id}
                    title={this.state.title}
                    description={this.state.description}
                    edit_post={this.state.edit_post}
                    post_id={this.state.post_id}
                  />
                ) : (
                  <AddYoutubeVideo
                    {...this.props}
                    uid={this.state.uid}
                    username={this.state.username}
                    link={this.state.link}
                    link_available={this.state.link_available}
                    yt_video_id={this.state.yt_video_id}
                    title={this.state.title}
                    description={this.state.description}
                    edit_post={this.state.edit_post}
                    post_id={this.state.post_id}
                  />
                )}
              </Col>
            </Row>
          </Container>
        </>
      );
    }
  }
}

export default Add;
