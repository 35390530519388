import firebase from "./../config/firebase.js";
import validator from "validator";
import utilitiesHelpers from "./utilities";

const validationHelpers = {
  /**
   * Saves all user data to database and creates user in realtime db
   * @method
   * @param email current user registration value
   * @param password current user registration value
   * @param confirm_password current user registration value
   * @param user_type current user registration value
   * @param user_acceptance current user registration value
   * @param birthday current user registration value
   * @param tutor_name current user registration value
   * @param tutor_relationship current user registration value
   * @param tutor_id current user registration value
   */
  registerUser: async function (
    username,
    email,
    name,
    password,
    confirm_password,
    user_type,
    user_acceptance,
    birthday,
    tutor_name,
    tutor_relationship,
    tutor_id
  ) {
    var errors = {};
    var res = {};

    var [resUsername, errorUsername] = await validationHelpers.validateUserName(
      username
    );
    if (!resUsername) {
      errors.username = errorUsername;
    }

    if (email === "" || !validator.isEmail(email)) {
      errors.email = "Correo electrónico inválido";
    }

    if (name === "") {
      errors.name = "Nombre inválido";
    }

    if (password === "" || confirm_password === "" || password.length < 8) {
      errors.password = "La contraseña debe tener mínimo 8 caracteres";
    }

    if (password !== confirm_password) {
      errors.password = "Las contraseñas no coinciden";
    }

    var [resAge, errorsAge] = await validationHelpers.validateAge(
      birthday,
      tutor_name,
      tutor_relationship,
      tutor_id
    );

    if (!resAge) {
      errors = { ...errors, ...errorsAge };
    }

    if (user_acceptance !== true) {
      errors.user_acceptance =
        "Es necesario aceptar los términos y condiciones y política de privacidad";
    }

    if (Object.keys(errors).length === 0) {
      try {
        const user = await firebase
          .auth()
          .createUserWithEmailAndPassword(email, password);
        if (user.user.uid) {
          localStorage.setItem("userUndiscovered", user.user.uid);
          var day =
            birthday.getDate() < 10
              ? "0" + birthday.getDate()
              : birthday.getDate();
          var month =
            birthday.getMonth() + 1 < 10
              ? "0" + (birthday.getMonth() + 1)
              : birthday.getMonth() + 1;
          var year = birthday.getFullYear();
          firebase
            .firestore()
            .collection("users")
            .doc(user.user.uid)
            .set({
              email: email,
              dateCreated: new Date(),
              user_type: user_type,
              user_acceptance: user_acceptance,
              birthday: day + "/" + month + "/" + year,
              tutor_name: tutor_name,
              tutor_relationship: tutor_relationship,
              tutor_id: tutor_id,
              username: username,
              name: name.trim(),
            });
          await firebase.auth().currentUser.updateProfile({
            displayName: username,
          });
          await firebase.auth().currentUser.sendEmailVerification({
            url: "https://undiscoveredtest.web.app/inicio",
            //url: "localhost:3000/inicio",
          });
          res = user.user;
        }
      } catch (err) {
        if (err.code === "auth/email-already-in-use") {
          errors.email = "Este correo ya se encuentra registrado";
        } else {
          errors.general =
            "No se pudo crear tu cuenta en este momento, porfavor intenta más tarde";
        }
      }
    }

    return [res, errors];
  },

  /**
   * Checks if user exists and logs them in
   * @method
   * @param email current user registration value
   */
  loginUser: async function (email, password) {
    var errors = {};
    var res = {};

    if (email === "" || !validator.isEmail(email)) {
      errors.email = "Correo inválido";
    }

    if (password === "") {
      errors.password = "Contraseña inválida";
    }

    if (Object.keys(errors).length === 0) {
      try {
        const user = await firebase
          .auth()
          .signInWithEmailAndPassword(email, password);
        if (user.user.uid) {
          localStorage.setItem("userUndiscovered", user.user.uid);
          res = user.user;
        } else {
          errors.general = "Ocurrió un error, porfavor intenta más tarde.";
        }
      } catch (err) {
        if (err.code === "auth/user-not-found") {
          errors.general = "Este usuario no existe.";
        } else {
          errors.general = "Correo o contraseña inválidos.";
        }
      }
    }
    return [res, errors];
  },

  /**
   * Checks if user exists and sends password recovery email
   * @method
   * @param email current user registration value
   */
  forgotPassword: async function (email) {
    var res = firebase
      .auth()
      .sendPasswordResetEmail(email)
      .then((user) => {
        return true;
      })
      .catch((err) => {
        if (err.code === "auth/user-not-found") {
          return "Este usuario no está registrado.";
        } else if (err.code === "auth/invalid-email") {
          return "Correo inválido.";
        } else {
          return "Ocurrió un error, porfavor intenta más tarde.";
        }
      });
    return res;
  },

  /**
   * Sets the display name of the user to complete data
   * @method
   * @param username current user registration value
   * @param profile_picture_url current user registration value
   */
  setDisplayName: async (username, profile_picture_url) => {
    const user = firebase.auth.Auth;
    user
      .updateProfile({
        displayName: username,
        photoURL: profile_picture_url,
      })
      .then(
        function () {},
        function (error) {}
      );
  },

  /**
   * Checks if username is not repeated with another user
   * @method
   * @param username current user registration value
   */
  validateUserName: async (username) => {
    const regEx = /^(.{2,30})[a-z0-9._]$/;
    const uid = localStorage.getItem("userUndiscovered");
    var error = "";
    var res = false;
    if (username === "" || !regEx.test(username)) {
      error =
        'Usuario inválido. Por favor introduzca un usuario de 3 a 30 caractéres de letras, números y "." o "_" ';
    } else {
      const repeated = await firebase
        .firestore()
        .collection("users")
        .get()
        .then((querySnapshot) => {
          var taken = false;
          querySnapshot.forEach((doc) => {
            if (username === doc.data().username && uid !== doc.id) {
              taken = true;
            }
          });
          return taken;
        });
      if (repeated) {
        error = "Este usuario no está disponible";
      } else {
        res = true;
      }
    }
    return [res, error];
  },

  /**
   * Checks if age is >14, if not it checks that extra input values are valid
   * @method
   * @param birthday current user registration value
   * @param tutor_name current user registration value
   * @param tutor_relationship current user registration value
   * @param tutor_id current user registration value
   */
  validateAge: async (birthday, tutor_name, tutor_relationship, tutor_id) => {
    var errors = {};
    var res = false;
    if (birthday === null) {
      errors.age = "Edad inválida";
    } else {
      const regExName = /^(?!(.*(([^a-zA-Zñáéíóúü.'\-\s])|(\.{2,})|('{2,}|\s{2,}))))/;
      const regExId = /^(?!(.*(([^A-Z0-9\-\s])|(\.{2,})|('{2,}|\s{2,}))))/;
      const age = utilitiesHelpers.calcAgeFromPicker(birthday);
      if (age < 14) {
        if (tutor_relationship === "") {
          errors.tutor_relationship = "Campo inválido";
        }
        if (tutor_name === "" || !regExName.test(tutor_name)) {
          errors.tutor_name = "Nombre de Tutor inválido";
        }

        if (tutor_id === "" || !regExId.test(tutor_id)) {
          errors.tutor_id = "No. de Identificación de Tutor inválido";
        }
      } else if (age < 1) {
        errors.age = "Edad inválida";
      }
    }

    if (Object.keys(errors).length === 0) {
      res = true;
    }
    return [res, errors];
  },

  updateProfile: async (
    username,
    name,
    country,
    city,
    birthday,
    tutor_name,
    tutor_relationship,
    tutor_id,
    phone,
    fav_team,
    instagram,
    description,
    gender,
    user_type,
    position,
    alt_position,
    dominant_side,
    height,
    weight,
    current_club,
    time30,
    time1000,
    agent,
    contract
  ) => {
    var errors = {};
    var res = {};

    var [resUsername, errorUsername] = await validationHelpers.validateUserName(
      username
    );
    if (!resUsername) {
      errors.username = errorUsername;
    }

    const regExName = /^(?!(.*(([^a-zA-Zñáéíóúü.'\-\s])|(\.{2,})|('{2,}|\s{2,}))))/;
    if (!regExName.test(name) || name === "") {
      errors.name = "Nombre Inválido";
    }

    var [resAge, errorsAge] = await validationHelpers.validateAge(
      birthday,
      tutor_name,
      tutor_relationship,
      tutor_id
    );
    if (!resAge) {
      errors = { ...errors, ...errorsAge };
    }

    if (country === "") {
      errors.country = "País es un campo requerido";
    }
    if (city === "") {
      errors.city = "Ciudad es un campo requerido";
    }

    if (gender === "") {
      errors.gender = "El género es un campo requerido";
    }

    if (user_type === "player") {
      if (position === "") {
        errors.position = "Posición Inválida";
      }
      if (alt_position === "") {
        errors.alt_position = "Posición Alternativa Inválida";
      }
      if (dominant_side === "") {
        errors.dominant_side = "Lado Dominante Inválido";
      }
      if (height === "") {
        errors.height = "Altura Inválida";
      }
      if (weight === "") {
        errors.weight = "Peso Inválido";
      }
    }

    console.log(Object.keys(errors).length);
    if (Object.keys(errors).length === 0) {
      const uid = localStorage.getItem("userUndiscovered");
      try {
        var day =
          birthday.getDate() < 10
            ? "0" + birthday.getDate()
            : birthday.getDate();
        var month =
          birthday.getMonth() + 1 < 10
            ? "0" + (birthday.getMonth() + 1)
            : birthday.getMonth() + 1;
        var year = birthday.getFullYear();

        if (user_type === "player") {
          await firebase
            .firestore()
            .collection("users")
            .doc(uid)
            .update({
              birthday: day + "/" + month + "/" + year,
              tutor_name: tutor_name,
              tutor_relationship: tutor_relationship,
              tutor_id: tutor_id,
              username: username,
              name: name.trim(),
              phone: phone,
              country: country,
              city: city,
              fav_team: fav_team,
              instagram: instagram,
              gender: gender,
              description: description,
              current_club: current_club,
              position: position,
              alt_position: alt_position,
              dominant_side: dominant_side,
              height: height,
              weight: weight,
              time30: time30,
              time1000: time1000,
              agent: agent,
              contract: contract,
              user_type: user_type,
            });
        } else if (user_type === "fan") {
          await firebase
            .firestore()
            .collection("users")
            .doc(uid)
            .update({
              birthday: day + "/" + month + "/" + year,
              tutor_name: tutor_name,
              tutor_relationship: tutor_relationship,
              tutor_id: tutor_id,
              username: username,
              name: name.trim(),
              phone: phone,
              country: country,
              city: city,
              fav_team: fav_team,
              instagram: instagram,
              gender: gender,
              description: description,
              current_club: "",
              position: "",
              alt_position: "",
              dominant_side: "",
              height: "",
              weight: "",
              time30: "",
              time1000: "",
              agent: false,
              contract: false,
              user_type: user_type,
            });
        } else {
          await firebase
            .firestore()
            .collection("users")
            .doc(uid)
            .update({
              birthday: day + "/" + month + "/" + year,
              tutor_name: tutor_name,
              tutor_relationship: tutor_relationship,
              tutor_id: tutor_id,
              username: username,
              name: name,
              phone: phone,
              country: country,
              city: city,
              fav_team: fav_team,
              instagram: instagram,
              gender: gender,
              description: description,
              user_type: user_type,
            });
        }
        await firebase.auth().currentUser.updateProfile({
          displayName: username,
        });
        res = "success";
      } catch (e) {
        console.log(e);
        errors.general = "Ocurrió un error, por favor intenta más tarde";
      }
    }

    return [res, errors];
  },

  /**
   * Checks if registration/update values are valid
   * @method
   * @param position current user registration value
   * @param alt_position current user registration value
   * @param dominant_side current user registration value
   * @param height current user registration value
   * @param weight current user registration value
   */
  validateFormPartPlayer: async (
    position,
    alt_position,
    dominant_side,
    height,
    weight
  ) => {
    var errors = [];
    if (position === "") {
      errors.position = "Posición Inválida";
    }
    if (alt_position === "") {
      errors.alt_position = "Posición Alternativa Inválida";
    }
    if (dominant_side === "") {
      errors.dominant_side = "Lado Dominante Inválido";
    }
    if (height === "") {
      errors.height = "Altura Inválida";
    }
    if (weight === "") {
      errors.weight = "Peso Inválido";
    }

    return errors;
  },

  /**
   * Formats the time to show beginning 0's
   * @method
   * @param minutes value of minutes
   * @param seconds value of seconds
   */
  formatTime: (minutes, seconds) => {
    let mins;
    if (minutes === "") {
      mins = "00";
    } else {
      if (minutes.length === 1) {
        mins = "0" + minutes;
      } else {
        mins = minutes;
      }
    }

    let secs;
    if (seconds === "" || seconds === undefined) {
      secs = "00";
    } else {
      if (seconds.length === 1) {
        secs = "0" + seconds;
      } else {
        secs = seconds;
      }
    }
    return mins + ":" + secs;
  },

  /**
   * Deletes post from database
   * @method
   * @param post_id Id of post to be deleted
   */
  deletePost: async (post_id) => {
    return firebase
      .firestore()
      .collection("posts")
      .doc(post_id)
      .delete()
  },

  /**
   * Publishes a temporary post to get post id and later add video
   * @method
   */
  addPost: async () => {
    const uid = localStorage.getItem("userUndiscovered");
    const doc_ref = await firebase.firestore().collection("posts").add({
      dateCreated: new Date(),
      author: uid,
    });
    return doc_ref.id;
  },

  validatePostData(
    link,
    title,
    description,
    physical_skills,
    technical_skills,
    type_skills,
    user_type,
    required = true
  ) {
    var phy, tech, type;
    if (link === "" || link === undefined) return "Video requerido";
    if (title === "" || title === undefined) return "Título requerido";
    if (description === "" || description === undefined)
      return "Descripción requerida";

    if (user_type !== "agent") {
      for (var skill in physical_skills) {
        if (physical_skills[skill].active === true) {
          phy = true;
        }
      }
      for (var skill in technical_skills) {
        if (technical_skills[skill].active === true) {
          tech = true;
        }
      }
      for (var skill in type_skills) {
        if (type_skills[skill].active === true) {
          type = true;
        }
      }

      if (required && (phy || tech || tech)) return null;
      return "Se requiere por lo menos una hablidad";
    }
  },

  /**
   * Publishes changes in a post of type skill
   * @method
   * @param post_id Id of post to be edited
   * @param link value of post data
   * @param title value of post data
   * @param description value of post data
   * @param physical_skills value of post data
   * @param technical_skills value of post data
   */
  editPostSkill: async (
    post_id,
    link,
    title,
    description,
    physical_skills,
    technical_skills,
    type_skills
  ) => {
    firebase.firestore().collection("posts").doc(post_id).update({
      link: link,
      title: title,
      description: description,
      video_type: "skill",
      physical_skills: physical_skills,
      technical_skills: technical_skills,
      type_skills: type_skills,
      unpublished: false,
    });
  },

  /**
   * Publishes changes in a post of type game
   * @method
   * @param post_id Id of post to be edited
   * @param link value of post data
   * @param title value of post data
   * @param description value of post data
   */
  editPostGame: async (post_id, link, title, description) => {
    firebase.firestore().collection("posts").doc(post_id).update({
      link: link,
      title: title,
      description: description,
      video_type: "game",
    });
  },

  /**
   * Saves a rating for a post
   * @method
   * @param post_id Id of post to be rated
   * @param rating_user user that is currently rating this post
   * @param rating value of rating
   */
  rateVideo: async (post_id, rating_user, rating) => {
    const uid = localStorage.getItem("userUndiscovered");
    firebase
      .firestore()
      .collection("posts")
      .doc(post_id)
      .collection("ratings")
      .add({
        rated_by: uid,
        rating: rating,
      });
  },

  /**
   * Checks if a user has rated this post
   * @method
   * @param post_id Id of post to be rated
   * @param rating_user user that has previously rated this post or not
   */
  getRatings: async (post_id, rating_user) => {
    var ratings = [];
    await firebase
      .firestore()
      .collection("posts")
      .doc(post_id)
      .collection("ratings")
      .get()
      .then((querySnapshot) => {
        querySnapshot.docs.map((doc) => {
          ratings.push({
            id: doc.id,
            rated_by: doc.data().rated_by,
            rating: doc.data().rating,
          });
          return doc.data();
        });
      });
    return ratings;
  },

  /**
   * Updates a rating for a post
   * @method
   * @param post_id Id of post to be re-rated
   * @param rating_user user that is currently rating this post
   * @param rating_id Id of rating to be changed
   * @param rating value of rating
   */
  changeRatingVideo: async (post_id, rating_user, rating_id, rating) => {
    const uid = localStorage.getItem("userUndiscovered");
    firebase
      .firestore()
      .collection("posts")
      .doc(post_id)
      .collection("ratings")
      .doc(rating_id)
      .update({
        rated_by: uid,
        rating: rating,
      });
  },
};

export default validationHelpers;
