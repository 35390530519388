import Register from "views/auth/Register.js";
import Login from "views/auth/Login.js";
import EmailVerification from "views/auth/EmailVerification.js";
import Add from "views/Add.js";
import Profile from "views/Profile.js";
import Search from "views/Search.js";
import Challenges from "views/Challenges.js";
import Home from "views/Home.js";
import TerminosYCondiciones from "views/info/TerminosYCondiciones.js";
import PoliticaPrivacidad from "views/info/PoliticaPrivacidad.js";
import PoliticaCookies from "views/info/PoliticaCookies.js";
import Nosotros from "views/info/Nosotros.js";
import Eliminar from "views/info/Eliminar.js";

/**
 * Routes and endpoints for navigation.
 * Divided into the 3 layouts.
 * @var
 */
var routes = [
  {
    path: "/inicio",
    name: "Home",
    icon: "menu-icon home",
    component: Home,
    layout: ""
  },
  {
    path: "/buscar",
    name: "Search",
    icon: "menu-icon search",
    component: Search,
    layout: ""
  },
  {
    path: "/agregar",
    name: "Add",
    icon: "menu-icon add",
    component: Add,
    layout: ""
  },
  {
    path: "/retos",
    name: "Challenges",
    icon: "menu-icon challenge",
    component: Challenges,
    layout: ""
  },
  {
    path: "/perfil/:username",
    name: "Profile",
    icon: "menu-icon user",
    component: Profile,
    layout: ""
  },
  {
    path: "/login",
    name: "Login",
    icon: "menu-icon",
    component: Login,
    layout: "/auth"
  },
  {
    path: "/registro",
    name: "Register",
    icon: "menu-icon",
    component: Register,
    layout: "/auth"
  },
  {
    path: "/verificacion",
    name: "Register",
    icon: "menu-icon",
    component: EmailVerification,
    layout: "/auth"
  },
  {
    path: "/terminos-condiciones",
    name: "Términos y Condiciones",
    icon: "menu-icon",
    component: TerminosYCondiciones,
    layout: "/info"
  },
  {
    path: "/politica-privacidad",
    name: "Política de Privacidad",
    icon: "menu-icon",
    component: PoliticaPrivacidad,
    layout: "/info"
  },
  {
    path: "/politica-cookies",
    name: "Política de Cookies",
    icon: "menu-icon",
    component: PoliticaCookies,
    layout: "/info"
  },
  {
    path: "/nosotros",
    name: "A cerca de Undiscovered",
    icon: "menu-icon",
    component: Nosotros,
    layout: "/info"
  },
  {
    path: "/eliminar-cuenta",
    name: "Eliminar Cuenta",
    icon: "menu-icon",
    component: Eliminar,
    layout: "/info"
  }
];
export default routes;
