import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import Footer from "components/Footers/Footer.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import { Container, Button, Row, Col } from "reactstrap";
import routes from "routes.js";

/**
 * InfoLayout Class.
 * @constructor
 */
class Info extends React.Component {
  constructor(props) {
    super(props);
    this.state = {loggedIn:false}
  }

  /**
   * Upon mounting, check if logged in
   * @method
   */
  componentDidMount() {
    const uid= localStorage.getItem("userUndiscovered");
    if (uid !== undefined && uid !== null){
      this.setState({loggedIn:true})
    } else {
      this.setState({loggedIn:false})
    }
  }

  /**
   * Upon update, scroll to top of page
   * @method
   */
  componentDidUpdate(e) {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.mainContent.scrollTop = 0;
  }

  /**
   * Get routes for this layout
   * @method
   */
  getRoutes = routes => {
    return routes.map((prop, key) => {
      if (prop.layout === "/info") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  /**
   * Get name of the Page
   * @method
   */
  getBrandText = path => {
    for (let i = 0; i < routes.length; i++) {
      if (
        this.props.location.pathname.indexOf(
          routes[i].layout + routes[i].path
        ) !== -1
      ) {
        return routes[i].name;
      }
    }
    return "Brand";
  };

  /**
   * Renders the InfoLayout component.
   * @see InfoLayout Component
   */
  render() {
    if (this.state.loggedIn) {
      return (
        <>
          <Sidebar
            {...this.props}
            routes={routes}
            loggedIn={this.state.loggedIn}
            logo={{
              innerLink: "/admin/inicio",
              imgSrc: require("assets/img/brand/argon-react.png"),
              imgAlt: "..."
            }}
          />
          <div className="main-content px-md-4 px-lg-8 mt-4" ref="mainContent">
            <AdminNavbar
              {...this.props}
              loggedIn={this.state.loggedIn}
              brandText={this.getBrandText(this.props.location.pathname)}
            />
            <Switch>
              {this.getRoutes(routes)}
              <Redirect from="*" to="/admin/inicio" />
            </Switch>
            <Container fluid>
              <Footer />
            </Container>
          </div>
        </>
      );
    } else {
      return (
        <>
          <div className="main-content px-md-4 px-lg-8" ref="mainContent">
            <AdminNavbar
              {...this.props}
              loggedIn={this.state.loggedIn}
              brandText={this.getBrandText(this.props.location.pathname)}
            />
            <Container>
              <Row>
                <Col xs="12" className="text-center my-4">
                  <Button
                    className="m-1 txt-black min-width-200"
                    color="white"
                    type="button"
                    onClick={() => {
                      this.props.history.push("../auth/registro");
                    }}
                  >
                    CREAR CUENTA
                  </Button>
                  <Button
                    className="m-1 txt-black min-width-200"
                    color="primary"
                    type="button"
                    onClick={() => {
                      this.props.history.push("../auth/login");
                    }}
                  >
                    INICIAR SESIÓN
                  </Button>
                </Col>
              </Row>
            </Container>

            <Switch>
              {this.getRoutes(routes)}
              <Redirect from="*" to="/auth/login" />
            </Switch>
            <Container fluid>
              <Footer />
            </Container>
          </div>
        </>
      );
    }
  }
}

export default Info;
