import React from "react";
import { Event } from "../../components/Analytics";

import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  ButtonGroup,
  Row,
  Col,
  Alert,
  InputGroup,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { Link } from "react-router-dom";
import DatePicker from "react-date-picker";
import validationHelpers from "./../../helpers/validation";
import utilitiesHelpers from "./../../helpers/utilities";

/**
 * Register Class.
 * Main Register component view.
 * @constructor
 */
class Register extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      name: "",
      confirm_password: "",
      user_type: "player",
      user_acceptance: false,
      error_message: "",
      error_array: [],
      birthday: null,
      minor: false,
      tutor_name: "",
      tutor_id: "",
      tutor_relationship: "",
      dropdown_open: false,
      username: "",
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleChangeBirthday = this.handleChangeBirthday.bind(this);
    this.register = this.register.bind(this);
  }

  /**
   * Basic change handle for inputs
   * @method
   * @param e Element firing the function
   */
  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  /**
   * Basic change handle for birthday
   * @method
   * @param e Element firing the function
   */
  handleChangeBirthday(birthday) {
    console.log(birthday[0]);

    if (birthday !== null && birthday[0]) {
      this.setState({ birthday: birthday[0] });
      var age = utilitiesHelpers.calcAgeFromPicker(birthday[0]);
      if (age < 14) {
        this.setState({ minor: true });
      } else {
        this.setState({ minor: false });
      }
    }
  }

  /**
   * Basic toggle for dropdowns
   * @method
   */
  toggleDropdown() {
    this.setState({ dropdown_open: !this.state.dropdown_open });
  }

  /**
   * Registers user with firebase and redirects to correct endpoint
   * @method
   */
  async register() {
    Event("AUTHENTICATION", "Register", "Register Page");
    var [res, errors] = await validationHelpers.registerUser(
      this.state.username,
      this.state.email,
      this.state.name,
      this.state.password,
      this.state.confirm_password,
      this.state.user_type,
      this.state.user_acceptance,
      this.state.birthday,
      this.state.tutor_name,
      this.state.tutor_relationship,
      this.state.tutor_id.toUpperCase()
    );

    if (res.id) {
      this.props.history.push("verificacion");
      this.setState({
        error_array: errors,
      });
    } else {
      this.setState({
        error_array: errors,
      });
    }
  }

  /**
   * Renders the Register component.
   * @see Register Component
   */
  render() {
    let error_message;

    if (Object.keys(this.state.error_array).length > 0) {
      error_message = Object.values(this.state.error_array).map((error, i) => {
        return (
          <p className="fs-12 mb-0" key={i}>
            {error}
          </p>
        );
      });
    }
    let minor_fields;

    if (this.state.minor) {
      minor_fields = (
        <div className="bg-primary px-2 pb-1 mt-2 border-radius-10">
          <Row className="d-flex justify-content-between pt-2 mt-2 pb-0 mb-0 px-3 filter-dropdown-row">
            <h4 className="text-center full-width mb-0">
              La edad mínima para el el uso de Undiscovered es de 14 años
            </h4>
            <p className="text-center full-width mb-1 fs-12">
              Para continuar llene los siguientes campos responsablemente
            </p>
            <FormGroup className="full-width pb-0 mb-0">
              <Input
                className={
                  "form-control-alternative" +
                  (this.state.error_array.user_acceptance ? " error-input" : "")
                }
                type="text"
                name="tutor_name"
                value={this.state.tutor_name}
                placeholder="Nombre de Tutor"
                onChange={this.handleChange}
              />
            </FormGroup>
          </Row>
          <Row className="d-flex pt-0 mt-2 pb-0 mb-0">
            <Dropdown
              isOpen={this.state.dropdown_open}
              toggle={() => this.toggleDropdown()}
              className="full-width hz-padded mt-0"
            >
              <DropdownToggle
                caret
                className={
                  "full-width btn-white" +
                  (this.state.error_array.user_acceptance ? " error-input" : "")
                }
              >
                {this.state.tutor_relationship !== ""
                  ? this.state.tutor_relationship
                  : "Parentesco "}
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem
                  name="tutor_relationship"
                  value="Padre"
                  onClick={this.handleChange}
                >
                  Padre
                </DropdownItem>
                <DropdownItem
                  name="tutor_relationship"
                  value="Madre"
                  onClick={this.handleChange}
                >
                  Madre
                </DropdownItem>
                <DropdownItem
                  name="tutor_relationship"
                  value="Tutor Legal"
                  onClick={this.handleChange}
                >
                  Tutor Legal
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </Row>
          <Row className="d-flex justify-content-between pt-0 mt-2 pb-0 mb-0 px-3 filter-dropdown-row">
            <FormGroup className="full-width mb-0">
              <Input
                className={
                  "form-control-alternative mb-2" +
                  (this.state.error_array.user_acceptance ? " error-input" : "")
                }
                type="text"
                name="tutor_id"
                value={this.state.tutor_id.toUpperCase()}
                placeholder="No. Identificación de Tutor"
                onChange={this.handleChange}
              />
            </FormGroup>
          </Row>
        </div>
      );
    }
    return (
      <>
        <Col lg="6" md="8">
          <div className="full-width text-center justify-content-center my-4">
            <a
              href="https://www.undiscovered.app/"
              target="_blank"
              rel="noopener noreferrer"
              className="h4 mb-0 d-lg-inline-block"
            >
              <img
                alt="..."
                className="main-logo"
                src={require("assets/img/brand/Undiscovered-logo-black.png")}
              />
            </a>
          </div>
          <Card className="bg-white shadow border-0">
            <CardBody className="px-lg-5 py-lg-5">
              <div className="h3 ls-2 text-center">REGISTRARME</div>
              <FormGroup className="mb-3">
                <Input
                  className={
                    "form-control-alternative" +
                    (this.state.error_array.username ? " error-input" : "")
                  }
                  type="text"
                  name="username"
                  returnkeytype="next"
                  value={this.state.username.toLowerCase().replace(" ", "")}
                  placeholder="Nombre de Usuario"
                  onChange={this.handleChange}
                  bluronsubmit={"false"}
                />
              </FormGroup>
              <FormGroup className="mb-2">
                <Input
                  className={
                    "form-control-alternative" +
                    (this.state.error_array.name ? " error-input" : "")
                  }
                  type="text"
                  name="name"
                  value={this.state.name}
                  returnkeytype="next"
                  placeholder="Nombre Completo"
                  onChange={this.handleChange}
                  bluronsubmit={"false"}
                />
              </FormGroup>
              <FormGroup className="mb-3">
                <Input
                  className={
                    "form-control-alternative" +
                    (this.state.error_array.email ? " error-input" : "")
                  }
                  type="text"
                  name="email"
                  value={this.state.email}
                  placeholder="Correo"
                  onChange={this.handleChange}
                  returnkeytype="next"
                  bluronsubmit={"false"}
                />
              </FormGroup>
              <FormGroup className="mb-3">
                <Input
                  className={
                    "form-control-alternative" +
                    (this.state.error_array.password ? " error-input" : "")
                  }
                  type="password"
                  name="password"
                  placeholder="Contraseña"
                  value={this.state.password}
                  onChange={this.handleChange}
                  returnkeytype="next"
                  bluronsubmit={"false"}
                />
              </FormGroup>
              <FormGroup className="mb-3">
                <Input
                  className={
                    "form-control-alternative" +
                    (this.state.error_array.password ? " error-input" : "")
                  }
                  type="password"
                  name="confirm_password"
                  value={this.state.confirm_password}
                  placeholder="Confirmar Contraseña"
                  onChange={this.handleChange}
                  returnkeytype="next"
                  bluronsubmit={"true"}
                />
              </FormGroup>
              <Row className="justify-content-center pt-0 mt-0 pb-0">
                <ButtonGroup className="full-width hz-padded mb-2">
                  <Button
                    color="white"
                    active={this.state.user_type === "player"}
                    onClick={() => {
                      this.setState({ user_type: "player" });
                    }}
                  >
                    Soy Jugador
                  </Button>
                  <Button
                    color="white"
                    active={this.state.user_type === "fan"}
                    onClick={() => {
                      this.setState({ user_type: "fan" });
                    }}
                  >
                    Soy Fan
                  </Button>
                  <Button
                    color="white"
                    active={this.state.user_type === "agent"}
                    onClick={() => {
                      this.setState({ user_type: "agent" });
                    }}
                  >
                    Soy Agente/Club
                  </Button>
                </ButtonGroup>
              </Row>
              <Row className="d-flex justify-content-between pt-2 pb-0 mb-0 px-3 filter-dropdown-row">
                <FormGroup className="full-width mb-0">
                  <p className="full-width text-center btn-label mb-0">
                    Fecha de Nacimiento
                  </p>
                  <InputGroup
                    className={
                      "input-group-alternative" +
                      (this.state.error_array.age ? " error-input" : "")
                    }
                  >
                    <DatePicker
                      calendarClassName="date-input-calendar"
                      className="date-input-picker"
                      onChange={(e) => this.handleChangeBirthday(e)}
                      value={this.state.birthday}
                      calendarIcon={null}
                      dayPlaceholder="dd"
                      monthPlaceholder="mm"
                      minDetail="decade"
                      maxDetail="month"
                      yearPlaceholder="aaaa"
                      id="birthday"
                      name="birthday"
                      format="d-M-y"
                      onCalendarOpen={null}
                      onCalendarClose={null}
                      nativeInputAriaLabel="Date"
                      returnValue="range"
                    />
                  </InputGroup>
                </FormGroup>
              </Row>
              {minor_fields}
              <Row className="mt-2 mb-3">
                <Col xs="12">
                  <div className="custom-control custom-control-alternative custom-checkbox">
                    <input
                      className="custom-control-input"
                      id="user_acceptance"
                      checked={this.state.user_acceptance}
                      onChange={(e) =>
                        this.setState({ user_acceptance: e.target.checked })
                      }
                      type="checkbox"
                    />
                    <label
                      className={
                        "custom-control-label" +
                        (this.state.error_array.user_acceptance
                          ? " error-input"
                          : "")
                      }
                      htmlFor="user_acceptance"
                    >
                      <span className="text-muted">
                        Acepto los{" "}
                        <Link
                          to="/info/terminos-condiciones"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="link inline"
                        >
                          Términos y Condiciones
                        </Link>{" "}
                        y{" "}
                        <Link
                          to="/info/politica-privacidad"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="link inline"
                        >
                          Política de Privacidad
                        </Link>
                      </span>
                    </label>
                  </div>
                </Col>
              </Row>
              <Alert
                color="warning"
                hidden={
                  Object.keys(this.state.error_array).length === 0
                    ? true
                    : false
                }
              >
                {error_message}
              </Alert>
              <div className="text-center pb-0 m-0">
                <Button
                  className="my-1 txt-black min-width-200"
                  color="primary"
                  type="button"
                  onClick={async () => {
                    await this.register();
                  }}
                >
                  CREAR CUENTA
                </Button>
              </div>
              <div className="full-width text-center">
                <p className="info-text-md mt-2 mb--1 full-width text-center">
                  ¿Ya tienes cuenta?
                </p>
                <Link to="login">Inicia Sesión</Link>
              </div>
            </CardBody>
          </Card>
        </Col>
      </>
    );
  }
}

export default Register;
