import { PageView, initGA } from "./components/Analytics";
import React from "react";
import { createBrowserHistory } from "history";
import { Router, Route, Switch, withRouter, Redirect } from "react-router-dom";
import firebase from "./config/firebase.js";
import AddToHomescreen from "react-add-to-homescreen";
import CookieConsent from "react-cookie-consent";
import { CookieBanner } from "@palmabit/react-cookie-law";

import AdminLayout from "layouts/Admin.js";
import AuthLayout from "layouts/Auth.js";
import InfoLayout from "layouts/Info.js";

import userHelpers from "./helpers/user";
import utilitiesHelpers from "./helpers/utilities";
import "../src/assets/css/custom.css";
const historyConfig = {
  basename: "",
};
const hist = createBrowserHistory(historyConfig);

/**
 * Main entry into app modules
 * @constructor
 */
class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      loggedIn: false,
    };
    this.authListener = this.authListener.bind(this);
    this.logout = this.logout.bind(this);
  }

  handleAddToHomescreenClick = () => {
    alert(
      `1. Abre el menú de compartir\n2. Haz click en "Añadir a la pantalla principal" `
    );
  };

  /**
   * Upon mounting, listen for authentification
   * @method
   */

  componentDidMount() {
    initGA("UA-165963857-1");
    PageView();
    this.authListener();
    //this.updateSkills();
  }

  /**
   * <h5>UPDATE HELPER</h5>
   * Helper to update skills when added directly to database. You must edit the selected skill in order to update.
   * @method
   */
  async updateSkills() {
    const [
      physical_skills,
      technical_skills,
    ] = await utilitiesHelpers.getUtilitiesSkills();

    var posts = await userHelpers.getAllPosts();
    for (let i = 0; i < posts.length; i++) {
      if (posts[i].technical_skills !== undefined) {
        for (
          let j = 0;
          j < Object.values(posts[i].technical_skills).length;
          j++
        ) {
          var prev_skills = Object.values(posts[i].technical_skills);
          if (
            posts[i].technical_skills[j].value !== technical_skills[j].value
          ) {
            prev_skills.splice(j, 0, { active: false, value: "Definición" });
            posts[i].technical_skills = { ...prev_skills };
            break;
          }
        }
        firebase
          .firestore()
          .collection("users")
          .doc(posts[i].user.id)
          .collection("posts")
          .doc(posts[i].id)
          .update({
            physical_skills: posts[i].physical_skills,
            technical_skills: posts[i].technical_skills,
          });
      }
    }
  }

  /**
   * Logout function.
   * Removes id from local storage and signs out of firebase.
   * Redirects to login.
   * @method
   */
  async logout() {
    await firebase
      .auth()
      .signOut()
      .then(function () {
        localStorage.removeItem("userUndiscovered");
        this.props.history.push("/auth/login");
      })
      .catch(function (error) {});
  }

  /**
   * Checks if user is logged in and redirects to desired endpoint.
   * Sets user ID in local storage.
   * Checks if profile is complete with the Firebase user.displayName parameter.
   * @method
   */
  async authListener() {
    await firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        localStorage.setItem("userUndiscovered", user.uid);
        console.log(`EMAIL VERIFICADO: ${ user.emailVerified }`)
        if (user.emailVerified) {
          if (hist.location.pathname.includes("auth")) {
            hist.push("/inicio");
          }
          this.setState({ loggedIn: true });
        } else {
          if (!hist.location.pathname.includes("/auth/verification")) {
            hist.push("/auth/verificacion");
          }
          this.setState({ loggedIn: true });
        }
      } else {
        localStorage.removeItem("userUndiscovered");

        if (hist.location.pathname === "/auth/registro") {
          hist.push("/auth/registro");
        } else if (!hist.location.pathname.includes("info")) {
          hist.push("/auth/login");
        }
      }
    });
    this.setState({ loading: false });
  }

  /**
   * Renders the different Layouts for all parts of the app.
   * @see Main App Layouts
   */
  render() {
    return (
      <>
        <AddToHomescreen
          onAddToHomescreenClick={this.handleAddToHomescreenClick}
          title="Agregar a pantalla de inicio"
        />
        <Router history={hist}>
          <Switch>
            <Route path="/info" render={(props) => <InfoLayout {...props} />} />
            <Route path="/auth" render={(props) => <AuthLayout {...props} />} />
            <Route path="/" render={(props) => <AdminLayout {...props} />} />
            <Redirect from="/" to="/auth/login" />
          </Switch>
        </Router>
        <CookieBanner
          message="Este sitio web utiliza cookies para mejorar tu experiencia en el
          sitio."
          wholeDomain={true}
          onAccept={() => {}}
          showPreferencesOption={false}
          showStatisticsOption={false}
          showMarketingOption={false}
          acceptButtonText={"OK"}
        />
      </>
    );
  }
}
export default withRouter(App);
