/*eslint-disable*/
import React from "react";
import {Row, Col} from "reactstrap";

/**
 * Footer Class.
 * Main Footer for all app.
 * @constructor
 */
class Footer extends React.Component {

  /**
   * Renders the Footer component.
   * @see Footer Component
   */
  render() {
    return (
      <footer className="footer">
        <Row className="align-items-center justify-content-center justify-content-xl-between full-width m-0" >
          <Col xl="6">
            <div className="copyright text-center text-xl-left text-muted">
              <a
                className="font-weight-bold ml-1"
                href="https://web.undiscovered.app"
                rel="noopener noreferrer"
                target="_blank"
              >
                Undiscovered 
              </a> | © Todos los derechos reservados. 2021
            </div>
          </Col>
        </Row>
      </footer>
    );
  }
}

export default Footer;
