import React from "react";
import { Container } from "reactstrap";

/**
 * PoliticaCookies Class.
 * Main Cookie Policy component view.
 * @constructor
 */
class PoliticaCookies extends React.Component {
  /**
   * Renders the PoliticaCookies component.
   * @see PoliticaCookies Component
   */
  render() {
    return (
      <>
        {/* Page content */}
        <Container fluid>
          <h2 className="ls-2  text-center">Política de Cookies</h2>
          <p className=" text-justify">
            Este documento te informa y describe la Política de Cookies de la
            web cuya url es{" "}
            <a href="https://www.undiscovered.app">www.undiscovered.app</a> cuya
            titular es la entidad Undiscovered Talent Discovery, S.L., con
            domicilio a efectos de notificaciones en Maria de Molina número 31,
            Madrid, Madrid 28006 (España); inscrita en el Registro Mercantil de
            Madrid, con fecha 26 de julio de 2019, número de inscripción 1, tomo
            39485, folio 80, hoja 701033 y provista de C.I.F. número B88439930.
            (la “Web”). <br />
            <br />
            Mediante el uso de esta Web el Usuario y/o Tú, denominado así
            indistintamente, consientes y aceptas expresamente el tratamiento de
            la información recabada en la forma y con los fines que se detallan
            en este documento. Asimismo, reconoces conocer la posibilidad de
            rechazar el tratamiento de tales datos o información rechazando el
            uso de cookies mediante la selección de la configuración apropiada a
            tal fin en tu navegador. Si bien esta opción de bloqueo de cookies
            en tu navegador puede no permitirte el uso pleno de todas las
            funcionalidades de la Web.
            <br />
            <br />
            <h3 className="color-primary uppercase">¿Qué es una cookie?</h3>
            Cookie es un fichero que se descarga en tu ordenador al acceder a
            determinadas páginas web. Las cookies permiten a una página web,
            entre otras cosas, almacenar y recuperar información sobre los
            hábitos de navegación de un usuario o de su equipo y, dependiendo de
            la información que contengan y de la forma en que utilice su equipo,
            pueden utilizarse para reconocer al Usuario. El navegador del
            Usuario memoriza cookies en el disco duro solamente durante la
            sesión actual ocupando un espacio de memoria mínimo y no
            perjudicando al ordenador. Las cookies no contienen ninguna clase de
            información personal específica, y la mismas se borran del disco
            duro al finalizar la sesión de navegador (las denominadas cookies de
            sesión).
            <br />
            <br />
            La mayoría de los navegadores aceptan como estándar a las cookies y,
            con independencia de las mismas, permiten o impiden en los ajustes
            de seguridad las cookies temporales o memorizadas.
            <br />
            <br />
            <h3 className="color-primary uppercase">
              ¿Qué tipos de cookies utiliza esta página Web?
            </h3>
            <h3 className="color-secondary">Cookies técnicas:</h3>
            Son aquéllas que permiten al Usuario la navegación a través de una página web, plataforma o aplicación y la utilización de las diferentes opciones o servicios que en ella existan como, por ejemplo, controlar el tráfico y la comunicación de datos, identificar la sesión, acceder a partes de acceso restringido, recordar los elementos que integran un pedido, realizar el proceso de compra de un pedido, realizar la solicitud de inscripción o participación en un evento, utilizar elementos de seguridad durante la navegación, almacenar contenidos para la difusión de videos o sonido o compartir contenidos a través de redes sociales.
            <br />
            <br />
            <h3 className="color-secondary">Cookies de personalización:</h3>
            Son aquéllas que permiten al Usuario acceder al servicio con algunas características de carácter general predefinidas en función de una serie de criterios en el terminal del Usuario como por ejemplo el idioma, el tipo de navegador a través del cual accede al servicio, la configuración regional desde donde accede al servicio, entre otros similares.
            <br />
            <br />
            <h3 className="color-secondary">Cookies de análisis:</h3>
            Son aquéllas que bien tratadas por nosotros o por terceros, nos permiten cuantificar el número de usuarios y así realizar la medición y análisis estadístico de la utilización que hacen los usuarios del servicio ofertado. Para ello se analiza su navegación en nuestra página Web con el fin de mejorar la oferta de productos o servicios que le ofrecemos.
            <br />
            <br />
            <h3 className="color-secondary">Cookies publicitarias:</h3>
            Son aquéllas que, bien tratadas por nosotros o por terceros, nos permiten gestionar de la forma más eficaz posible la oferta de los espacios publicitarios que hay en la página Web, adecuando el contenido del anuncio al contenido del servicio solicitado o al uso que realice de nuestra página Web. Para ello podemos analizar sus hábitos de navegación en Internet y podemos mostrarle publicidad relacionada con su perfil de navegación.
            <br />
            <br />
            <h3 className="color-secondary">
              Cookies de publicidad comportamental:
            </h3>
            Son aquéllas que permiten la gestión, de la forma más eficaz posible, de los espacios publicitarios que, en su caso, el editor haya incluido en una página web, aplicación o plataforma desde la que presta el servicio solicitado. Estas cookies almacenan información del comportamiento de los usuarios obtenida a través de la observación continuada de sus hábitos de navegación, lo que permite desarrollar un perfil específico para mostrar publicidad en función del mismo.
            <br />
            <br />
            <h3 className="color-secondary">Cookies de terceros:</h3>
            La Web puede utilizar servicios de terceros que, por cuenta del
            titular de la Web, recopilaran información con fines estadísticos,
            de uso de la Web por parte del Usuario y para la prestación de otros
            servicios relacionados con la actividad de la Web y otros servicios
            de Internet.
            <br />
            <br />
            Esta Web utiliza Google Analytics, un servicio analítico de web
            prestado por Google, Inc. con domicilio en los Estados Unidos con
            sede central en 1600 Amphitheatre Parkway, Mountain View, California
            94043 (<a href="https://www.google.com/analytics/terms/?hl=es">https://www.google.com/analytics/terms/?hl=es</a>), así como de
            su parte relacionada YouTube.
            <br />
            <br />
            Para la prestación de estos servicios, estos utilizan cookies que
            recopilan la información, incluida la dirección IP del usuario, que
            será transmitida, tratada y almacenada por Google en los términos
            fijados en la Web Google.com. Incluyendo la posible transmisión de
            dicha información a terceros por razones de exigencia legal o cuando
            dichos terceros procesen la información por cuenta de Google.
            <br />
            <br />
            Esta Web utiliza Google Analytics, un servicio analítico de web
            prestado por Google, Inc. con domicilio en los Estados Unidos con
            sede central en 1600 Amphitheatre Parkway, Mountain View, California
            94043. Para la prestación de estos servicios, estos utilizan cookies
            que recopilan la información, incluida la dirección IP del usuario,
            que será transmitida, tratada y almacenada por Google en los
            términos fijados en la Web Google.com. Incluyendo la posible
            transmisión de dicha información a terceros por razones de exigencia
            legal o cuando dichos terceros procesen la información por cuenta de
            Google.
            <br />
            <br />
            <h3 className="color-secondary">
              ¿Cómo puedes eliminar las de cookies que utiliza esta Web y qué
              consecuencias tiene?
            </h3>
            Para utilizar esta Web resulta necesaria la instalación de cookies, que recaban, en todo caso, información totalmente anónima. A pesar de ello, puedes configurar tu navegador para bloquearlas y, en su caso, eliminarlas. No obstante, si seleccionas esta configuración, quizás no puedas acceder a determinadas partes del sitio Web o no puedas aprovecharte y/o usar alguno de los servicios de la misma. A menos que hayas ajustado la configuración de tu navegador de forma que rechace cookies, nuestro sistema producirá cookies cuando se conecte a nuestra Web.
            <br />
            <br />
            No obstante, puedes en cualquier momento, restringir, bloquear o borrar las cookies de esta Web. Para ello deberás modificar la configuración de tu navegador en lo que respecta al uso de cookies a través del menú “Preferencias”, “Opciones” o “Herramientas” (el nombre de los menús o el procedimiento para acceder a las opciones de cookies varían dependiendo del navegador utilizado). Para más información sobre la configuración de cookies consulta el menú “Ayuda” del navegador que tengas instalado en tu equipo terminal.
            <br />
            <br />
            <h3 className="color-secondary">Contacto</h3>
            Si tienes dudas sobre esta política de cookies, puedes contactar con
            nosotros a través de la cuenta de correo electrónico{" "}
            <a href="contacto@undiscovered.app">contacto@undiscovered.app</a>.
            <br />
            <br />
            <h5 className="color-black">
              Fecha de ultima actualización: Junio 19, 2020
            </h5>
          </p>
        </Container>
      </>
    );
  }
}

export default PoliticaCookies;
