import React from "react";
import { Card, CardHeader, CardBody, Col, Button } from "reactstrap";
import utilitiesHelpers from "../../helpers/utilities";
import userHelpers from "../../helpers/user";

/**
 * ProfileCard Class.
 * Main Profile Card for posts component view.
 * @constructor
 */
class ProfileCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      discovered: false,
    };
    this.discoverUser = this.discoverUser.bind(this);
  }

  /**
   * Upon mounting, set if this user has been discovered
   * @method
   */
  componentDidMount() {
    this.setState({
      discovered: this.props.user.discovered
        ? this.props.user.discovered
        : false,
    });
  }

  /**
   * Switch the user to being discovered/undiscovered
   * @method
   */
  async discoverUser() {
    if (this.state.discovered === false) {
      await userHelpers.discoverUser(this.props.user.id);
    } else {
      await userHelpers.undiscoverUser(this.props.user.id);
    }
    this.setState({
      discovered: !this.state.discovered,
    });
  }

  /**
   * Renders the ProfileCard component.
   * @see ProfileCard Component
   */
  render() {
    let user_type;

    if (this.props.user.username !== "undiscovered") {
      if (this.props.user.user_type === "agent") {
        user_type = "AGENTE";
      } else if (this.props.user.user_type === "fan") {
        user_type = "FAN";
      } else if (this.props.user.user_type === "player") {
        user_type = "JUGADOR";
      }
    }

    return (
      <>
        <Card className="card-profile shadow mb-3 mt-1">
          <CardHeader className="border-0 p-2 m-0 border-radius-10">
            <div className="justify-content-start">
              <Col className="d-flex justify-content-between p-2" lg="12">
                <div className="d-flex justify-content-between p-0">
                  <div className="card-profile-image mr-1">
                    <Button
                      className="no-styles"
                      onClick={() =>
                        this.props.history.push(
                          "/perfil/" + this.props.user.username
                        )
                      }
                    >
                      <img
                        alt="..."
                        className="rounded-circle small"
                        src={
                          this.props.user.profile_picture_url !== undefined
                            ? this.props.user.profile_picture_url
                            : require("assets/img/brand/ProfilePlaceholder.png")
                        }
                        // src={require("assets/img/brand/ProfilePlaceholder.png")}
                      />
                    </Button>
                  </div>
                  <div
                    className="text-left"
                    onClick={() =>
                      this.props.history.push(
                        "/perfil/" + this.props.user.username
                      )
                    }
                  >
                    <div className="h4 text-left mt--1 mb-0">
                      {this.props.user.username}
                    </div>
                    <div className="h5 font-weight-300 mb-0">
                      {this.props.user.name ? this.props.user.name.substr(0, 30) : null}
                      {this.props.user.username !== "undiscovered" ? (
                        <span className="font-weight-light">
                          , {utilitiesHelpers.calcAge(this.props.user.birthday)}
                        </span>
                      ) : null}
                    </div>
                    {this.props.user.username !== "undiscovered"  && this.props.user.city ? (
                      <div className="h6 font-weight-300 mb-0 fs-11">
                        {this.props.user.city}, {this.props.user.country}
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="d-flex justify-content-center">
                  {this.props.user.id ===
                  localStorage.getItem("userUndiscovered") ? null : (
                    <div className="d-flex flex-column text-center">
                      {this.props.user.username !== "undiscovered" ? (
                        <Button
                          className="discovered button small"
                          color={this.state.discovered ? "primary" : "white"}
                          onClick={this.discoverUser}
                          size="sm"
                        >
                          {this.state.discovered
                            ? "DISCOVERED"
                            : "UNDISCOVERED"}
                        </Button>
                      ) : null}

                      <div className="h4 mb-0 mt-2 color-primary user-type-label">
                        {user_type}
                      </div>
                    </div>
                  )}
                </div>
              </Col>
            </div>
          </CardHeader>
          {this.props.user.user_type !== "player" ? (
            null
          ) : (
            this.props.user.gender ? (
            <CardBody className="px-0 pb-3 pt-0">
              <div className="card-profile-stats py-3 scrolling-wrapper user-list border-radius-10 text-center">
                <Card className="card-profile shadow py-2 px-3 ml-3 d-inline-flex justify-content-between">
                  <h4 className="mb--3 color-secondary pb-1 fs-14">
                    BIOGRAFÍA
                  </h4>
                  <div>
                    <img
                      alt="undiscovered-agente"
                      className="profile-card-icon-small mb-1"
                      src={require("assets/img/icons/gender.svg")}
                    />
                    <div className="h5 profile-stats-value-1 mb--1">
                      {this.props.user.gender}
                      <p className="profile-stats">Género</p>
                    </div>
                  </div>
                  <div>
                    <img
                      alt="undiscovered-agente"
                      className="profile-card-icon-small mb-1"
                      src={require("assets/img/icons/height.svg")}
                    />
                    <div className="h5 profile-stats-value-1 mb--1">
                      {this.props.user.height}cm
                      <p className="profile-stats">Altura</p>
                    </div>
                  </div>
                  <div>
                    <img
                      alt="undiscovered-agente"
                      className="profile-card-icon-small mb-1"
                      src={require("assets/img/icons/weight.svg")}
                    />
                    <div className="h5 profile-stats-value-1 mb--1">
                      {this.props.user.weight}kg
                      <p className="profile-stats">Peso</p>
                    </div>
                  </div>
                </Card>
                <Card className="card-profile shadow py-2 px-3 ml-3 d-inline-flex justify-content-between">
                  <h4 className="color-secondary fs-14">DATOS TÉCNICOS</h4>
                  <div className="my-half1">
                    <div className="h5 profile-stats-value-1 mb--1">
                      {this.props.user.position}
                    </div>
                    <p className="profile-stats">Posición</p>
                  </div>
                  <hr className="my-0" />
                  <div className="my-half1">
                    <div className="h5 profile-stats-value-1 mb--1">
                      {this.props.user.alt_position}
                    </div>
                    <p className="profile-stats">Posición Alternativa</p>
                  </div>
                  <hr className="my-0" />
                  <div className="my-half1">
                    <div className="h5 profile-stats-value-1 mb--1">
                      {this.props.user.dominant_side}
                    </div>
                    <p className="profile-stats">Lado Dominante</p>
                  </div>
                  <hr className="my-0" />
                  <div className="my-half1">
                    <div className="h5 profile-stats-value-1 mb--1">
                      {this.props.user.time30 === "00:00"
                        ? "N/A"
                        : this.props.user.time30}
                    </div>
                    <p className="profile-stats">Tiempo 30m</p>
                  </div>
                  <hr className="my-0" />
                  <div className="my-half1">
                    <div className="h5 profile-stats-value-1 mb--1">
                      {this.props.user.time1000 === "00:00"
                        ? "N/A"
                        : this.props.user.time1000}
                    </div>
                    <p className="profile-stats">Tiempo 1000m</p>
                  </div>
                </Card>
                <Card className="card-profile shadow py-2 px-3 mx-3 d-inline-flex justify-content-between">
                  <h4 className="color-secondary fs-14">DATOS PROFESIONALES</h4>
                  <div>
                    <div className="h5 profile-stats-value-1 mb--1">
                      <img
                        alt="undiscovered-agente"
                        className="profile-card-icon mb-2"
                        src={
                          this.props.user.agent
                            ? require("assets/img/icons/check.svg")
                            : require("assets/img/icons/cross.svg")
                        }
                      />
                    </div>
                    <p className="profile-stats-bigger">Agente</p>
                  </div>
                  <hr className="my-2" />
                  <div className="mb-3">
                    <div className="h5 profile-stats-value-1 mb--1">
                      <img
                        alt="undiscovered-contrato"
                        className="profile-card-icon mb-2"
                        src={
                          this.props.user.contract
                            ? require("assets/img/icons/check.svg")
                            : require("assets/img/icons/cross.svg")
                        }
                      />
                    </div>
                    <p className="profile-stats-bigger">Contrato</p>
                  </div>
                </Card>
              </div>
            </CardBody> ) : null 
          )}
        </Card>
      </>
    );
  }
}

export default ProfileCard;
